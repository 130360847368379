import React from "react";

export const isDevelopment = () => process.env.NODE_ENV === 'development';
export const isProduction = () => process.env.NODE_ENV === 'production';

export const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export const isNullOrUndefinedOrEmpty = (value: any):boolean =>{
    return value === null || value === undefined || value === "";
}

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
