import React from "react";
import {useStyles} from "./style";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import ChipInput from 'material-ui-chip-input';
import {ParcoursAmount} from "../../../models/parcours.model";
import {EmailsByParcoursID} from "../../../models/enterprise.model";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {nanoid} from "nanoid";
import {Catalogue} from "../../../models/catalogue.model";
import {sortCatalogue} from "../../../utils/suiviConso.util";

export interface DialogProps {
    parcoursAmount: ParcoursAmount;
    onChipValuesChanged: (values: string[], parcoursAmount: ParcoursAmount, index: number) => void;
    onCheckAddUser: (email: string, parcours: string) => void;
    reinitForm: boolean;
    disabled: boolean;
    index: number;
    chipValuesDictionnary: EmailsByParcoursID;
    emailToAddFromParent: string;
}


export const MAX_NB_USERS_TO_ADD = 100000;
export default function CreateUserChipInput(props: DialogProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const {
        parcoursAmount,
        onChipValuesChanged,
        reinitForm,
        disabled// all emails coming from other chip inputs
    } = props;
    const [chipValues, setChipValues] = React.useState<string[]>([]);
    const [disabledChips, setDisabledChips] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const openModal = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    // Update amount to pay on each new user added
    React.useEffect(() => {
        onChipValuesChanged(chipValues, parcoursAmount, props.index);
    }, [chipValues]);
    // Reinit all forms values
    React.useEffect(() => {
        if (reinitForm) {
            setChipValues([]);
        }
    }, [reinitForm]);
    // Disable chips if parent send disabled props
    React.useEffect(() => {
        setDisabledChips(disabledChips);
    }, [disabled]);
    React.useEffect(() => {
        if (props.emailToAddFromParent && props.emailToAddFromParent.trim().length > 0) {
            handleAddChip(props.emailToAddFromParent);
        }
    }, [props.emailToAddFromParent]);

    const handleAddChip = (chip: string) => {
        if (chipValues.length < MAX_NB_USERS_TO_ADD) {
            setChipValues([...chipValues, chip]);
        }
    };
    const handleOnBeforeAddChip = (chip: string): boolean => {
        // Do API calls in parent component to check if user already exists or already has the modules on this parcours
        props.onCheckAddUser(chip, props.parcoursAmount.parcoursID);
        // Return false and let parent handle the adding
        return false;
    };
    const handleDeleteChip = (chip: string, index: number) => {
        const newChipValues = chipValues.filter((_, i) => i !== index);
        setChipValues(newChipValues);
    };

    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const getParcoursName = (name: string): string => {
        return t(`catalogue.parcours.${parcoursAmount.name}`);
    }

    const inputProps = {
        autoComplete: 'new-password',
        id: "field1"
    }


    return (<div className={classes.root}>

        <ChipInput
            className={classes.chipInput}
            label={t('enterprise.dashboard.popup.emailsParcours', {parcoursName: parcoursAmount.name})}
            disabled={disabledChips}
            clearInputValueOnChange={true}
            fullWidth={false}
            value={chipValues}
            onAdd={(chip: string) => handleAddChip(chip)}
            onBeforeAdd={(chip: string) => handleOnBeforeAddChip(chip)}
            onDelete={(chip: string, index: number) => handleDeleteChip(chip, index)}
            InputProps={inputProps}
            aria-autocomplete={"none"}
        />
        <Tooltip aria-label="View more"
                 title={t('enterprise.dashboard.popup.tooltip', {parcoursName: parcoursAmount.name}) || parcoursAmount.name}>
            <IconButton aria-label="Infos" className={classes.info} onClick={openModal}>
                <InfoOutlinedIcon/>
            </IconButton>
        </Tooltip>

        <ParcoursDetailsInfo open={open} handleClose={handleClose} parcoursName={parcoursAmount.name}
                             catalogues={parcoursAmount.catalogues}/>
    </div>);
}


export function ParcoursDetailsInfo(props: { open: boolean, handleClose: () => void, parcoursName: string, catalogues: Catalogue[] }) {
    const {t} = useTranslation();
    const theme = useTheme();
    const useStyles = makeStyles({
        title: {
            color: theme.palette.primary.main
        },
    });
    const classes = useStyles();


    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.title}>
                    {props.parcoursName}
                </DialogTitle>
                <DialogContent>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow key={nanoid()}>
                                <TableCell>{t('enterprise.dashboard.users.phase')}</TableCell>
                                <TableCell>{t('enterprise.dashboard.users.service')}</TableCell>
                                <TableCell
                                    align="right">{t('enterprise.dashboard.users.nbSeances')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortCatalogue(props.catalogues)?.map((cat: Catalogue, index: number) => (
                                <TableRow key={nanoid() + index}>
                                    <TableCell component="th" scope="row">
                                        {t(`catalogue.phases.${cat.phase}`)}
                                    </TableCell>
                                    <TableCell>{t(`catalogue.services.${cat.service}`)}</TableCell>
                                    <TableCell align="right">{cat.heures}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary" autoFocus>
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}