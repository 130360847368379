import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {StripeAccountModel} from "../models/stripe-account.model";


export class StripeAccountService {

    endpoint = '/api/v1/stripeAccount';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue durant le paiement, Veuillez réessayer s'il vous plait`;

    public async oauthUrl(): Promise<string> {
        return await axios.get(this.endpoint + "/oauthUrl")
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in stripeAccount oauthurl retrieving: " + error);
                throw error;
            });
    }

    public async getByUser(userID: string): Promise<StripeAccountModel> {
        return await axios.get(this.endpoint + "/user/" + userID)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in stripeAccount retrieving: " + error);
                throw error;
            });
    }

    public async oauthStart(scope: string | null, code: string| null, state: string| null,
                            error: string| null, error_description: string| null): Promise<StripeAccountModel> {
        return await axios.get(this.endpoint +
            `/oauth?scope=${scope}&code=${code}&state=${state}&error=${error || ''}&error_description=${error_description || ''}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in stripeAccount oauthStart: " + error);
                throw error;
            });
    }

    public async remove(): Promise<StripeAccountModel> {
        return await axios.delete(this.endpoint)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in stripeAccount retrieving: " + error);
                throw error;
            });
    }
}