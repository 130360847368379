import {Divider, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import clsx from 'clsx';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Assignment, Cancel, Euro, EventAvailable, Home, TimerOutlined} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useTranslation} from "react-i18next";
import useDashboardCoachHook from "../DashboardCoachHook";
import ListAltIcon from "@material-ui/icons/ListAlt";

interface Props {
    toggleDrawer: (open: boolean) => any
}

export default function SliderCoachContent({toggleDrawer}: Props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const {
        logout,
        dashboard,
        learners,
        resume,
        paiement,
        profile,
        pendingConfirmation,
        modules
    } = useDashboardCoachHook()

    return (<div
        className={clsx(classes.list)}
        role="presentation">
        <div
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            className={classes.close}>
            <Typography variant={'h6'} className={classes.closeText}>Bring Your Best</Typography>
            <Typography variant={'h6'} className={classes.closeIcon}><Cancel/></Typography>
        </div>
        <Divider/>
        <List>
            <ListItem button key={'home'} onClick={dashboard}>
                <ListItemIcon><Home/></ListItemIcon>
                <ListItemText>{t('menu.home')}</ListItemText>
            </ListItem>
            <ListItem button key={'info'} onClick={profile}>
                <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                <ListItemText>{t('menu.profile')}</ListItemText>
            </ListItem>
            <ListItem button key={'presentation'} onClick={resume}>
                <ListItemIcon><Assignment/></ListItemIcon>
                <ListItemText>{t('menu.fichePresentation')}</ListItemText>
            </ListItem>
            <ListItem button key={'modules'} onClick={modules}>
                <ListItemIcon><ListAltIcon/></ListItemIcon>
                <ListItemText>{t('menu.myModules')}</ListItemText>
            </ListItem>
            <ListItem button key={'users'} onClick={learners}>
                <ListItemIcon><EventAvailable/></ListItemIcon>
                <ListItemText>{t('menu.learner')}</ListItemText>
            </ListItem>
            <ListItem button key={'purchase'} onClick={paiement}>
                <ListItemIcon><Euro/></ListItemIcon>
                <ListItemText>{t('menu.paiement')}</ListItemText>
            </ListItem>
            <ListItem button key={'purchase'} onClick={pendingConfirmation}>
                <ListItemIcon><TimerOutlined/></ListItemIcon>
                <ListItemText>{t('menu.waitingConfirmation')}</ListItemText>
            </ListItem>
            <ListItem button key={'logout'} onClick={logout}>
                <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                <ListItemText>{t('menu.logout')}</ListItemText>
            </ListItem>
        </List>
    </div>);
}