import {Container, Grid, Paper, TableContainer} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import AvancementTable from "../../Common/AvancementTable";
import {SuiviConsommationContainer} from "../../../container/SuiviConsommationContainer";
import PageTitle from "../../Common/PageTitle";


export default function LearnerAvancement() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");

    const suiviConsoState = SuiviConsommationContainer.useContainer();


    return (<Container maxWidth="lg" className={classes.root}>

        <PageTitle title={t("menu.consosTitle")}/>
        <Grid container className={classes.row} direction="row" alignItems={'stretch'}>
            <Paper className={classes.paper}>
                <div className={classes.content}>
                    {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
                    <TableContainer component={Grid} className={classes.table}>
                        <AvancementTable suiviConsos={suiviConsoState.suiviConso} displayAvancement={true}/>
                    </TableContainer>
                </div>
            </Paper>
        </Grid>


    </Container>);
}
