import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
// @ts-ignore
import {SketchPicker} from "react-color";
import {InputField} from "../../../../Common/FormFields";
import useBilanProStepsHook from "../BilanProStepsHook";
import {BilanProStepProps} from "../FormModel/validationSchema";
import {setFormValuesInLocalStorage} from "../../../../../utils/form.util";
import {Link} from "@material-ui/core";


export const BilanProStep3 = (props: BilanProStepProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    useBilanProStepsHook(props.setFieldValue, props.validateForm, props.setFieldTouched, 'plaisirs', 'irritants');
    const plaisirsHintLink = process.env.REACT_APP_PUBLIC_CDN + '/bilanpro/Exemples - Sources de Motivation - document support.docx';

    const handleOnChange = (event: any) => {
        props.handleChange(event);
        const values = localStorage.getItem('bilanPro');
        let parsed = values ? JSON.parse(values) : null;
        const key = event.target.name;
        const value = event.target.value
        props.setFieldValue(key, value, true);
        parsed = {...parsed, ...{[key]: value}}
        parsed = Object.assign({[key]: value}, parsed || {});
        setFormValuesInLocalStorage('bilanPro', parsed);
    }

    return (
        <>
            <InputField
                className={classes.formElement}
                multiline
                fullWidth
                variant={'outlined'}
                id="plaisirs"
                name="plaisirs"
                rows={10}
                label={t('catalogue.BILAN_PRO.plaisirs')}
                type="text"
                onChange={handleOnChange}
                helperTextInput={
                    <Link href={plaisirsHintLink} target="_blank" rel="noopener">
                        {t('catalogue.BILAN_PRO.uniqueHint', {fieldName: 'sources de motivations'})}
                    </Link>
                }
            />
            <InputField
                className={classes.formElement}
                multiline
                fullWidth
                variant={'outlined'}
                id="irritants"
                name="irritants"
                rows={10}
                label={t('catalogue.BILAN_PRO.irritants')}
                type="text"
                onChange={handleOnChange}

            />
        </>
    );
}

export default BilanProStep3;