import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            flexGrow: 1,
            textAlign: 'center',
            marginBottom: '30px',
            fontFamily: "'Nunito', sans-serif",
            fontSize: "35px",
            fontWeight: theme.typography.fontWeightBold
        },
        subtitle: {
            flexGrow: 1,
            textAlign: 'center',
            marginBottom: '30px',
            fontFamily: "'Nunito', sans-serif",
            fontSize: "35px",
            color: "#707070"
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            margin: theme.spacing(3),
            maxWidth: '50%',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        formElement: {
            margin: theme.spacing(1)
        },
        formSubmit: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        formCancel: {
            margin: theme.spacing(1),
        },
        signInRoot: {}
    }),
);