import {useTheme} from "@material-ui/core/styles";
import React from "react";
import {Avatar, Link, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useStyles} from "./style";
import {LearnerCoachModel} from "../../../models/learner-coach.model";
import CoachResumePopup from "../../Coach/CoachResumePopup";
import _ from "lodash";
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import {getPageFromService, sortCoachByLastName} from "../../../utils/user.utils";
import {useNavigate} from "react-router";


interface CoachListTableProps {
    coachSummaries: LearnerCoachModel[];
}


export default function CoachListGrid(props: CoachListTableProps) {
    const {t} = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [coachSummary, setCoachSummary] = React.useState<LearnerCoachModel | null>(null);

    const showCoachResume = (coachSummaryModel: LearnerCoachModel) => {
        setOpen(true);
        setCoachSummary(coachSummaryModel);
    }
    const handleClosePopup = () => {
        setOpen(false);
    };

    const groupedCoachSummariesPerCoach: _.Dictionary<LearnerCoachModel[]> = _.groupBy(props.coachSummaries?.sort(sortCoachByLastName), coachSummary => coachSummary.user.userID);

    const moduleTitle = (service: ServiceEnum) => {
        return t(`catalogue.services.learner.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.learner.${service}`)
    };

    const routeToService = (phase: PhaseEnum, service: ServiceEnum) => {
        const url = getPageFromService(phase, service);
        navigate(url);
    }

    const coachModuleNames = (learnerCoachModels: LearnerCoachModel[]) => {
        return learnerCoachModels.map((coachSummary, index) =>
            (<React.Fragment key={coachSummary.service + index + 'route'}>
                <Link onClick={() => routeToService(coachSummary.phase, coachSummary.service)}>
                    {moduleTitle(coachSummary.service)}
                </Link>
                <span>{(index < learnerCoachModels.length - 1 ? ', ' : '.')}</span>
            </React.Fragment>)
        );
    }

    return (
        <React.Fragment>
            <CoachResumePopup coachSummary={coachSummary} open={open} handleClose={handleClosePopup}/>
            <div>
                {Object.keys(groupedCoachSummariesPerCoach)?.map((item, index) => (
                    <Paper key={'CoachListTableRow' + index} className={classes.listContainer} elevation={0}
                           variant={'outlined'}>
                        <div className={classes.avatarContainer}>
                            <Avatar alt={groupedCoachSummariesPerCoach[item][0].user?.email}
                                    className={classes.avatar}
                                    src={groupedCoachSummariesPerCoach[item][0]?.user?.avatar || ''}/>
                        </div>
                        <div className={classes.listItem}>
                            <Typography>
                                Bonjour, je suis
                                <span> <Link href="#"
                                             className={classes.bold}
                                             onClick={() => showCoachResume(groupedCoachSummariesPerCoach[item][0] as LearnerCoachModel)}>{groupedCoachSummariesPerCoach[item][0]?.user.firstname}</Link></span>,
                                j’aurais le plaisir de vous accompagner sur les modules
                                : <span className={classes.bold}>
                                    {coachModuleNames(groupedCoachSummariesPerCoach[item])}
                                </span>
                            </Typography>
                            <Typography className={classes.seeCoachResume}>
                                {t('learner.coachs.findMyBio')} <Link href="#"
                                                                      onClick={() => showCoachResume(groupedCoachSummariesPerCoach[item][0] as LearnerCoachModel)}>ici</Link>
                            </Typography>
                        </div>
                    </Paper>
                ))}
            </div>


        </React.Fragment>
    );
}