import React, {useEffect, useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
import './styles.sass'
// @ts-ignore
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {SuiviConsommationContainer} from "../../../../../container/SuiviConsommationContainer";
import {SuiviConsommationModel} from "../../../../../models/suivi-consommation.model";
import {PhaseEnum, ServiceEnum} from "../../../../../models/catalogue.model";
import {UserService} from "../../../../../services/user.service";
import {BilanProContainer} from "../../../../../container/BilanProContainer";
import {CreateOutlined, EventNoteOutlined} from "@material-ui/icons";
import {useNavigate} from "react-router";
import {isAllBilanProFieldIsFilled} from "../../../../../utils/bilanPro.util";
import {AlertDialog} from "../../../../Common/Dialog";
import PageTitle from "../../../../Common/PageTitle";

export const BilanProGuidelines = () => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [notAuthorized, setNotAuthorized] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [isBilanProQuestionsCompleted, setBilanProQuestionsCompleted] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const suiviConsoState = SuiviConsommationContainer.useContainer();
    const bilanProState = BilanProContainer.useContainer();
    const user = new UserService().getCurrentUser();

    const fill = () => {
        navigate("/learner/bilanpro");
    }
    const start = () => {
        if (isBilanProQuestionsCompleted) {
            navigate("/learner/bilanpro/booking");
        } else {
            setOpenAlert(true);
        }
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    useEffect(() => {
        const auth = suiviConsoState.suiviConso?.find((suiviconso: SuiviConsommationModel) => suiviconso.phase === PhaseEnum.INTROSPECTION
            && suiviconso.service === ServiceEnum.BILAN_PRO);
        setNotAuthorized(auth === undefined);
    }, [suiviConsoState.suiviConso]);

    useEffect(() => {
        (async () => {
            await bilanProState.loadBilanPro(user.userID);
        })()
    }, []);

    useEffect(() => {
        (async () => {
            setBilanProQuestionsCompleted(isAllBilanProFieldIsFilled(bilanProState.bilanPro));
        })()
    }, [bilanProState.bilanPro]);

    useEffect(() => {
        if (notAuthorized) {
            setErrorMessage(t('notAuthorized'));
        } else {
            setErrorMessage('');
        }
    }, [notAuthorized]);

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Grid container className={classes.row} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                <AlertDialog open={openAlert} handleClose={handleCloseAlert}
                             displayConfirm={false}
                             title={t('catalogue.BILAN_PRO.guidelines.alert.title')}
                             contentText={t('catalogue.BILAN_PRO.guidelines.alert.content')}/>

                <PageTitle title={t("catalogue.BILAN_PRO.title")}/>

                <Paper square className={classes.paper} elevation={1}>
                    {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
                    <div className={classes.guidelineTitle}>
                        <Typography variant="h6">{t("catalogue.BILAN_PRO.guidelines.title")}</Typography>
                    </div>
                    <div className={classes.description}>
                        <Typography variant="body2">{t("catalogue.BILAN_PRO.guidelines.paragraph1")}</Typography>
                    </div>
                    <div className={classes.description}>
                        <Typography variant="body2">{t("catalogue.BILAN_PRO.guidelines.paragraph2")}</Typography>
                        <List>
                            <ListItem>
                                <Typography
                                    variant="body2">{t("catalogue.BILAN_PRO.guidelines.paragraph2_list1")}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography
                                    variant="body2">{t("catalogue.BILAN_PRO.guidelines.paragraph2_list2")}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography
                                    variant="body2">{t("catalogue.BILAN_PRO.guidelines.paragraph2_list3")}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography
                                    variant="body2">{t("catalogue.BILAN_PRO.guidelines.paragraph2_list4")}</Typography>
                            </ListItem>
                        </List>
                    </div>
                    <div className={classes.description}>
                        <Typography variant="body2">{t("catalogue.BILAN_PRO.guidelines.paragraph3")}</Typography>
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button onClick={fill} color="primary" variant="outlined"
                                startIcon={<CreateOutlined/>}>
                            {t("catalogue.BILAN_PRO.guidelines.fill")}
                        </Button>
                        <Button onClick={start} color="primary" variant="contained"
                                startIcon={<EventNoteOutlined/>}>
                            {t("catalogue.BILAN_PRO.guidelines.start")}
                        </Button>
                    </div>
                </Paper>
            </Grid>
        </Container>


    );
}

export default BilanProGuidelines;