import React from "react";
// @ts-ignore
import {CKEditor} from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {sanitize} from "../../../utils/security.util";

export interface RichTextEditorProps {
    initialState: string;
    setValue: (value: string) => void;
    className?: any;
    disabled?: any;
}

export function RichTextEditor(props: RichTextEditorProps) {


    return (
        <CKEditor
            editor={ClassicEditor}
            data={sanitize(props.initialState)}
            disabled={props.disabled}
            onReady={(editor: any) => {
                // You can store the "editor" and use when it is needed.
                // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event: any, editor: any) => {
                const data = editor.getData();
                // console.log({event, editor, data});
                props.setValue(sanitize(data));
            }}
            onBlur={(event: any, editor: any) => {
                // console.log('Blur.', editor);
            }}
            onFocus={(event: any, editor: any) => {
                // console.log('Focus.', editor);
            }}
        />
    );

}