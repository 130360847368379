import {PhaseEnum, ServiceEnum} from "./catalogue.model";
import {NotificationService} from "../services/notification.service";

export interface NotificationUser {
    firstname: string;
    lastname: string;
}

export enum NotificationTypeEnum {
    SESSION_CONFIRM = 'SESSION_CONFIRM'
}

export interface NotificationModelPaginate {
    total: number;
    data: NotificationModel[];
}
export interface NotificationModel {

    id: string;

    notificationID: string;

    fromUserID: string;
    fromUser: NotificationUser;
    toUserID: string;
    read: boolean;

    type: NotificationTypeEnum;

    createdDate: Date;

    service: ServiceEnum;

    phase: PhaseEnum;
    session: number;
}