import {createStyles, makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            textAlign: 'center'
        },
        subtitle: {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        main: {
            marginBottom: theme.spacing(3),
        },
        subTitleWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end'
        }
    }),
);