import {createStyles, makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) =>
    createStyles({
        row: {
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(2),
        },
    }),
);