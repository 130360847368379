import axios, {AxiosError} from "axios";
import {log} from "../utils/log";

export class AmazonService {

    endpoint = '/api/v1/cdn';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;


    public async getWelcomeVideoUrl(): Promise<string> {
        return await axios.get(`${this.endpoint}/welcomeVideo`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in welcomevideo url retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });

    }

}
