import axios, {AxiosError} from "axios";
import {
    AddEnterpriseUserModel,
    CheckAddEnterpriseUser,
    CheckAddEnterpriseUserOutput,
    EnterpriseModel
} from "../models/enterprise.model";
import {log} from "../utils/log";
import {RegisterUser} from "../models/register.model";
import {ParcoursAmount} from "../models/parcours.model";
import {Pagination} from "../models/purchase.model";

export class EnterpriseService {

    enterpriseEndpoint = '/api/v1/enterprise';
    public static defaultPageSize = 10;

    public async registerEnterpriseUser(user: RegisterUser): Promise<any> {
        return await axios.post(`${this.enterpriseEndpoint}/register`, user).then((response) => {
            log.debug("Success register" + JSON.stringify(response));
            return response.data.accessToken;
        }).catch((error) => {
            log.error("Error register" + JSON.stringify(error.response));
            throw error;
        });
    }

    public async checkoutPayUser(sessionID: string): Promise<any> {
        return await axios.post(`${this.enterpriseEndpoint}/addUser/pay?sessionID=${sessionID}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in creating user: " + error);
                throw error;
            });
    }

    public async checkoutCreateUser(value: Partial<AddEnterpriseUserModel | null>): Promise<any> {
        return await axios.post(`${this.enterpriseEndpoint}/addUser/checkout`, value)
            .then(response => {
                return response.data as string;
            }).catch((error: AxiosError) => {
                log.error("Error in creating user: " + error);
                throw error;
            });
    }

    public async updateEnterpriseInfos(enterpriseID: string, data: EnterpriseModel): Promise<any> {
        return await axios
            .put(this.enterpriseEndpoint + `/${enterpriseID}`, {...data, enterpriseID})
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw error;
            });
    }

    public async getEnterpriseInfos(enterpriseID: string): Promise<any> {
        return await axios
            .get(this.enterpriseEndpoint + `/${enterpriseID}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw error;
            });
    }


    public async getUsers(page: number, maxByPage: number = EnterpriseService.defaultPageSize): Promise<any> {
        return await axios.get(`${this.enterpriseEndpoint}/users?range=[${page},${maxByPage}]`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in getting enterprise users: " + error);
                throw error;
            });
    }


    public async getAmount(): Promise<ParcoursAmount[]> {
        return await axios.get(`${this.enterpriseEndpoint}/amount`)
            .then(response => {
                return response.data as ParcoursAmount[];
            }).catch((error: AxiosError) => {
                log.error("Error in parcours retrieving: " + error);
                throw error;
            });
    }


    public async checkAddUser(emailAndParcours: CheckAddEnterpriseUser): Promise<CheckAddEnterpriseUserOutput> {
        return await axios.post(`${this.enterpriseEndpoint}/addUser/check`, emailAndParcours)
            .then(response => {
                return response.data as CheckAddEnterpriseUserOutput;
            }).catch((error: AxiosError) => {
                log.error("Error in creating user: " + error);
                throw error;
            });
    }

}
