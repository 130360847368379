import {Button, Dialog, DialogContent, IconButton, useMediaQuery} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import {UserService} from "../../../services/user.service";
import {AlertDialog} from "../Dialog";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CustomVideoRecorder from "../VideoRecorder";

export interface CustomVideoRecorderButtonsProps {
    submitVideo: (userID: string, file: File | null) => Promise<any>;
    file: string | null;
    timeLimit?: number | null;
}

export default function CustomVideoRecorderButtons(props: CustomVideoRecorderButtonsProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const userService = new UserService();

    const [useVideoInput, setUseVideoInput] = useState(false);
    const [openRecordPopup, setOpenRecordPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [initialFile, setInitialFile] = useState<string | null>(null);


    const userID = userService.getCurrentUser().userID;

    const uploadVideo = () => {
        setUseVideoInput(true);
        setOpenRecordPopup(true);
    }

    const recordVideo = () => {
        setUseVideoInput(false);
        setOpenRecordPopup(true);
    }
    const submitVideo = async (userID: string, file: File | null): Promise<any> => {
        await props.submitVideo(userID, file);
        setOpenRecordPopup(false);
    }

    const [openDeleteVideo, setOpenDeleteVideo] = React.useState(false);

    const openDeleteVideoModal = () => {
        setOpenDeleteVideo(true);
    }
    const handleCloseDeleteVideo = () => {
        setOpenDeleteVideo(false);
    }
    const handleConfirmDeleteVideo = async () => {
        setOpenDeleteVideo(false);
        setErrorMessage(t(""));
        await props.submitVideo(userID, null).then(() => {
            setErrorMessage("");
        }).catch(() => {
            setErrorMessage(t("myProfile.deleteError"));
        });
    }

    useEffect(() => {
        setInitialFile(props.file);
    }, [props.file]);


    return (
        <>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <DialogRecordVideo open={openRecordPopup} onClose={() => setOpenRecordPopup(false)}
                               useVideoInput={useVideoInput} handleSubmit={submitVideo} timeLimit={props.timeLimit}/>
            <AlertDialog open={openDeleteVideo} handleClose={handleCloseDeleteVideo}
                         handleConfirmDelete={handleConfirmDeleteVideo}
                         title={t('coach.resume.deleteVideoConfirm.title')}
                         contentText={t('coach.resume.deleteVideoConfirm.description')}/>
            <Button onClick={recordVideo} color="default" variant="outlined"
                    className={classes.videoButtonAction} startIcon={<VideocamOutlinedIcon/>}>
                {t("coach.resume.record")}
            </Button>
            <Button onClick={uploadVideo} color="default" variant="outlined"
                    className={classes.videoButtonAction} startIcon={<CloudUploadOutlinedIcon/>}>
                {t("coach.resume.upload")}
            </Button>
            {initialFile && <Button onClick={openDeleteVideoModal} color="secondary" variant="outlined"
                                    className={classes.videoButtonAction} startIcon={<DeleteOutlineOutlinedIcon/>}>
                {t("coach.resume.delete")}
            </Button>}

        </>);
}

export function DialogRecordVideo(props: {
    useVideoInput: boolean,
    handleSubmit: (userID: string, file: File | null) => Promise<any>,
    open: boolean,
    onClose: () => void,
    timeLimit?: number | null
}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const useStyles = makeStyles({
        button: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1)
        },
        dialog: {
            display: 'flex',
            height: 'calc(100vh - 20vh)',
            [theme.breakpoints.down('sm')]: {
                height: 'calc(90vh)',
            },
        },
        video: {
            flexGrow: 1
        },
        closeIcon: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    });
    const classes = useStyles();
    const FIVE_MINUTE = 5 * 60 * 1000;
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'md'}
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="delete user account"
                aria-describedby="delete user account"
            >
                <MuiDialogTitle disableTypography className={classes.closeIcon}>
                    {props.onClose ? (
                        <IconButton aria-label="close" onClick={props.onClose}>
                            <CloseIcon/>
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <DialogContent className={classes.dialog}>
                    <CustomVideoRecorder className={classes.video}
                                         useVideoInput={props.useVideoInput}
                                         handleSubmit={props.handleSubmit}
                                         timeLimit={props.timeLimit || FIVE_MINUTE}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}