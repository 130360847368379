import {createStyles, makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles(() =>
    createStyles({
        column: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'stretch',
            alignContent: 'stretch',
            minheight: '100%',
            maxheight: '100%',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                transform: 'scale(1.1)'
            }
        },


    })
);