import {
    Container,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";
import React, {useEffect} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router";
import BybButton from "../../Common/BybButton";
import {StripeAccountService} from "../../../services/stripe-account.service";
import Alert from "@material-ui/lab/Alert";
import {CoachStripeAccountContainer} from "../../../container/CoachStripeAccountContainer";
import {PurchaseService} from "../../../services/purchase.service";
import {GridRowsProp} from "@material-ui/data-grid";
import {EnterpriseService} from "../../../services/enterprise.service";
import {CoachPaymentService} from "../../../services/coach-payment.service";
import {UserService} from "../../../services/user.service";
import {CoachPaymentModel} from "../../../models/coach-payment.model";
import {getFullnameOrEmail} from "../../../utils/user.utils";
import {formatDateToStringDate} from "../../../utils/date.util";
import {nanoid} from "nanoid";
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";
import PageTitle from "../../Common/PageTitle";


export default function CoachPaiements() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const stripeAccountService = new StripeAccountService();
    const coachPaymentService = new CoachPaymentService();
    const userService = new UserService();
    const coachStripeAccountState = CoachStripeAccountContainer.useContainer();
    const userID = userService.getCurrentUser().userID;


    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorStripe, setErrorStripe] = React.useState<string>("");
    const [errorPaymentList, setErrorPaymentList] = React.useState<string>("");
    const [success, setSuccess] = React.useState<string>("");
    const [oAuthUrl, setOAuthUrl] = React.useState<string | null>(null);
    const [page, setPage] = React.useState<number>(0);
    const [loadingTable, setLoadingTable] = React.useState<boolean>(false);
    const [rowCount, setRowCount] = React.useState(0);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const navigate = useNavigate();

    const handleConnectStripe = () => {
        if (oAuthUrl) {
            window.location.href = oAuthUrl;
        }
    }


    const labelDisplayedRows = ({from, to, count}: { from: number, to: number, count: number }) => {
        const currentPage = page + 1;
        const pageCount = Math.ceil(count / EnterpriseService.defaultPageSize);
        const total = pageCount < 1 ? 1 : pageCount;
        return t('pagination', {current: currentPage, total: total});
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const serviceTitle = (service: string): string => {
        return t(`catalogue.services.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.${service}`)
    };
    const phaseTitle = (phase: string): string => {
        return t(`catalogue.phases.${phase}`).split(':')?.[0].trim()
            || t(`catalogue.phases.${phase}`)
    };

    const stripeAccountConnected = (): boolean => !isNullOrUndefinedOrEmpty(coachStripeAccountState.getStripeAccount()?.stripeUserId);

    const roundAmount = (amount: number): number => {
        return +amount.toFixed(2);
    }

    useEffect(() => {
        if (Array.from(params).length > 0) {
            setSuccess("");
            setErrorStripe("");
            setLoading(true);
            coachStripeAccountState.oauthStart(
                params.get('scope') || '',
                params.get('code') || '',
                params.get('state') || '',
                params.get('error') || '',
                params.get('error_description') || ''
            ).then(() => {
                setSuccess(t("coach.paiements.saveSuccess"));
                setLoading(false);
                navigate('/coach/payment', {replace: true})
            }).catch(() => {
                setErrorStripe(t('unexpectedError'));
                navigate('/coach/payment', {replace: true})
            });
        }

    }, [params.get('code'), params.get('state'), params.get('error')]);

    useEffect(() => {
        setLoading(true);
        setErrorStripe("");
        stripeAccountService.oauthUrl().then((url: string) => {
            setOAuthUrl(url);
            setLoading(false);
        }).catch(() => {
            setErrorStripe(t('unexpectedErrorLoading'));
        });
    }, []);

    React.useEffect(() => {
            (async () => {
                setLoadingTable(true);
                const pageToFetch = (page < 0) ? 0 : page;
                const {
                    total,
                    data
                } = await coachPaymentService.getAll(userService.getCurrentUser().userID, pageToFetch)
                    .then((res) => {
                        return res;
                    })
                    .catch(() => {
                        setErrorPaymentList(t('unexpectedErrorLoading'))
                        return {total: 0, data: []};
                    })
                    .finally(() => {
                        setLoadingTable(false);
                    });
                setRows(data);
                setRowCount(total);
            })();
        },
        // eslint-disable-next-line
        [page]);


    // @ts-ignore
    return (<>
        <Container maxWidth="lg" className={classes.root}>
            <PageTitle title={t("coach.paiements.title")}/>

            {errorStripe && <Alert severity="error" className={classes.row}>{errorStripe}</Alert>}
            {success && <Alert severity="success" className={classes.row}>{success}</Alert>}
            {!stripeAccountConnected() && <Grid container className={classes.row} direction="row"
                                                justify={'flex-start'} alignItems={'stretch'}>
                <Paper className={classes.paper}>
                    <div className={classes.formWrapper}>
                        <Typography variant="caption">{t("coach.paiements.saveCard")}</Typography>
                    </div>
                    <div className={classes.buttonWrapper}>
                        <BybButton onClick={handleConnectStripe}
                                   color={"primary"}
                                   fullWidth={false}
                                   variant={"contained"}
                                   disabled={loading || !oAuthUrl}
                                   loading={loading}
                                   label={t('coach.paiements.stripeButton')}/>
                    </div>
                </Paper>
            </Grid>}


            <Grid container className={classes.row} direction="row"
                  justify={'flex-start'} alignItems={'stretch'}>
                {errorPaymentList && <Alert severity="error" className={classes.row}>{errorPaymentList}</Alert>}
                <Paper className={classes.paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('coach.paiements.history.date')}</TableCell>
                                <TableCell>{t('coach.paiements.history.learner')}</TableCell>
                                <TableCell>{t('coach.paiements.history.phase')}</TableCell>
                                <TableCell>{t('coach.paiements.history.service')}</TableCell>
                                <TableCell>{t('coach.paiements.history.session')}</TableCell>
                                <TableCell>{t('coach.paiements.history.amount')}</TableCell>
                                <TableCell>{t('coach.paiements.history.status')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadingTable && <TableRow key={nanoid()} className={classes.rowProgress}>
                                <TableCell colSpan={6}><LinearProgress/></TableCell>
                            </TableRow>}
                            {(rows).map((row, index) => (
                                <TableRow key={nanoid() + index}>
                                    <TableCell>
                                        {formatDateToStringDate((row as CoachPaymentModel).createdDate)}
                                    </TableCell>
                                    <TableCell>
                                        {getFullnameOrEmail((row as CoachPaymentModel).learner.firstname,
                                            (row as CoachPaymentModel).learner.lastname,
                                            (row as CoachPaymentModel).learner.email)
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {phaseTitle((row as CoachPaymentModel).phase)}
                                    </TableCell>
                                    <TableCell>
                                        {serviceTitle((row as CoachPaymentModel).service)}
                                    </TableCell>
                                    <TableCell>
                                        {(row as CoachPaymentModel).session}
                                    </TableCell>
                                    <TableCell>
                                        {roundAmount((row as CoachPaymentModel).amount)} €
                                    </TableCell>
                                    <TableCell>
                                        {(row as CoachPaymentModel).status}
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow key={nanoid() + 'pagination'}>
                                <TablePagination
                                    colSpan={5}
                                    count={+rowCount}
                                    page={page}
                                    onChangePage={handlePageChange}
                                    rowsPerPage={+PurchaseService.defaultPageSize}
                                    rowsPerPageOptions={[]}
                                    labelDisplayedRows={labelDisplayedRows}/>
                            </TableRow>
                        </TableFooter>
                    </Table>

                </Paper>
            </Grid>
        </Container>
    </>);
}
