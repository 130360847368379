import {Container, Grid} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {AccountCircleOutlined} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import DashboardUserContentPaper from "../../Learner/DashboardLearnerContentPaper";
import {useTranslation} from "react-i18next";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import GroupIcon from "@material-ui/icons/Group";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {useNavigate} from "react-router";
import ContactUsBox from "../../Common/ContactUsBox";


export default function DashboardEnterpriseContent() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const purchase = () => {
        navigate('/enterprise/purchases');
    }
    const addUser = () => {
        navigate('/enterprise/addUser');
    }
    const myUsers = () => {
        navigate('/enterprise/users');
    }
    const profile = () => {
        navigate('/enterprise/profile');
    }

    return (<Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3} className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={profile}>
                <DashboardUserContentPaper icon={() => <AccountCircleOutlined/>}
                                           menuTitle={t('menu.profile')}
                                           menuDescription={t('menu.infos')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={addUser}>
                <DashboardUserContentPaper icon={() => <GroupAddOutlinedIcon/>}
                                           menuTitle={t('menu.addUsers')}
                                           menuDescription={t('menu.addUsersDescription')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={myUsers}>
                <DashboardUserContentPaper icon={() => <GroupIcon/>}
                                           menuTitle={t('menu.myUsers')}
                                           menuDescription={t('menu.myUsersDescription')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={purchase}>
                <DashboardUserContentPaper icon={() => <ShoppingCartIcon/>}
                                           menuTitle={t('menu.myPurchases')}
                                           menuDescription={t('menu.myPurchasesDescription')}/>
            </Grid>
            <ContactUsBox/>
        </Grid>

    </Container>);
}