import * as Yup from 'yup';
import i18n from '../../../../../locales/i18n';

const MIN_CHARRACTERS = 500;

export interface BilanProStepProps {
    formik?: any;
    index?: number;
    isStepCompleted?: (index: string, completed: boolean) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    handleChange?: any
    values?: any
    style?: any
    validateForm?: any
    setFieldTouched?: any
}

export default [
    Yup.object({
        parcoursProDescription: Yup
            .string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step1Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
        talents: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step1Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
    }),

    Yup.object().shape({
        strengths: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step2Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
        weaknesses: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step2Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required'))
    }),

    Yup.object().shape({
        plaisirs: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step3Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
        irritants: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step3Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
    }),

    Yup.object().shape({
        valeurs: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step4Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
        unique: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step4Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
    }),

    Yup.object().shape({
        doubts: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step5Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),

        evolution: Yup.string()
            .min(MIN_CHARRACTERS, i18n.t('catalogue.BILAN_PRO.validation.min', {
                field: i18n.t('catalogue.BILAN_PRO.step5Title'),
                min: MIN_CHARRACTERS
            }))
            .required(i18n.t('catalogue.BILAN_PRO.validation.required')),
    }),

];
