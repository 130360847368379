import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {ThemeProvider, useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {Typography} from "@material-ui/core";
import PasswordInput from "../../components/Common/Password";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {UserRole} from "../../models/user.model";
import Alert from "@material-ui/lab/Alert";
import {log} from "../../utils/log";
import {RegisterUser} from "../../models/register.model";
import {EnterpriseService} from "../../services/enterprise.service";
import {preventDefault} from "../../utils/index.util";
import {passwordRegex} from "../../components/Common/MyProfile";
import BybButton from "../../components/Common/BybButton";
import {dashboardTheme} from "../DashboardEnterprise";
import {CustomCheckbox} from "../../components/Common/CustomCheckbox";


const SignUp = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const [loginSucessMessage, setLoginSucessMessage] = useState("");


    const validationSchema = yup.object({
        email: yup
            .string()
            .email(t("signUp.errors.email.invalid"))
            .required(t("signUp.errors.email.required")),
        password: yup
            .string()
            .matches(passwordRegex, t("signUp.errors.password.passwordHintText"))
            .required(t("signUp.errors.password.required")),
        entrepriseName: yup
            .string()
            .min(2, t("signUp.errors.enterpriseName.min"))
            .max(150, t("signUp.errors.enterpriseName.max"))
            .required(t("signUp.errors.enterpriseName.required")),
        title: yup
            .string()
            .required(t("signUp.errors.title.required")),
        firstname: yup
            .string()
            .min(2, t("signUp.errors.firstname.min"))
            .max(150, t("signUp.errors.firstname.max"))
            .required(t("signUp.errors.firstname.required")),
        lastname: yup
            .string()
            .min(2, t("signUp.errors.lastname.min"))
            .max(150, t("signUp.errors.lastname.max"))
            .required(t("signUp.errors.lastname.required")),
        acceptTermsOfService: yup
            .boolean()
            .oneOf([true], t("signUp.errors.acceptTermsOfService.required"))
            .required(t("signUp.errors.acceptTermsOfService.required"))
    });

    const
        formik = useFormik({
            initialValues: {
                email: '',
                password: '',
                firstname: '',
                lastname: '',
                type: UserRole.ENTREPRISE,
                entrepriseName: '',
                title: '',
                acceptTermsOfService: false
            } as RegisterUser,
            validationSchema: validationSchema,
            onSubmit: async (values, formikHelpers) => {
                formikHelpers.setSubmitting(true);
                setLoginSucessMessage("")
                setLoginErrorMessage("");
                await new EnterpriseService().registerEnterpriseUser(values as RegisterUser).then(() => {
                    setLoginSucessMessage(t("signUp.errors.successMessage", {email: values.email}));
                    formikHelpers.resetForm();
                }).catch(ko => {
                    formikHelpers.setSubmitting(false);
                    if (ko?.response?.status === 409) {
                        setLoginErrorMessage(t("signUp.errors.conflict"));
                    } else {
                        setLoginErrorMessage(t("signUp.errors.errorMessage"));
                    }
                    log.info("Error in signin: " + loginErrorMessage);
                })
                formikHelpers.setSubmitting(false);
            },
        });


    return (
        <ThemeProvider theme={dashboardTheme}>

            <div className={classes.root}>
                <div className={classes.content}>
                    <Typography variant="h5" noWrap className={classes.title}>
                        BringYourBest
                    </Typography>
                    <Typography variant="h6" noWrap className={classes.subtitle}>
                        {t('signUp.title')}
                    </Typography>
                    {loginErrorMessage && <Alert severity="error">{loginErrorMessage}</Alert>}
                    {loginSucessMessage && <Alert severity="success">{loginSucessMessage}</Alert>}

                    <form onSubmit={preventDefault && formik.handleSubmit}>
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="email"
                            name="email"
                            label={t('signUp.email')}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <PasswordInput
                            className={classes.formElement}
                            fullWidth
                            id="password"
                            name="password"
                            label={t('signUp.password')}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="lastname"
                            name="lastname"
                            label={t('signUp.lastname')}
                            type="text"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                            helperText={formik.touched.lastname && formik.errors.lastname}
                        />
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="firstname"
                            name="firstname"
                            label={t('signUp.firstname')}
                            type="text"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                            helperText={formik.touched.firstname && formik.errors.firstname}
                        />
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="entrepriseName"
                            name="entrepriseName"
                            label={t('signUp.enterpriseName')}
                            type="text"
                            value={formik.values.entrepriseName}
                            onChange={formik.handleChange}
                            error={formik.touched.entrepriseName && Boolean(formik.errors.entrepriseName)}
                            helperText={formik.touched.entrepriseName && formik.errors.entrepriseName}
                        />
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="title"
                            name="title"
                            label={t('signUp.userTitle')}
                            type="text"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                        <CustomCheckbox handleChange={formik.handleChange} errors={formik.errors}
                                        formName="acceptTermsOfService"/>
                        <BybButton classes={classes.formSubmit} disabled={formik.isValid && formik.isSubmitting}
                                   loading={formik.isSubmitting}
                                   label={t('signUp.submit')}
                                   color="primary" variant="contained"
                                   fullWidth
                                   type="submit">
                        </BybButton>

                    </form>


                    <div className={classes.signInRoot}>
                        <div className="sign-in-wrapper">
                            <span>
                                {t('signUp.alreadyAnAccount')} <b>BringYourBest</b>?
                            </span>
                            <Link className="sign-in-link" to="/signin">{t('signUp.signInLink')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default SignUp;