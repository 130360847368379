import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'left',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        title: {
            textAlign: 'left'
        },
        row: {
            marginTop: theme.spacing(1),
        },
        goBack: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        contentWrapper: {},
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        content: {
            flexGrow: 1,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(3),
        },
        step: {
            cursor: 'pointer'
        },
        button: {
            marginTop: theme.spacing(2),
        },
        heading: {},
    }),
);