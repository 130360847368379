import React, {useState} from 'react'
// @ts-ignore
import VideoRecorder from 'react-video-recorder'
import BybButton from "../BybButton";
import {useTranslation} from "react-i18next";
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import Alert from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";
import {UserService} from "../../../services/user.service";
import clsx from "clsx";
import {log} from "../../../utils/log";

export interface CustomVideoRecorderProps {
    renderDisconnectedView?: () => any,
    handleSubmit: (userID: string, file: File | null) => any,
    timeLimit: number, // timelimit in milliseconds
    replayVideoAutoplayAndLoopOff?: boolean,
    useVideoInput: boolean,
    className?: string
}

const CustomVideoRecorder: React.FC<CustomVideoRecorderProps> = (props: CustomVideoRecorderProps) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);
    const [video, setVideo] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const userID = new UserService().getCurrentUser().userID;

    const handleSubmit = () => {
        setErrorMessage("");
        setIsSubmitting(true);
        log.debug("Submit video recording");
        props.handleSubmit(userID, video).then((res: any) => {
            setIsSubmitting(false);
            setSnackBarOpen(true);
            log.info(`Submit video successfully for user ${userID} and video ${video?.name}`)
        }).catch((err: any) => {
            setIsSubmitting(false);
            setErrorMessage(err);
            log.info(`Submit video fails for user ${userID} and video ${video?.name}`)
        });
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        log.debug("Closing record popup");
        setSnackBarOpen(false);
    };

    const onStartRecording = () => {
        log.debug("Start recording");
        setIsRecording(true);
    }
    const onRecordingComplete = (videoBlob: Blob) => {
        // TODO check size of video
        const videoFileName = "webcam_byb.webm";
        log.debug("Complete recording");
        const videoFile = new File([videoBlob], videoFileName)
        setVideo(videoFile);
        setIsRecording(false);
    }

    return (

        <div className={clsx(classes.root, props.className)}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert severity="success" onClose={handleClose}>
                    {t('successMessage')}
                </Alert>
            </Snackbar>
            <VideoRecorder
                className={classes.video}
                onStartRecording={onStartRecording}
                onRecordingComplete={onRecordingComplete}
                renderDisconnectedView={props.renderDisconnectedView}
                showReplayControls={true}
                replayVideoAutoplayAndLoopOff={props.replayVideoAutoplayAndLoopOff}
                timeLimit={props.timeLimit}
                useVideoInput={props.useVideoInput}
            />
            <BybButton
                color={"primary"}
                classes={classes.button}
                onClick={handleSubmit}
                variant="contained"
                disabled={isSubmitting || isRecording}
                loading={isSubmitting}
                label={t("coach.resume.saveVideo")}>
            </BybButton>
        </div>
    )
}


CustomVideoRecorder.defaultProps = {
    renderDisconnectedView: () => <></>,
    useVideoInput: false,
    replayVideoAutoplayAndLoopOff: true, // True to disable autoplay
}

export default CustomVideoRecorder;