import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
        },
        chipInput: {
            flexGrow: 1,
        },
        info: {
            marginLeft: theme.spacing(1),
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
    })
);