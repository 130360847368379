import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useEffect, useState} from "react";

interface CustomAccordionProps {
    children: any;
    summary: any;
    detailsClass?: any;
    defaultExpanded?: boolean;
}

export const CustomAccordion = (props: CustomAccordionProps) => {

    const [expanded, setExpanded] = useState(props.defaultExpanded);

    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded);
    };
    useEffect(()=> {
        setExpanded(props.defaultExpanded);
    }, [props.defaultExpanded]);

    return (<Accordion onChange={handleChange('panel1')}
                       expanded={expanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {props.summary}
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );

}