import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {EnterpriseModel} from "../../../models/enterprise.model";
import {EnterpriseService} from "../../../services/enterprise.service";

interface MyProfileProps {
    enterpriseID: string;
}

const MyEnterpriseProfile = (props: MyProfileProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const [errorMessageProfile, setErrorMessageProfile] = useState("");
    const [sucessMessageProfile, setSucessMessageProfile] = useState("");
    const [enterpriseName, setEnterpriseName] = useState("");
    const [entrepriseAddress, setEntrepriseAddress] = useState("");
    const [entrepriseCountry, setEntrepriseCountry] = useState("");

    const enterpriseService = new EnterpriseService();
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const validationSchemaProfile = yup.object({
        entrepriseName: yup
            .string()
            .min(2, t("signUp.errors.enterpriseName.min"))
            .required(t("signUp.errors.enterpriseName.required")),
        address: yup
            .string(),
        country: yup
            .string()
    });


    React.useEffect(() => {
            let active = true;
            (async () => {
                if (props.enterpriseID) {
                    await enterpriseService.getEnterpriseInfos(props.enterpriseID)
                        .then((res: EnterpriseModel) => {
                            setEnterpriseName(res.entrepriseName || '');
                            setEntrepriseAddress(res.address || '');
                            setEntrepriseCountry(res.country || '');
                        })
                        .catch(() => {
                            setErrorMessageProfile(t('myProfile.enterpriseError'))
                        });
                }
                if (!active) {
                    return;
                }
            })();
            return () => {
                active = false;
            };
        },
        // eslint-disable-next-line
        [props.enterpriseID]);

    const formikProfileInfo = useFormik({
        initialValues: {
            entrepriseName: enterpriseName,
            address: entrepriseAddress,
            country: entrepriseCountry,
        } as EnterpriseModel,
        enableReinitialize: true,
        validationSchema: validationSchemaProfile,
        onSubmit: async (values, formikHelpers) => {
            formikHelpers.setSubmitting(true);
            setSucessMessageProfile("")
            setErrorMessageProfile("");
            await new EnterpriseService().updateEnterpriseInfos(props.enterpriseID, values as EnterpriseModel).then(() => {
                setSucessMessageProfile(t("myProfile.success"));
            }).catch(() => {
                formikHelpers.setSubmitting(false);
                setErrorMessageProfile(t("myProfile.error"));
                formikHelpers.resetForm();
            })
            formikHelpers.setSubmitting(false);
        },
    });


    return (


        <div className={classes.formProfile}>

            {errorMessageProfile && <Alert severity="error">{errorMessageProfile}</Alert>}
            {sucessMessageProfile && <Alert severity="success">{sucessMessageProfile}</Alert>}


            <form onSubmit={preventDefault && formikProfileInfo.handleSubmit}>
                <fieldset className={classes.fieldset}>

                    <TextField
                        className={classes.formElement}
                        fullWidth
                        id="entrepriseName"
                        name="entrepriseName"
                        label={t('myProfile.enterpriseName')}
                        value={formikProfileInfo.values.entrepriseName}
                        onChange={formikProfileInfo.handleChange}
                        error={formikProfileInfo.touched.entrepriseName && Boolean(formikProfileInfo.errors.entrepriseName)}
                        helperText={formikProfileInfo.touched.entrepriseName && formikProfileInfo.errors.entrepriseName}
                    />
                    <TextField
                        className={classes.formElement}
                        fullWidth
                        id="address"
                        name="address"
                        label={t('myProfile.enterpriseAddress')}
                        type="text"
                        value={formikProfileInfo.values.address}
                        onChange={formikProfileInfo.handleChange}
                        error={formikProfileInfo.touched.address && Boolean(formikProfileInfo.errors.address)}
                        helperText={formikProfileInfo.touched.address && formikProfileInfo.errors.address}
                    />
                    <TextField
                        className={classes.formElement}
                        fullWidth
                        id="country"
                        name="country"
                        label={t('myProfile.enterpriseCountry')}
                        type="text"
                        value={formikProfileInfo.values.country}
                        onChange={formikProfileInfo.handleChange}
                        error={formikProfileInfo.touched.country && Boolean(formikProfileInfo.errors.country)}
                        helperText={formikProfileInfo.touched.country && formikProfileInfo.errors.country}
                    />
                    <Button className={classes.formSubmit}
                            disabled={formikProfileInfo.isValid && formikProfileInfo.isSubmitting}
                            color="primary" variant="contained"
                            fullWidth
                            type="submit">
                        {t('myProfile.submitEnterpriseProfile')}
                    </Button>
                </fieldset>
            </form>
        </div>
    );
}

export default MyEnterpriseProfile;
