import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {NotificationModel, NotificationModelPaginate} from "../models/notification.model";
import {Pagination} from "../models/purchase.model";

export class NotificationService {

    endpoint = '/api/v1/notification';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;

    public static MAX_NOTIFICATION_PER_PAGE = 5;

    public async create(notificationModel: NotificationModel): Promise<NotificationModel[]> {
        return await axios.post(`${this.endpoint}`, notificationModel)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in notification retrieving: " + error);
                throw error;
            });
    }

    public async read(notificationModel: NotificationModel): Promise<NotificationModel> {
        return await axios.put(`${this.endpoint}/read/${notificationModel.notificationID}`, notificationModel)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in notification retrieving: " + error);
                throw error;
            });
    }


    public async get(page: number, maxByPage = NotificationService.MAX_NOTIFICATION_PER_PAGE): Promise<NotificationModelPaginate> {
        return await axios.get(`${this.endpoint}?range=[${page},${maxByPage}]`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in notification fetching: " + error);
                throw error;
            });
    }

    public async getUnReadNotifs(page: number, maxByPage = NotificationService.MAX_NOTIFICATION_PER_PAGE): Promise<NotificationModelPaginate> {
        return await axios.get(`${this.endpoint}?range=[${page},${maxByPage}]&read=false`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in notification fetching: " + error);
                throw error;
            });
    }

}
