import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {Pagination} from "../models/purchase.model";

export class PurchaseService {

    endpoint = '/api/v1/purchases';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue durant le paiement, Veuillez réessayer s'il vous plait`;
    public static defaultPageSize = 10;

    public async findByUserID(userID: string, page: number, maxByPage: number = PurchaseService.defaultPageSize): Promise<any> {
        return await axios.get(`${this.endpoint}?range=[${page},${maxByPage}]`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in payment processing: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }

}
