import React, {useEffect, useState} from 'react'
// @ts-ignore
import VideoRecorder from 'react-video-recorder'
import VideoPlayer from "../VideoPlayer";
import {SettingsUserEnum} from "../../../models/settings-user.model";
import {AmazonService} from "../../../services/amazon.service";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {Dialog, DialogContent, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {SettingsUserService} from "../../../services/settings-user.service";
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";

export interface WelcomeVideoProps {
    showVideo?: boolean,
    onCloseVideoPopup?: any
}

const WelcomeVideo: React.FC<WelcomeVideoProps> = (props: WelcomeVideoProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [close, setClose] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [isVideoWatched, setVideoWatched] = useState(true)
    const [showWelcomeVideo, setShowWelcomeVideo] = useState(false);
    const settingsUserService = new SettingsUserService();


    const onClose = () => {
        setClose(false);
        setVideoWatched(true);
        setShowWelcomeVideo(false);
        if (props.onCloseVideoPopup) {
            props.onCloseVideoPopup();
        }
    }

    const confirmVideoWatched = () => {
        settingsUserService.update(SettingsUserEnum.WATCH_WELCOME_VIDEO, "true");
    }

    useEffect(() => {
        (async () => {
            setVideoUrl(await new AmazonService().getWelcomeVideoUrl());
            await settingsUserService.getByUserIdAndKey(SettingsUserEnum.WATCH_WELCOME_VIDEO).then(res => {
                setVideoWatched(!isNullOrUndefinedOrEmpty(res) && !isNullOrUndefinedOrEmpty(res.value))
            });
        })()
    }, []);

    useEffect(() => {
        setShowWelcomeVideo(props.showVideo === true);
    }, [props.showVideo]);

    return (<Dialog
        fullScreen={false}
        fullWidth={false}
        maxWidth={'md'}
        open={!isVideoWatched || showWelcomeVideo}
        onClose={onClose}
        aria-labelledby="delete user account"
        aria-describedby="delete user account"
    >
        <MuiDialogTitle disableTypography className={classes.closeIcon}>
            <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon/>
            </IconButton>
        </MuiDialogTitle>
        <DialogContent className={classes.dialog}>
            <VideoPlayer url={videoUrl} onStart={() => confirmVideoWatched()}/>
        </DialogContent>
    </Dialog>);
}

WelcomeVideo.defaultProps = {
    showVideo: false
}

export default WelcomeVideo;