import {useState} from "react";
import {createContainer} from "unstated-next";
import {CoachResumeService} from "../services/coach-resume.service";
import {CoachResumeModel} from "../models/coach-resume.model";
import {CoachModuleModel} from "../models/coach-module.model";
import {CoachModuleService} from "../services/coach-module.service";

function CoachResumeContainerHook() {
    const [initialSummary, setInitialSummary] = useState<string>('');
    const [initialExperiences, setInitialExperiences] = useState<string>('');
    const [initialCompetences, setInitialCompetences] = useState<string>('');
    const [initialVideo, setInitialVideo] = useState<string>();
    const [coachModules, setCoachModules] = useState<CoachModuleModel[]>();

    const coachResumeService = new CoachResumeService();
    const coachModuleService = new CoachModuleService();

    let loadCoachResume = async (userID: string) => {
        await coachResumeService.getCoachResume(userID).then((coachResume: CoachResumeModel) => {
            setInitialSummary(coachResume.shortPresentation || '');
            setInitialExperiences(coachResume.experiences || '');
            setInitialCompetences(coachResume.services || '');
            setInitialVideo(coachResume.video || '');
        });
    }

    let loadCoachModules = async (userID: string): Promise<CoachModuleModel[]> => {
        return await coachModuleService.getCoachModules(userID).then((coachModules: CoachModuleModel[]) => {
            setCoachModules(coachModules);
            return coachModules;
        });
    }

    const submitShortPresentation = (userID: string, value: any): Promise<CoachResumeService> => {
        return coachResumeService.updateCoachResume(userID, {shortPresentation: value});
    }
    const submitExperiences = (userID: string, value: any): Promise<CoachResumeService> => {
        return coachResumeService.updateCoachResume(userID, {experiences: value});
    }
    const submitCompetences = (userID: string, value: any): Promise<CoachResumeService> => {
        return coachResumeService.updateCoachResume(userID, {services: value});
    }

    const submitVideo = (userID: string, file: File): Promise<CoachResumeModel> => {
        return coachResumeService.multiPartUpload(userID, file).then((res: CoachResumeModel) => {
            setInitialVideo(res.video);
            return res;
        });
    }

    const deleteVideo = async (userID: string): Promise<CoachResumeModel> => {
        return coachResumeService.deleteVideo(userID).then((res) => {
            setInitialVideo(res.video);
            return res;
        });
    }


    return {
        loadCoachResume, loadCoachModules, submitVideo, submitCompetences, submitExperiences, submitShortPresentation,
        coachModules, initialSummary, initialVideo, initialCompetences, initialExperiences, deleteVideo
    }
}

export const CoachResumeContainer = createContainer(CoachResumeContainerHook)

