import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {SuiviConsommationModel} from "../models/suivi-consommation.model";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";

export class SuiviConsommationService {

    consoEndpoint = '/api/v1/consommation';


    public async getSuiviConsommations(userID: string): Promise<SuiviConsommationModel[]> {
        return await axios
            .get(this.consoEndpoint + `/user/${userID}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw error;
            });
    }

    public async getSuiviConsommation(learnerID: string, phase: PhaseEnum, service: ServiceEnum): Promise<SuiviConsommationModel> {
        return await axios
            .get(this.consoEndpoint + `/user/${learnerID}/phase/${phase}/service/${service}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw error;
            });
    }

}
