import {Container, Grid, Select} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {SuiviConsommationContainer} from "../../../container/SuiviConsommationContainer";
import {sortSuiviConso} from "../../../utils/suiviConso.util";
import LearnerDocumentsModules from "../LearnerDocumentsModules";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import {useLocation} from "react-router";
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";
import {SuiviConsommationModel} from "../../../models/suivi-consommation.model";
import PageTitle from "../../Common/PageTitle";


export default function LearnerDocuments() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const user = new UserService().getCurrentUser();
    const suiviConsommationState = SuiviConsommationContainer.useContainer();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    // @ts-ignore
    const [defaultModule] = useState<ServiceEnum | null>(params.get('selectedModule'));
    // @ts-ignore
    const [selectedModule, setSelectedModule] = useState<ServiceEnum | null>(null);
    const [userSuiviConsoAndCV, setUserSuiviConsoAndCV] = useState<SuiviConsommationModel[]>([]);

    const serviceTitle = (service: string): string => {
        return t(`catalogue.services.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.${service}`)
    };

    const handleSelectedSession = (event: any) => {
        const value = event.target.value;
        setSelectedModule(value);
    }

    const getNbSession = (service: ServiceEnum | null): number => {
        return userSuiviConsoAndCV?.find(mod => mod.service === service)?.heuresTotal || 0;
    }

    const getPhase = (service: ServiceEnum | null): PhaseEnum | null => {
        return userSuiviConsoAndCV?.find(mod => mod.service === service)?.phase || null;
    }

    useEffect(
        () => {
            if (userSuiviConsoAndCV != null && userSuiviConsoAndCV?.length > 0) {
                if (isNullOrUndefinedOrEmpty(defaultModule)) {
                    setSelectedModule(ServiceEnum.CV);
                } else {
                    setSelectedModule(defaultModule);
                }
            }
        }
        , [userSuiviConsoAndCV]);

    useEffect(
        () => {
            suiviConsommationState.loadSuiviConsommationsAndParcours(user.userID);
        },
        []
    );

    useEffect(
        () => {
            let cvModuleIsIncluded = false;
            if (suiviConsommationState.suiviConso.find(cvModule => cvModule.service === ServiceEnum.CV)) {
                cvModuleIsIncluded = true;
            }
            const tempSuiviConsos = suiviConsommationState.suiviConso;
            if (suiviConsommationState.suiviConso?.[0] && !cvModuleIsIncluded) {
                // If user does not have the right to see CV module, he should have the right to upload CV anyway
                const cvModule = {
                    phase: PhaseEnum.OPTIMIZE_IMAGE_PRO,
                    service: ServiceEnum.CV,
                    heuresTotal: 1,
                    userID: suiviConsommationState.suiviConso?.[0].userID,
                    entrepriseID: suiviConsommationState.suiviConso?.[0].entrepriseID
                } as SuiviConsommationModel;
                tempSuiviConsos.push(cvModule);
            }
            setUserSuiviConsoAndCV(tempSuiviConsos);
        },
        [suiviConsommationState.suiviConso]
    );

    return (<Container maxWidth="lg" className={classes.root}>

        <PageTitle title={t("documents.title")}/>

        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}

        {selectedModule && <Grid container className={classes.row} direction="row" alignItems={'stretch'}>
            <FormControl className={classes.formElement}>
                <InputLabel id="session">{t("documents.module")}</InputLabel>
                <Select
                    labelId="session"
                    fullWidth
                    native
                    value={selectedModule}
                    onChange={(event) => handleSelectedSession(event)}
                    inputProps={{
                        name: t("documents.session"),
                    }}
                >
                    {sortSuiviConso(userSuiviConsoAndCV)?.map(module =>
                        <option aria-label="None" value={module.service} key={module.service}>
                            {serviceTitle(module.service)}
                        </option>)
                    }
                </Select>
            </FormControl>
        </Grid>}
        {!isNullOrUndefinedOrEmpty(selectedModule) &&
            <LearnerDocumentsModules
                phase={getPhase(selectedModule)}
                service={selectedModule}
                nbSessions={getNbSession(selectedModule)}/>}

    </Container>);
}
