import {useState} from "react";
import {createContainer} from "unstated-next";
import {NotificationModel, NotificationModelPaginate} from "../models/notification.model";
import {NotificationService} from "../services/notification.service";

function NotificationContainerHook() {

    let [unreadNotifications, setUnreadNotifications] = useState<{ [key: string]: NotificationModel }>({});
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const notificationService = new NotificationService();

    let read = async (notificationModel: NotificationModel) => {
        notificationService.read(notificationModel).then((notif: NotificationModel) => {
            delete unreadNotifications[notif.notificationID];
        });
    }

    let getUnreadNotifs = (): NotificationModel[] => Object.values(unreadNotifications);

    let loadUnreadNotifs = async (page: number): Promise<NotificationModelPaginate> => {
        return await notificationService.getUnReadNotifs(page).then((notifPaginate: NotificationModelPaginate) => {
            let newNotifications = page === 0 ? {} : unreadNotifications;
            for (const notif of notifPaginate.data) {
                newNotifications = {...newNotifications, ...{[notif.notificationID]: notif}};
            }
            setUnreadNotifications(newNotifications);
            setTotal(notifPaginate.total);
            return notifPaginate;
        });
    }
    return {getUnreadNotifs, read, loadUnreadNotifs, page, setPage, total}
}

export const NotificationContainer = createContainer(NotificationContainerHook)

