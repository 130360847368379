import {useState} from "react";
import {createContainer} from "unstated-next";
import {JwtTokenModel} from "../models/jwt-token.model";
import {UserService} from "../services/user.service";

function UserContainerHook() {
    let [jwtToken, setJwtToken] = useState<string>('');
    let [user, setUser] = useState<JwtTokenModel | null>(new UserService().getCurrentUser());

    let updateUser = (user: JwtTokenModel | null) => {
        localStorage.setItem('user', JSON.stringify(user));
    }
    let getConnectedUser = ():JwtTokenModel | null => {
        const res = localStorage.getItem('user');
        return res? JSON.parse(res): null;
    }
    let updateJWT = (jwt: string) => {
        localStorage.setItem('token', jwt);
    }
    return {jwtToken, setJwtToken, user, setUser, updateUser, getConnectedUser, updateJWT}
}

export const UserContainer = createContainer(UserContainerHook)

