import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        parcours: {
            verticalAlign: 'top',
            textAlign: 'left'
        },
        root: {
            red: {
                color: "red"
            }
        },
        red: {
            color: "red"
        },
        listContainer: {
            display: 'flex',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(4),
            width: '100%'
        },
        listItem: {
            marginLeft: theme.spacing(2),
            display: '1'
        }, divider: {},
        bold: {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        },
        seeCoachResume: {
            marginTop: theme.spacing(1),
        },
        avatar: {
            width: theme.spacing(12),
            height: theme.spacing(12)
        },
        avatarContainer: {
            width: '10%'
        }
    })
);