import {SuiviConsommationModel} from "../models/suivi-consommation.model";
import {Catalogue, PhaseEnum, PhaseService, ServiceEnum} from "../models/catalogue.model";
import {LearnerCoachModel} from "../models/learner-coach.model";

export const phasesOrder = [
    PhaseEnum.INTROSPECTION,
    PhaseEnum.OPTIMIZE_IMAGE_PRO,
    PhaseEnum.IDENTIFY_AND_TAKE_OPPORTUNITIES
];

export const servicesOrder = [
    ServiceEnum.UNDERSTAND_MY_PERSONALITY,
    ServiceEnum.BILAN_PRO,
    ServiceEnum.RESILIENCE,
    ServiceEnum.SELF_CONFIANCE,
    ServiceEnum.WELL_BEING,
    ServiceEnum.CV,
    ServiceEnum.DIGITAL_IMAGE,
    ServiceEnum.PITCH,
    ServiceEnum.DIVERSITY,
    ServiceEnum.TRAINING_AND_DEBRIEF,
    ServiceEnum.DEVELOP_COMMUNICATION,
    ServiceEnum.NETWORKING,
    ServiceEnum.VISION_360,
    ServiceEnum.RAYONNER,
];

export function sortSuiviConso(suiviConsos: SuiviConsommationModel[]): SuiviConsommationModel[] {
    return suiviConsos.slice(0).sort((a, b) => {
        return servicesOrder.indexOf(a.service) - servicesOrder.indexOf(b.service);
    });
}

export function sortCoachModel(coachModuleModels: LearnerCoachModel[]): LearnerCoachModel[] {
    return coachModuleModels.slice(0).sort((a, b) => {
        return servicesOrder.indexOf(a.service) - servicesOrder.indexOf(b.service);
    });
}

export function sortPhaseService(phaseServices: PhaseService[]): PhaseService[] {
    return phaseServices.slice(0).sort((a, b) => {
        return servicesOrder.indexOf(a.service) - servicesOrder.indexOf(b.service);
    });
}

export function sortPhase(phaseEnums: PhaseEnum[]): PhaseEnum[] {
    return phaseEnums.slice(0).sort((a, b) => {
        return phasesOrder.indexOf(a) - phasesOrder.indexOf(b);
    });
}

export function sortCatalogue(catalogues: Catalogue[]): Catalogue[] {
    return catalogues.slice(0).sort((a, b) => {
        return servicesOrder.indexOf(a.service) - servicesOrder.indexOf(b.service);
    });
}

