export const isFormTouched = (formik: any, key: string): boolean => {
    return formik.values?.[key] !== null && formik.values?.[key] !== undefined && formik.values?.[key] !== '';
}

export const setFormValuesInLocalStorage = (formName: string, values: any): void => {
    localStorage.setItem(formName, JSON.stringify(values));
}

export const getFormValuesFromLocalStorage = (formName: string): any | null => {
    const value = localStorage.getItem(formName);
    return value ? JSON.parse(value) : null;
}

