import {Container, Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {useLocation} from "react-router";
import {getFullname} from "../../../../utils/user.utils";
import {User} from "../../../../models/user.model";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {UserService} from "../../../../services/user.service";
import CoachLearnerDetailsModuleSession from "../CoachLearnerDetailsModuleSession";
import {SuiviConsommationService} from "../../../../services/suivi-consommation.service";
import {SuiviConsommationModel} from "../../../../models/suivi-consommation.model";
import {AxiosError} from "axios";
import {CoachModuleService} from "../../../../services/coach-module.service";
import {CoachModuleModel} from "../../../../models/coach-module.model";
import PageTitle from "../../../Common/PageTitle";

export default function CoachLearnerDetailsModule() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);

    const [errorMessage, setErrorMessage] = useState("");
    const [service] = useState<string | null>(params.get('service') || '');
    const [phase] = useState<string | null>(params.get('phase') || '');
    const [learnerID] = useState<string | null>(params.get('user'));

    const [user, setUser] = useState<User | null>(null);
    const learnerName = getFullname(user?.firstname || null, user?.lastname || null)
        || user?.email;
    const [sessionList, setSessionList] = useState<number[]>([]);
    const [coachHasRigth, setCoachHasRigth] = useState(false);

    const moduleTitle = (service: ServiceEnum) => {
        return t(`catalogue.services.learner.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.learner.${service}`)
    };

    useEffect(() => {
        const coachID = new UserService().getCurrentUser().userID;
        new CoachModuleService().getCoachModules(coachID).then((coachModules: CoachModuleModel[]) => {
            if (coachModules.find((module: CoachModuleModel) => module.service === service)) {
                setCoachHasRigth(true);
            }
        });
    }, [service]);

    React.useEffect(() => {
            (async () => {
                if (learnerID) {
                    new UserService().getUser(learnerID).then((user: User) => {
                        setUser(user)
                    }).catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'));
                    });
                    new SuiviConsommationService().getSuiviConsommation(learnerID, phase as PhaseEnum, service as ServiceEnum)
                        .then((suiviConso: SuiviConsommationModel) => {
                            let tempSessionList: number[] = [];
                            for (let i = 1; i <= suiviConso.heuresTotal; i++) {
                                tempSessionList = [...tempSessionList, i];
                            }
                            if (tempSessionList.length === 0 && service === ServiceEnum.CV && phase === PhaseEnum.OPTIMIZE_IMAGE_PRO) {
                                // The coach should see the CV even if user doesn't have access to CV module
                                tempSessionList.push(1);
                            }
                            setSessionList(tempSessionList);
                        })
                        .catch((error: AxiosError) => {
                            if (error?.response?.status === 404) {
                                setErrorMessage(t("coach.confirmSession.phaseServiceNotFound"));
                            } else {
                                setErrorMessage(t('unexpectedErrorLoading'));
                            }
                        });
                }

            })();
        },
        [learnerID, phase, service]);


    function displaySession(sessionNumber: number) {
        return coachHasRigth || (service === ServiceEnum.CV && sessionNumber === 1);
    }

    return (
        <Container maxWidth="lg" className={classes.root}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <PageTitle
                title={
                    t("coach.learnerDetails.module.title", {
                        module: moduleTitle(service as ServiceEnum)
                    })
                }
            />
            <Grid container className={classes.row1} direction="row"
                  justify={'flex-start'} alignItems={'stretch'}>
                <Typography variant="subtitle1" dangerouslySetInnerHTML={{
                    __html: t('coach.learnerDetails.module.title2', {
                        name: learnerName
                    })
                }}/>
            </Grid>
            <Grid container className={classes.row} direction="column"
                  justify={'flex-start'} alignItems={'stretch'}>
                {
                    sessionList.map(sessionNumber =>
                        displaySession(sessionNumber) &&
                        <CoachLearnerDetailsModuleSession learnerID={learnerID} sessionNumber={sessionNumber}
                                                          key={'CoachLearnerDetailsModuleSession_' + sessionNumber}
                                                          phase={phase as PhaseEnum} service={service as ServiceEnum}/>
                    )
                }
            </Grid>

        </Container>);
}
