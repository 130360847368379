import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";
import {ModuleDetails} from "../models/module-details";

export class ModuleDetailsService {

    endpoint = '/api/v1/moduleDetails';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;

    public async getOneByPhaseAndService(phase: PhaseEnum, service: ServiceEnum): Promise<ModuleDetails> {
        return await axios.get(`${this.endpoint}/phase/${phase}/service/${service}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachmodule retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });

    }

}
