import {Alert} from '@material-ui/lab';
import {Formik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {UserService} from '../../services/user.service';
import './styles.sass';
import {log} from "../../utils/log";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";


const ResetPassword: React.FC = (props: any) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const ResetPasswordSchema = Yup.object({
        password: Yup.string().required(`Le mot de passe est obligatoire.`).matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            t("signUp.errors.password.passwordHintText")
        ),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas.')
    });


    const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
    const [resetPasswordError, setResetPasswordError] = useState("");
    const [completed, setCompleted] = useState(false);


    return (
        <div className="wrapper">
            <div className="form-container">
                <h1>BringYourBest</h1>
                <h2>Changement de mot de passe</h2>
                {resetPasswordSuccess && <Alert severity="success">{resetPasswordSuccess}</Alert>}
                {resetPasswordError && <Alert severity="error">{resetPasswordError}</Alert>}

                <Formik
                    initialValues={{password: '', passwordConfirmation: ''}}
                    validationSchema={ResetPasswordSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 400);
                        let search = window.location.search;
                        let params = new URLSearchParams(search);
                        const email = params.get('email');
                        const code = params.get('code');
                        if (!email || !code) {
                            setResetPasswordError(t('passwordReset.error'));
                        } else {
                            setResetPasswordError('');
                            new UserService().resetPassword(email, values.password, code).then(ok => {
                                setSubmitting(false);
                                setResetPasswordSuccess(t('passwordReset.success'));
                                setCompleted(true);
                            }).catch(ko => {
                                setSubmitting(false);
                                setResetPasswordError(ko);
                                log.info("Error in signin: " + resetPasswordError);
                            })
                        }

                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="input-wrapper">
                                <label htmlFor="password">Entrer votre nouveau Mot de passe</label>
                                <input
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                <span>
                  {errors.password && touched.password && errors.password}
                </span>
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="passwordConfirmation">Confirmer votre nouveau Mot de passe</label>
                                <input
                                    type="password"
                                    name="passwordConfirmation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.passwordConfirmation}
                                />
                                <span>
                  {errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation}
                </span>
                            </div>

                            {!completed && <button
                                type="submit"
                                className={`button ${
                                    errors.password || errors.passwordConfirmation || isSubmitting
                                        ? 'disabled'
                                        : ''
                                }`}
                                disabled={isSubmitting}
                            >
                                {t('passwordReset.continueButton')}
                            </button>}
                            {completed && <button onClick={() => navigate('/signin')} className={`button`}>
                                {t('passwordReset.connectButton')}
                            </button>}
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ResetPassword;
