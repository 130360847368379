import Alert from '@material-ui/lab/Alert';
import {Formik} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {UserService} from '../../services/user.service';
import './styles.sass';
import {log} from "../../utils/log";
import {useTranslation} from "react-i18next";


const ForgotPassword: React.FC = () => {
    const {t} = useTranslation();

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string().email(t("signUp.errors.email.invalid")).required(`L'email est obligatoire`)
    });

    const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState("");
    const [forgotPasswordError, setForgotPasswordError] = useState("");


    return (
        <div className="wrapper">
            <div className="form-container">
                <h1>BringYourBest</h1>
                <h2>Mot de passe oublié</h2>
                {forgotPasswordSuccess && <Alert severity="success">{forgotPasswordSuccess}</Alert>}
                {forgotPasswordError && <Alert severity="error">{forgotPasswordError}</Alert>}
                <p>Vous avez perdu votre mot de passe? Veuillez entrer votre adresse email. Nous vous enverrons un lien
                    pour créer un nouveau mot de passe.</p>

                <Formik
                    initialValues={{email: ''}}
                    validationSchema={ForgotPasswordSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        setForgotPasswordSuccess("");
                        setForgotPasswordError("");
                        new UserService().forgotPassword(values as any).then(ok => {
                            setSubmitting(false);
                            setForgotPasswordSuccess(ok);
                            log.info("Forgot password ok: " + ok);
                        }).catch(ko => {
                            setSubmitting(false);
                            setForgotPasswordError(ko);
                            log.error("Forgot password error: " + ko);
                        })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="input-wrapper">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    className={errors.email ? 'error' : ''}
                                />
                                <span>{errors.email && touched.email && errors.email}</span>
                            </div>

                            <button
                                type="submit"
                                className={`button ${
                                    errors.email || isSubmitting
                                        ? 'disabled'
                                        : ''
                                }`}
                                disabled={isSubmitting}
                            >
                                Continuer
                            </button>
                            <PersistFormikValues name="forgot-password-form" storage={window.sessionStorage}/>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ForgotPassword;
