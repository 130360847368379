import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {ThemeProvider, useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {Button, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {preventDefault} from "../../utils/index.util";
import BybButton from "../../components/Common/BybButton";
import {dashboardTheme} from "../DashboardEnterprise";
import {ContactUsModel} from "../../models/contactUs.model";
import {UserService} from "../../services/user.service";
import {useNavigate} from "react-router";
import {userHasRole} from "../../utils/login.util";
import {UserRole} from "../../models/user.model";


export default function ContactUs() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const user = new UserService().getCurrentUser();

    const navigate = useNavigate();

    const goToDashboard = () => {
        if (userHasRole(UserRole.ENTREPRISE_CADRE)) {
            navigate('/learner');
        } else if (userHasRole(UserRole.ENTREPRISE)) {
            navigate('/enterprise');
        }
    }

    const validationSchema = yup.object({
        email: yup
            .string()
            .email(t("contactUs.errors.email.invalid"))
            .required(t("contactUs.errors.email.required")),
        name: yup
            .string()
            .min(2, t("contactUs.errors.name.min"))
            .max(150, t("contactUs.errors.name.max"))
            .required(t("contactUs.errors.name.required")),
        subject: yup
            .string()
            .min(2, t("contactUs.errors.subject.min"))
            .max(50, t("contactUs.errors.subject.max"))
            .required(t("contactUs.errors.subject.required")),
        message: yup
            .string()
            .min(10, t("contactUs.errors.message.min"))
            .max(1500, t("contactUs.errors.message.max"))
            .required(t("contactUs.errors.message.required")),
        /* telephone: yup
             .string()
             .matches(phoneRegExp, t("contactUs.errors.telephone.min"))*/
    });

    const
        formik = useFormik({
            initialValues: {
                email: user.email,
                name: '',
                subject: '',
                message: '',
                /*
                                telephone: '',
                */
            } as ContactUsModel,
            validationSchema: validationSchema,
            onSubmit: async (values, formikHelpers) => {
                formikHelpers.setSubmitting(true);
                setSuccessMessage("")
                setErrorMessage("");
                await new UserService().contactUs(values as ContactUsModel).then(() => {
                    setSuccessMessage(t("contactUs.errors.successMessage", {email: values.email}));
                    formikHelpers.resetForm();
                }).catch(() => {
                    setErrorMessage(t("contactUs.errors.errorMessage"));
                });
                formikHelpers.setSubmitting(false);
            },
        });


    return (
        <ThemeProvider theme={dashboardTheme}>

            <div className={classes.root}>
                <div className={classes.content}>
                    <Typography variant="h5" noWrap className={classes.title}>
                        BringYourBest
                    </Typography>
                    <Typography variant="h6" noWrap className={classes.subtitle}>
                        {t('contactUs.title')}
                    </Typography>
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    {successMessage && <Alert severity="success">{successMessage}</Alert>}

                    <form onSubmit={preventDefault && formik.handleSubmit}>
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            disabled
                            id="email"
                            name="email"
                            label={t('contactUs.email')}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="name"
                            name="name"
                            label={t('contactUs.name')}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            variant="outlined"
                        />
                        {/*<TextField
                            className={classes.formElement}
                            fullWidth
                            id="telephone"
                            name="telephone"
                            label={t('contactUs.telephone')}
                            value={formik.values.telephone}
                            onChange={formik.handleChange}
                            error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                            helperText={formik.touched.telephone && formik.errors.telephone}
                        />*/}
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="subject"
                            name="subject"
                            label={t('contactUs.subject')}
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                            helperText={formik.touched.subject && formik.errors.subject}
                            variant="outlined"
                        />
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            id="message"
                            name="message"
                            label={t('contactUs.message')}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}
                            multiline={true}
                            rows={10}
                            variant="outlined"
                        />
                        <BybButton classes={classes.formSubmit} disabled={formik.isValid && formik.isSubmitting}
                                   loading={formik.isSubmitting}
                                   label={t('contactUs.submit')}
                                   color="primary" variant="contained"
                                   fullWidth
                                   type="submit">
                        </BybButton>
                        <Button className={classes.formCancel}
                                onClick={goToDashboard}
                                color="default"
                                variant="outlined"
                                fullWidth
                                type="button">
                            {t('menu.contactUsBack')}
                        </Button>

                    </form>
                </div>
            </div>
        </ThemeProvider>
    );
}

