import {Container, Grid, Paper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import MyProfile from "../MyProfile";
import {UserService} from "../../../services/user.service";
import {DeleteAccount} from "../DeleteAccount";
import CoachDeleteStripe from "../../Coach/CoachDeleteStripe";
import {JwtTokenModel} from "../../../models/jwt-token.model";
import PageTitle from "../PageTitle";

export default function UserProfile() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const userService = new UserService();

    const [errorMessage] = useState("");
    const [user, setUser] = useState<JwtTokenModel>(userService.getCurrentUser());


    useEffect(() => {
        function checkUserData() {
            const item = userService.getCurrentUser();
            setUser(item);
        }

        window.addEventListener('storage', checkUserData)
        return () => {
            window.removeEventListener('storage', checkUserData)
        }
    });


    return (
        <Container maxWidth="lg" className={classes.root}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}

            <PageTitle title={t("myProfile.title")}/>

            <Grid container className={classes.row} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                <Paper square={false} className={classes.paper} elevation={1}>
                    <MyProfile user={user}/>
                </Paper>
            </Grid>
            <Grid container className={classes.deleteAccountRow} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                <CoachDeleteStripe user={user}/>
            </Grid>
            <Grid container className={classes.deleteAccountRow} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                <DeleteAccount user={user}/>
            </Grid>
        </Container>
    );
}