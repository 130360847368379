import {createStyles, makeStyles} from "@material-ui/core/styles";

const drawerWidth = 250;

export const useStyles = makeStyles((theme) =>
    createStyles({
        list: {
            width: drawerWidth,
        },
        close: {
            cursor: 'pointer',
            display: 'flex',
            padding: theme.spacing(2),
        },
        closeText: {
            flexGrow: 1,
        },
        closeIcon: {
            transition: 'all .2s ease-in-out',
            /*'&:hover': {
                transform: 'rotateX(45deg)'
            }*/
        }
    }),
);