import React, {useEffect, useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
import _ from "lodash";

// @ts-ignore
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import {Form, Formik} from "formik";
import Alert from "@material-ui/lab/Alert";
import {BilanProModel} from "../../../../../models/bilanpro.model";
import BilanProStep1 from "../BilanProStep1";
import BilanProStep2 from "../BilanProStep2";
import BilanProStep3 from "../BilanProStep3";
import BilanProStep5 from "../BilanProStep5";
import {SuiviConsommationContainer} from "../../../../../container/SuiviConsommationContainer";
import {SuiviConsommationModel} from "../../../../../models/suivi-consommation.model";
import {PhaseEnum, ServiceEnum} from "../../../../../models/catalogue.model";
import BybButton from "../../../../Common/BybButton";
import {getFormValuesFromLocalStorage} from "../../../../../utils/form.util";
import {UserService} from "../../../../../services/user.service";
import validationSchema from "../FormModel/validationSchema";
import {nanoid} from "nanoid";
import BilanProStep4 from "../BilanProStep4";
import {BilanProContainer} from "../../../../../container/BilanProContainer";
import {usePrompt} from "react-router-dom";
import GoBackButton from "../../../../Common/GoBackButton";
import {isAllBilanProFieldIsFilled} from "../../../../../utils/bilanPro.util";

function _renderStepContent(step: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
                            handleChange: any, values: any, validateForm: any, setFieldTouched: any) {
    switch (step) {
        case 0:
            return <BilanProStep1 setFieldValue={setFieldValue} handleChange={handleChange} values={values}
                                  validateForm={validateForm} setFieldTouched={setFieldTouched}/>;
        case 1:
            return <BilanProStep2 setFieldValue={setFieldValue} handleChange={handleChange} values={values}
                                  validateForm={validateForm} setFieldTouched={setFieldTouched}/>;
        case 2:
            return <BilanProStep3 setFieldValue={setFieldValue} handleChange={handleChange} values={values}
                                  validateForm={validateForm} setFieldTouched={setFieldTouched}/>;
        case 3:
            return <BilanProStep4 setFieldValue={setFieldValue} handleChange={handleChange} values={values}
                                  validateForm={validateForm} setFieldTouched={setFieldTouched}/>;
        case 4:
            return <BilanProStep5 setFieldValue={setFieldValue} handleChange={handleChange} values={values}
                                  validateForm={validateForm} setFieldTouched={setFieldTouched}/>;
        default:
            return <></>;
    }
}

export const BilanPro = () => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const steps = getSteps();

    const [activeStep, setActiveStep] = React.useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [notAuthorized, setNotAuthorized] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<BilanProModel | null>(null);
    const isLastStep = activeStep === steps.length - 1;

    const bilanProState = BilanProContainer.useContainer();
    const suiviConsoState = SuiviConsommationContainer.useContainer();
    const userService = new UserService();


    useEffect(() => {
        const auth = suiviConsoState.suiviConso?.find((suiviconso: SuiviConsommationModel) => suiviconso.phase === PhaseEnum.INTROSPECTION
            && suiviconso.service === ServiceEnum.BILAN_PRO);
        setNotAuthorized(auth === undefined);
    }, [suiviConsoState.suiviConso]);

    useEffect(() => {
        if (notAuthorized) {
            setErrorMessage(t('notAuthorized'));
        } else {
            setErrorMessage('');
        }
    }, [notAuthorized]);

    useEffect(() => {
        (async () => {
            setInitialValues(await getValueFromLocalstorageOrBackend())
        })();
    }, [bilanProState.bilanPro?.parcoursProDescription]);


    // Surveiller la fermetture de l'onglet/navigateur
    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, []);
    const alertUser = (e: any) => {
        e.preventDefault()
        e.returnValue = ''
    }

    async function getValueFromLocalstorageOrBackend(): Promise<BilanProModel | null> {
        const storedValues = getFormValuesFromLocalStorage('bilanPro') as BilanProModel | null;
        const user = userService.getCurrentUser();
        await bilanProState.loadBilanPro(user.userID);
        const backendValues = bilanProState.bilanPro;
        return _.isEmpty(backendValues) ? storedValues : backendValues;
    }


    function getSteps() {
        const prefix = "catalogue.BILAN_PRO"
        return [t(`${prefix}.step1Title`), t(`${prefix}.step2Title`), t(`${prefix}.step3Title`),
            t(`${prefix}.step4Title`), t(`${prefix}.step5Title`)];
    }

    async function _submitForm(values: any, formikHelpers: any) {
        formikHelpers.setSubmitting(true);
        setSuccessMessage("")
        setErrorMessage("");
        setLoading(true);
        await bilanProState.updateBilanPro(userService.getCurrentUser()?.userID, values).then(() => {
            setSuccessMessage(t("catalogue.BILAN_PRO.success"));
            formikHelpers.setSubmitting(false);
            setLoading(false);
        }).catch(() => {
            formikHelpers.setSubmitting(false);
            setErrorMessage(t("unexpectedError"));
            // formikHelpers.resetForm();
            setLoading(false);
        });
    }

    function _handleSubmit(values: any, formikHelpers: any) {
        /* if (isLastStep) {

         } else {
             setActiveStep(activeStep + 1);
             formikHelpers.setTouched({});
             formikHelpers.setSubmitting(false);
         }*/
        formikHelpers.setTouched({});
        _submitForm(values, formikHelpers);
    }

    async function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const isFormDisabled = (isSubmitting: boolean, errors: any): boolean => {
        return isSubmitting || (Object.keys(errors)?.length !== 0)
    }

    const showFinishButton = (values: BilanProModel): boolean => {
        return isLastStep || isAllBilanProFieldIsFilled(values);
    }

    const isChangesSavedInBackend = () => {
        const storedValues = getFormValuesFromLocalStorage('bilanPro') as BilanProModel | null;
        const backendValues = bilanProState.bilanPro;
        return backendValueChanged(storedValues, backendValues);
    }

    const backendValueChanged = (storedValues: any, backendValues: any): boolean => {
        if (!storedValues) {
            return false;
        }
        for (const key of Object.keys(storedValues)) {
            if (storedValues[key] !== backendValues?.[key]) {
                return false;
            }
        }
        return true;
    }

    usePrompt(
        t('leaveWithoutSave'),
        !isChangesSavedInBackend()
    );

    const currentValidationSchema = validationSchema[activeStep];


    return (
        <Container maxWidth="lg" className={classes.root}>
            <Grid container className={classes.row} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                <Grid container className={classes.goBack} direction="row"
                      justify={'flex-end'} alignItems={'stretch'}>
                    <GoBackButton/>
                </Grid>
                <Paper square className={classes.paper} elevation={1}>
                    <div className={classes.title}>
                        <Typography variant="h6">{t("catalogue.BILAN_PRO.title")}</Typography>
                    </div>
                    {!notAuthorized && <div className={classes.content}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label: string, index: number) => {
                                return (
                                    <Step key={label + index} className={classes.step}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                        <Formik
                            initialValues={{
                                talents: initialValues?.talents || '',
                                strengths: initialValues?.strengths || '',
                                weaknesses: initialValues?.weaknesses || '',
                                plaisirs: initialValues?.plaisirs || '',
                                irritants: initialValues?.irritants || '',
                                valeurs: initialValues?.valeurs || '',
                                unique: initialValues?.unique || '',
                                doubts: initialValues?.doubts || '',
                                evolution: initialValues?.evolution || '',
                                parcoursProDescription: initialValues?.parcoursProDescription || '',
                            } as BilanProModel}
                            enableReinitialize={true}
                            validationSchema={currentValidationSchema}
                            onSubmit={_handleSubmit}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validateOnMount={true}
                        >
                            {({
                                  values,
                                  errors,
                                  handleChange,
                                  isSubmitting,
                                  setFieldValue,
                                  validateForm,
                                  setFieldTouched
                                  /* and other goodies */
                              }) => (
                                <Form id={nanoid()}>

                                    {_renderStepContent(activeStep, setFieldValue, handleChange, values, validateForm, setFieldTouched)}

                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                        >
                                            {t('catalogue.BILAN_PRO.previous')}
                                        </Button>
                                        {showFinishButton(values) &&
                                            <BybButton variant="contained" color="primary" type="submit"
                                                       disabled={isFormDisabled(isSubmitting, errors)}
                                                       loading={loading}
                                                       label={t('catalogue.BILAN_PRO.finish')}>
                                            </BybButton>}
                                        {!isLastStep &&
                                            <Button variant="contained" color="primary"
                                                    onClick={() => handleNext()}
                                                    disabled={isFormDisabled(isSubmitting, errors)}>
                                                {t('catalogue.BILAN_PRO.next')}
                                            </Button>}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
                        {successMessage &&
                            <Alert severity="success" className={classes.row}>{successMessage}</Alert>}

                    </div>}
                </Paper>
            </Grid>
        </Container>


    );
}

export default BilanPro;