import React, {useEffect} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom";
import {CoachStripeAccountContainer} from "../../../container/CoachStripeAccountContainer";
import {UserService} from "../../../services/user.service";
import {userHasRole} from "../../../utils/login.util";
import {UserRole} from "../../../models/user.model";
import {log} from "../../../utils/log";


export default function StripeAccountNotLoaded() {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const userService = new UserService();

    const coachStripeAccountState = CoachStripeAccountContainer.useContainer();

    useEffect(() => {
        coachStripeAccountState.loadStripeAccount(userService.getCurrentUser().userID).catch((error) => log.error(error))
    }, []);


    const showStripeAccountWarning = (): boolean => {
        const stripeAccount = coachStripeAccountState.getStripeAccount()?.stripeUserId;
        return !stripeAccount && userHasRole(UserRole.COACH);
    }

    return (
        <>
            {showStripeAccountWarning() && <Alert severity="error" className={classes.cvWarning}>
                {t('coach.dashboard.stripeMissingPre')} <Link
                to='/coach/payment'>{t('coach.dashboard.here')}</Link> {t('coach.dashboard.stripeMissingPost')}
            </Alert>
            }
        </>
    );
}

