import React, {useState} from "react";
import {createContainer} from "unstated-next";
import {CoachModuleModel} from "../models/coach-module.model";
import {User} from "../models/user.model";

function CoachLearnerDetailsContainerHook() {
    const [selectedModule, setSelectedModule] = React.useState<CoachModuleModel |null>(null);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);


    return {
        selectedUser, selectedModule, setSelectedUser, setSelectedModule
    }
}

export const CoachLearnerDetailsContainer = createContainer(CoachLearnerDetailsContainerHook)

