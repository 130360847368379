import {
    Button,
    Container,
    Grid,
    LinearProgress,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {PurchaseService} from "../../../services/purchase.service";
import {GridRowsProp} from "@material-ui/data-grid";
import {EnterpriseService} from "../../../services/enterprise.service";
import {MeetingConfirmationService} from "../../../services/meeting-confirmation.service";
import {MeetingModel} from "../../../models/meeting.model";
import {formatDateToStringDate} from "../../../utils/date.util";
import {getFullname} from "../../../utils/user.utils";
import {AlertDialog} from "../../Common/Dialog";
import {NotificationContainer} from "../../../container/NotificationContainer";
import PageTitle from "../../Common/PageTitle";

export default function LearnerConfirmSession() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [loadingPopup, setLoadingPopup] = React.useState(false);
    const [openConfirmSession, setOpenConfirmSession] = React.useState(false);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [selectedSession, setSelectedSession] = React.useState<MeetingModel | null>(null);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const notificationState = NotificationContainer.useContainer();

    const userService = new UserService();
    const meetingConfirmationService = new MeetingConfirmationService();


    const confirmSession = (meeting: MeetingModel) => {
        setOpenConfirmSession(true);
        setSelectedSession(meeting);
    };
    const handleCloseConfirmSession = () => {
        setOpenConfirmSession(false);
    };

    const disableConfirm = (row: MeetingModel): boolean => {
        return !!row.confirm;
    };

    const handleConfirmSession = () => {
        setLoadingPopup(true);
        setErrorMessage("");
        setSnackBarOpen(false);
        if (selectedSession) {
            meetingConfirmationService.confirm(selectedSession).then((meetingUpdated: MeetingModel | void) => {
                if (meetingUpdated) {
                    const rowClone = rows;
                    const index = rows.findIndex((row) => (row as MeetingModel).meetingConfirmationID === meetingUpdated.meetingConfirmationID);
                    if (index !== -1) {
                        rowClone[index] = meetingUpdated;
                        setRows(rowClone);
                    }
                    notificationState.loadUnreadNotifs(0);
                    setSnackBarOpen(true);
                }
            }).catch(() => {
                setErrorMessage(t('unexpectedErrorLoading'))
            }).finally(() => {
                setSelectedSession(null);
                setOpenConfirmSession(false);
                setLoadingPopup(false);
            });
        } else {
            setLoadingPopup(false);
            setOpenConfirmSession(false);
        }
    };


    React.useEffect(() => {
            let active = true;
            (async () => {
                setLoading(true);
                const {
                    total,
                    data
                } = await meetingConfirmationService.get(page)
                    .then((res: any) => {
                        setLoading(false);
                        return res;
                    })
                    .catch(() => {
                        setLoading(false);
                        setErrorMessage(t('unexpectedErrorLoading'))
                        return {total: 0, data: []};
                    });
                setRows(data);
                setRowCount(total);
                if (!active) {
                    return;
                }
                setLoading(false);
            })();
            return () => {
                active = false;
            };
        },
        // eslint-disable-next-line
        [page]);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const labelDisplayedRows = ({from, to, count}: { from: number, to: number, count: number }) => {
        const currentPage = page + 1;
        const pageCount = Math.ceil(count / EnterpriseService.defaultPageSize);
        const total = pageCount < 1 ? 1 : pageCount;
        return t('pagination', {current: currentPage, total: total});
    };

    const serviceTitle = (service: string): string => {
        return t(`catalogue.services.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.${service}`)
    };
    const phaseTitle = (phase: string): string => {
        return t(`catalogue.phases.${phase}`).split(':')?.[0].trim()
            || t(`catalogue.phases.${phase}`)
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    return (<Container maxWidth="lg" className={classes.root}>
        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
        <Snackbar open={snackBarOpen} autoHideDuration={20000} onClose={handleClose}>
            <Alert severity="success" onClose={handleClose}>
                {t('learner.confirmSession.successMessage')}
            </Alert>
        </Snackbar>
        <AlertDialog open={openConfirmSession} handleClose={handleCloseConfirmSession}
                     handleConfirmDelete={handleConfirmSession}
                     loading={loadingPopup}
                     title={t('learner.confirmSession.dialog.title')}
                     confirmText={t('learner.confirmSession.dialog.title')}
                     contentText={t('learner.confirmSession.dialog.description')}/>

        <PageTitle title={t("menu.notifsTitle")}/>
        <Grid container className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('learner.confirmSession.phase')}</TableCell>
                            <TableCell>{t('learner.confirmSession.service')}</TableCell>
                            <TableCell>{t('learner.confirmSession.session')}</TableCell>
                            <TableCell>{t('learner.confirmSession.start')}</TableCell>
                            <TableCell>{t('learner.confirmSession.end')}</TableCell>
                            <TableCell>{t('learner.confirmSession.coach')}</TableCell>
                            <TableCell>{t('learner.confirmSession.confirm')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && <TableRow key={'progress'} className={classes.rowProgress}>
                            <TableCell colSpan={7}><LinearProgress/></TableCell>
                        </TableRow>}
                        {(rows).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    {phaseTitle(row.phase)}
                                </TableCell>
                                <TableCell>
                                    {serviceTitle(row.service)}
                                </TableCell>
                                <TableCell>
                                    {row.session}
                                </TableCell>
                                <TableCell>
                                    {formatDateToStringDate((row as MeetingModel).meetingEndDate)}
                                </TableCell>
                                <TableCell>
                                    {formatDateToStringDate((row as MeetingModel).meetingEndDate)}
                                </TableCell>
                                <TableCell>
                                    {getFullname(
                                        (row as MeetingModel).coach?.firstname,
                                        (row as MeetingModel).coach?.lastname
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => confirmSession(row as MeetingModel)}
                                            color="primary"
                                            variant="outlined"
                                            disabled={disableConfirm(row as MeetingModel)}
                                            className={classes.button}>
                                        {t('learner.confirmSession.confirm')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                count={+rowCount}
                                page={page}
                                onChangePage={handlePageChange}
                                rowsPerPage={+PurchaseService.defaultPageSize}
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={labelDisplayedRows}/>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>

    </Container>);
}
