import React, {useEffect} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";
import BybButton from "../../Common/BybButton";
import {StripeAccountService} from "../../../services/stripe-account.service";
import {CoachStripeAccountContainer} from "../../../container/CoachStripeAccountContainer";
import {UserService} from "../../../services/user.service";
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";
import {AlertDialog} from "../../Common/Dialog";
import {JwtTokenModel} from "../../../models/jwt-token.model";
import {log} from "../../../utils/log";

export interface CoachDeleteStripeInterface {
    user: JwtTokenModel;
}

export default function CoachDeleteStripe(props: CoachDeleteStripeInterface) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const location = useLocation();
    const search = location.search;
    const stripeAccountService = new StripeAccountService();
    const userService = new UserService();
    const coachStripeAccountState = CoachStripeAccountContainer.useContainer();
    const userID = userService.getCurrentUser().userID;


    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorStripe, setErrorStripe] = React.useState<string>("");
    const [success, setSuccess] = React.useState<string>("");
    const [openDeleteStripe, setOpenDeleteStripe] = React.useState(false);


    const stripeAccountConnected = (): boolean => !isNullOrUndefinedOrEmpty(coachStripeAccountState.getStripeAccount()?.stripeUserId);

    const openDeleteVideoModal = () => {
        setOpenDeleteStripe(true);
    }
    const handleCloseDeleteStripe = () => {
        setOpenDeleteStripe(false);
    }

    const handleDeleteStripe = async () => {
        setOpenDeleteStripe(false);
        setErrorStripe(t(""));
        setSuccess("");
        await stripeAccountService.remove().then(() => {
            setErrorStripe("");
            coachStripeAccountState.loadStripeAccount(userID);
            setSuccess(t("coach.paiements.deleteSuccess"));
        }).catch(() => {
            setErrorStripe(t("myProfile.deleteError"));
        });
    }

    useEffect(() => {
        coachStripeAccountState.loadStripeAccount(userService.getCurrentUser().userID).catch((error) => log.error(error));
    }, []);

    // @ts-ignore
    return (<>
        <AlertDialog open={openDeleteStripe} handleClose={handleCloseDeleteStripe}
                     handleConfirmDelete={handleDeleteStripe}
                     title={t('coach.paiements.deleteStripe.title')}
                     contentText={t('coach.paiements.deleteStripe.description')}/>
        {UserService.isCoachUser(props.user) && <div className={classes.buttonWrapper}>
            {stripeAccountConnected() && <BybButton onClick={openDeleteVideoModal}
                                                    color={"secondary"}
                                                    fullWidth={false}
                                                    variant={"contained"}
                                                    disabled={loading}
                                                    loading={loading}
                                                    label={t('coach.paiements.stripeDeleteButton')}/>}
        </div>}
    </>);
}
