import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";
import {CoachSummaryModel} from "../models/coach-summary.model";
import {CoachModuleModel} from "../models/coach-module.model";
import {LearnerCoachModel} from "../models/learner-coach.model";

export class CoachModuleService {

    endpoint = '/api/v1/coachModules';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;


    public async getCoachModules(userID: string): Promise<CoachModuleModel[]> {
        return await axios.get(`${this.endpoint}/user/${userID}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachmodule retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async getCoachSummaryByPhaseAndService(phase: PhaseEnum, service: ServiceEnum): Promise<CoachSummaryModel> {
        return await axios.get(`${this.endpoint}/coachCaption?phase=${phase}&service=${service}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachmodule retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });

    }
    public async getOneByPhaseAndService(phase: PhaseEnum, service: ServiceEnum): Promise<CoachModuleModel> {
        return await axios.get(`${this.endpoint}/phase/${phase}/service/${service}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachmodule retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });

    }
     public async getAllCoachForUser(): Promise<LearnerCoachModel[]> {
        return await axios.get(`${this.endpoint}/userCoachs`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachmodule retrieving: " + error);
                throw error;
            });

    }

}
