import {UserService} from "../services/user.service";
import jwtDecode from "jwt-decode";
import {LOGIN_GOOGLE_FAILURE} from "../constants";
import {log} from "./log";
import {JwtTokenModel} from "../models/jwt-token.model";
import {UserRole} from "../models/user.model";

export function googleSuccessHandler(idToken: string, navigate: any,
                                     setLoginErrorMessage: (error: string) => void,
                                     userState: { jwtToken: string, setJwtToken: React.Dispatch<any>, setUser: React.Dispatch<any> }
) {
    if (idToken) {
        new UserService().loginGoogle(idToken).then(async (accessToken) => {
            log.debug("Login google ok")
            const jwtToken = getTokenAfterLogin(accessToken);
            userState.setJwtToken(jwtToken);
            userState.setUser(new UserService().getCurrentUser())
            navigate(getDashboardByRole(jwtToken));
        }).catch(ko => {
            log.info("ko: " + ko);
            setLoginErrorMessage(ko);
            userState.setJwtToken(null);
            userState.setUser(null);
        });
    } else {
        setLoginErrorMessage(LOGIN_GOOGLE_FAILURE);
        log.error('Google Login Failed');
    }
}

export function getTokenAfterLogin(accessToken: any): JwtTokenModel | null {
    if (accessToken) {
        log.debug("In getTokenAfterLogin to set a token!");
        // Get the first role
        const jwtToken = jwtDecode(accessToken) as JwtTokenModel;
        // Set the session
        localStorage.setItem("token", accessToken);
        localStorage.setItem("user", JSON.stringify(jwtToken));
        log.debug("token set!");
        // Redirect to the page according the role
        return jwtToken;
    }
    return null;
}

export function cleanLocalStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
}

export function getIsLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    if (token == null || localStorage.getItem('user') == null) {
        return false;
    }
    const jwtToken = jwtDecode(token) as JwtTokenModel;
    const expirationDate = new Date(+jwtToken.exp * 1000).getTime();
    const today = new Date().getTime();
    // log.debug("token found!");
    if (expirationDate <= today) {
        return false;
    }
    return true;
}

export function userHasRole(role: UserRole) {
    return new UserService().getCurrentUser()?.roles?.includes(role);
}

export const isLoggedIn = getIsLoggedIn();


export function getDashboardByRole(jwtToken: JwtTokenModel | null): string {
    if (jwtToken?.roles?.includes(UserRole.ENTREPRISE)) {
        log.debug("Navigating to /enterprise");
        return '/enterprise';
    } else if (jwtToken?.roles?.includes(UserRole.ENTREPRISE_CADRE) && !jwtToken?.firstPasswordChanged) {
        log.debug("navigating to /first-password");
        return '/first-password';
    } else if (jwtToken?.roles?.includes(UserRole.ENTREPRISE_CADRE) && jwtToken?.firstPasswordChanged) {
        log.debug("navigating to /learner");
        return '/learner';
    } else if (jwtToken?.roles?.includes(UserRole.COACH) && !jwtToken?.firstPasswordChanged) {
        log.debug("Navigating to /first-password");
        return '/first-password';
    } else if (jwtToken?.roles?.includes(UserRole.COACH) && jwtToken?.firstPasswordChanged) {
        log.debug("Navigating to /coach");
        return '/coach';
    }
    throw new Error("Role inconnu. Veuillez contacter votre administrateur.")
}

export function getProfilePageByRole(jwtToken: JwtTokenModel | null): string {
    if (jwtToken?.roles?.includes(UserRole.ENTREPRISE)) {
        log.debug("Navigating to /enterprise/profile");
        return '/enterprise/profile';
    } else if (jwtToken?.roles?.includes(UserRole.ENTREPRISE_CADRE) && jwtToken?.firstPasswordChanged) {
        log.debug("navigating to /first-password/profile");
        return '/learner/profile';
    } else if (jwtToken?.roles?.includes(UserRole.COACH)) {
        log.debug("Navigating to /coach/profile");
        return '/coach/profile';
    }
    throw new Error("Role inconnu. Veuillez contacter votre administrateur.")
}

export function encodeBase64(data: string) {
    let buff = new Buffer(data);
    let base64data = buff.toString('base64');
    log.info("Encode data to base64: " + base64data);
    return base64data;
}

export function decodeBase64(data: string) {
    let buff = new Buffer(data, 'base64');
    let text = buff.toString('ascii');
    log.info('"' + data + '" converted from Base64 to ASCII is "' + text + '"');
    return text;
}
