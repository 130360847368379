import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        button: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1)
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        video: {
            width: '100%',
        }

    })
);