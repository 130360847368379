import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        button: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1)
        },
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        rowProgress: {
            border: 0
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        controlRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(1)
        },
        formControl: {
          minWidth: '30%'
        },
        formElement:{},
        table: {
            border: '1px solid #e0e0e0'
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            borderRadius: theme.spacing(1),
        },
    })
);