import React from 'react';

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography
} from "@material-ui/core";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import LearnerSessionStepper from "../../LearnerParcoursPhaseService/LearnerSessionStepper";


export default function Resilience() {

    const phase = PhaseEnum.INTROSPECTION;
    const service = ServiceEnum.RESILIENCE;

    return (
        <LearnerSessionStepper phase={phase}
                               service={service}/>
    );
}

