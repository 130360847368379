import {
    Container,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {GridRowsProp} from '@material-ui/data-grid';
import {EnterpriseService} from "../../../services/enterprise.service";
import {EntrepriseUsersModel} from "../../../models/enterprise.model";
import EnterpriseUsersCollapsibleRow from "../EnterpriseUsersCollapsibleRow";
import {nanoid} from "nanoid";
import {getTauxAvancement} from "../../../utils/user.utils";
import PageTitle from "../../Common/PageTitle";


export default function EntrepriseUsers() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = React.useState<boolean>(false);
    const enterpriseService = new EnterpriseService();


    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const labelDisplayedRows = ({from, to, count}: { from: number, to: number, count: number }) => {
        const currentPage = page + 1;
        const pageCount = Math.ceil(count / EnterpriseService.defaultPageSize);
        const total = pageCount < 1 ? 1 : pageCount;
        return t('pagination', {current: currentPage, total: total});
    };

    const mapDataToRowModel = (purchases: EntrepriseUsersModel[]) => {
        return purchases.map((user, index) => {
                return {
                    id: index,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    firstPasswordChanged: user.firstPasswordChanged,
                    suiviConsommation: user.suiviConsommation,
                    avancement: getTauxAvancement(user.suiviConsommation)
                };
            }
        );
    }

    React.useEffect(() => {
            let active = true;
            (async () => {
                setLoading(true);
                const pageToFetch = (page < 0) ? 0 : page;
                const {total, data} = await enterpriseService.getUsers(pageToFetch)
                    .then((res) => {
                        setLoading(false);
                        return res;
                    })
                    .catch(() => {
                        setLoading(false);
                        setErrorMessage(t('enterprise.dashboard.users.unexpectedError'));
                        return {total: 0, data: []};
                    });
                if (!active) {
                    return;
                }
                setRows(mapDataToRowModel(data));
                setRowCount(total);
                setLoading(false);
            })();
            return () => {
                active = false;
            };
        },
        // eslint-disable-next-line
        [page]);

    return (<Container maxWidth="lg" className={classes.root}>

        <PageTitle title={t("menu.myUsers")}/>

        <Grid container className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow key={'0'}>
                            <TableCell>{''}</TableCell>
                            <TableCell>{t('enterprise.dashboard.users.name')}</TableCell>
                            <TableCell>{t('enterprise.dashboard.users.email')}</TableCell>
                            <TableCell>{t('enterprise.dashboard.users.status')}</TableCell>
                            <TableCell>{t('enterprise.dashboard.users.joined')}</TableCell>
                            <TableCell>{t('enterprise.dashboard.users.avancement')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && <TableRow key={nanoid()} className={classes.rowProgress}>
                            <TableCell colSpan={5}><LinearProgress/></TableCell>
                        </TableRow>}
                        {(rows).map((row) => (
                            <EnterpriseUsersCollapsibleRow row={row} key={nanoid()}/>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={4}
                                count={+rowCount}
                                page={page}
                                onChangePage={handlePageChange}
                                rowsPerPage={+EnterpriseService.defaultPageSize}
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={labelDisplayedRows}/>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>

    </Container>);
}
