import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1)
        },
        dialog: {
            display: 'flex',
            height: 'calc(100vh - 20vh)',
            [theme.breakpoints.down('sm')]: {
                height: 'calc(90vh)',
            },
        },
        video: {
            flexGrow: 1
        },
        closeIcon: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    })
);