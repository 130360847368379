import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import TRANSLATIONS_EN from "./en.json";
import TRANSLATIONS_FR from "./fr.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            // Temporary disable en lang
           /* en: {
                translation: TRANSLATIONS_EN
            },*/
            fr: {
                translation: TRANSLATIONS_FR
            }
        },
        lng: "fr",
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
