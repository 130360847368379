import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        alert: {
            marginTop: theme.spacing(2),
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        element: {

        },
        formWrapper: {
          display: "flex",
          flexDirection: "column"
        },
        formElement: {
            marginTop: theme.spacing(2)
        },
        elementTitle: {},
        elementContent: {
            padding: theme.spacing(2)
        },
        button: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(2)
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            borderRadius: theme.spacing(1)
        },
        link: {
            marginRight: theme.spacing(1)
        },
    })
);