import React from 'react';

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";


export interface PitchAdditionnalPrerequisProps {
    documentKey: string;
    readonly: boolean;
}

export default function PitchAdditionnalPrerequis(props: PitchAdditionnalPrerequisProps) {
    const {t} = useTranslation();

    return (
        <>
            <ul>
                <li>
                    <Typography variant="body1">
                        {t(`catalogue.PITCH.prerequis1`)}
                        {!props.readonly && <Link to="/learner/documents?selectedModule=PITCH">
                            {t(`catalogue.PITCH.prerequis2`)}
                        </Link>}
                        {props.readonly && t(`catalogue.PITCH.prerequis2`)}
                        {t(`catalogue.PITCH.prerequis3`)}
                    </Typography>
                </li>
            </ul>
        </>
    );
}

