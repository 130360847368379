import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {SessionDetail} from "../../../../models/session-detail";
import LearnerSessionAccordeonList from "../../../Learner/LearnerParcoursPhaseService/LearnerSessionAccordeonList";

export interface LearnerSessionHomeProps {
    service: ServiceEnum;
    phase: PhaseEnum;
    nbSession: number;
    description: string;
    goal: string;
    handleNext: () => void;
    sessionsDetails: SessionDetail[];
}

export default function CoachSessionHome(props: LearnerSessionHomeProps) {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    return (
        <>
            <div className={classes.description}>
                <Typography className={classes.accordionTitle}
                            variant="body1">{t('catalogue.presentation')}</Typography>
                <Typography variant="body1"
                            dangerouslySetInnerHTML={{__html: `<p>${props?.description}</p>`}}/>
            </div>
            <div className={classes.goal}>
                <Alert severity="info" icon={<InfoIcon fontSize="inherit"/>}>
                    <AlertTitle>{t("catalogue.objectifs")}</AlertTitle>
                    <Typography variant="body1" dangerouslySetInnerHTML={{__html: `${props?.goal}`}}/>
                </Alert>
            </div>
            <div className={classes.title}>
                <Typography variant="h6">{t("catalogue.sectionList")}</Typography>
            </div>
            <Divider/>
            <LearnerSessionAccordeonList nbSession={props.nbSession} service={props.service}
                                         sessionsDetails={props.sessionsDetails}/>
        </>
    );
}

