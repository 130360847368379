import {
    Button,
    Container,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {CoachResumeContainer} from "../../../container/CoachResumeContainer";
import {PurchaseService} from "../../../services/purchase.service";
import {GridRowsProp} from "@material-ui/data-grid";
import {EnterpriseService} from "../../../services/enterprise.service";
import {CoachSessionService} from "../../../services/coach-session.service";
import {ServiceEnum} from "../../../models/catalogue.model";
import {CoachLearnerDetailsContainer} from "../../../container/CoachLearnerDetailsContainer";
import {useNavigate} from "react-router";
import {User} from "../../../models/user.model";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import CheckIcon from "@material-ui/icons/Check";
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import {Search} from "@material-ui/icons";
import PageTitle from "../../Common/PageTitle";

export default function CoachLearners() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [filterName, setFilterName] = React.useState('');


    const coachResumeState = CoachResumeContainer.useContainer();
    const coachLearnerDetailsState = CoachLearnerDetailsContainer.useContainer();
    const userService = new UserService();
    const userID = userService.getCurrentUser().userID;
    const coachSessionService = new CoachSessionService();
    const navigate = useNavigate();

    const moduleTitle = (service: ServiceEnum) => {
        return t(`catalogue.services.learner.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.learner.${service}`)
    };

    const handleChange = (e: any) => {
        setFilterName(e.target.value);
        //debouncedSave(e.target.value as string);
    }

    const loadData = async () => {
        setLoading(true);
        const pageToFetch = (page < 0) ? 0 : page;
        if (coachLearnerDetailsState.selectedModule) {
            const {
                total,
                data
            } = await coachSessionService.getLearners(coachLearnerDetailsState.selectedModule.coachModuleID, pageToFetch, filterName)
                .then((res) => {
                    setLoading(false);
                    return res;
                })
                .catch(() => {
                    setLoading(false);
                    setErrorMessage(t('unexpectedErrorLoading'))
                    return {total: 0, data: []};
                });
            setRows(data);
            setRowCount(total);
        }
        setLoading(false);
    }

    useEffect(() => {
        coachLearnerDetailsState.setSelectedModule(coachResumeState.coachModules?.[0] || null)
    }, [coachResumeState.coachModules]);

    useEffect(() => {
        coachResumeState.loadCoachModules(userID);
    }, []);

    React.useEffect(() => {
            loadData();
        },
        // eslint-disable-next-line
        [page, coachLearnerDetailsState.selectedModule?.coachModuleID, filterName]);

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleModuleOnChange = (event: any) => {
        const value = event.target.value;
        const moduleChosed = coachResumeState.coachModules?.find(module => (module.coachModuleID === value));
        setFilterName('');
        coachLearnerDetailsState.setSelectedModule(moduleChosed || null);
    };

    const labelDisplayedRows = ({from, to, count}: { from: number, to: number, count: number }) => {
        const currentPage = page + 1;
        const pageCount = Math.ceil(count / EnterpriseService.defaultPageSize);
        const total = pageCount < 1 ? 1 : pageCount;
        return t('pagination', {current: currentPage, total: total});
    };

    const seeLearnerInfos = (row: User) => {
        coachLearnerDetailsState.setSelectedUser(row);
        navigate(`/coach/learner/details?user=${row.userID}`)
    }

    const confirmSession = (row: User) => {
        navigate(`/coach/learner/confirmSession?user=${row.userID}&phase=${coachLearnerDetailsState.selectedModule?.phase}&service=${coachLearnerDetailsState.selectedModule?.service}`);
    }


    return (<Container maxWidth="lg" className={classes.root}>
        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
        <PageTitle title={t("menu.learners.title")}/>
        <Grid container className={classes.row} direction="row"
              justify={'space-between'} alignItems={'stretch'}>
            <Paper className={classes.paper}>
                <div className={classes.controlRow}>
                    {coachLearnerDetailsState.selectedModule &&
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel id="select-module">{t("menu.learners.selectTitle")}</InputLabel>
                            <Select
                                id={'select-module'}
                                labelId={'select-module'}
                                value={coachLearnerDetailsState.selectedModule.coachModuleID}
                                onChange={handleModuleOnChange}
                            >
                                {coachResumeState.coachModules?.map(module =>
                                    <option aria-label="None" value={module.coachModuleID} key={module.coachModuleID}>
                                        {moduleTitle(module.service)}
                                    </option>)
                                }
                            </Select>
                        </FormControl>}
                    <TextField
                        variant={"outlined"}
                        className={classes.formControl}
                        label={t('coach.learners.search')}
                        value={filterName}
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search/>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('coach.learners.lastName')}</TableCell>
                                <TableCell>{t('coach.learners.firstName')}</TableCell>
                                <TableCell>{t('coach.learners.email')}</TableCell>
                                <TableCell>{t('coach.learners.parcours')}</TableCell>
                                <TableCell>{t('coach.learners.seeInfo')}</TableCell>
                                <TableCell>{t('coach.learners.confirmSession')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && <TableRow key={'progress'} className={classes.rowProgress}>
                                <TableCell colSpan={4}><LinearProgress/></TableCell>
                            </TableRow>}
                            {(rows).map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        {row.lastname}
                                    </TableCell>
                                    <TableCell>
                                        {row.firstname}
                                    </TableCell>
                                    <TableCell>
                                        {row.email}
                                    </TableCell>
                                    <TableCell>
                                        {row.parcours?.name}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => seeLearnerInfos(row as User)}
                                                color="default"
                                                variant="outlined"
                                                endIcon={<VisibilityOutlinedIcon/>}
                                                className={classes.button}>
                                            {t('coach.learners.seeMore')}
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => confirmSession(row as User)}
                                                color="primary"
                                                variant="outlined"
                                                endIcon={<CheckIcon/>}
                                                className={classes.button}>
                                            {t('coach.learners.confirm')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={3}
                                    count={+rowCount}
                                    page={page}
                                    onChangePage={handlePageChange}
                                    rowsPerPage={+PurchaseService.defaultPageSize}
                                    rowsPerPageOptions={[]}
                                    labelDisplayedRows={labelDisplayedRows}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </Grid>

    </Container>);
}
