import {useState} from "react";
import {createContainer} from "unstated-next";


function RouterContainerHook(initialState = '/signin') {
    let [lastAskedUrl, setLastAskedUrl] = useState(initialState);
    return {lastAskedUrl, setLastAskedUrl}
}

export const RouterContainer = createContainer(RouterContainerHook)

