import {useState} from "react";
import {createContainer} from "unstated-next";
import {SettingsUserService} from "../services/settings-user.service";
import {SettingsUserEnum} from "../models/settings-user.model";


function SettingsContainerHook() {
    let [primary, setPrimary] = useState('#311b92');
    let [background, setBackground] = useState('#f6f6f6');
    const settingsUserService = new SettingsUserService();

    let initPrimaryColor = async () => {
        settingsUserService.getByUserIdAndKey(SettingsUserEnum.FOREGROUND_COLOR).then((value) => {
            setPrimary(value.value || primary);
        });
    };
    let initBackgroundColor = async () => {
        settingsUserService.getByUserIdAndKey(SettingsUserEnum.BACKGROUND_COLOR).then((value) => {
            setBackground(value.value || background);
        });
    };

    return {primary, setPrimary, background, setBackground, initPrimaryColor, initBackgroundColor}
}

export const SettingsContainer = createContainer(SettingsContainerHook)

