export const PROFILE_ROUTE_PATH = '/profile';
export const ADD_USERS_ROUTE_PATH = '/enterprise/addUser';

export function isModuleRoute(path: string): boolean {
    const moduleRoutes = ['/bilanpro', '/wellbeing', '/bilanpro/booking',
        '/networking', '/digitalImage', '/cv', '/diversity', '/pitch', '/trainingAndDebrief',
        '/communication', '/selfConfiance', '/understandPersonality', '/resilience'];
    for (const moduleRoute of moduleRoutes) {
        if (path.endsWith(moduleRoute)) {
            return true;
        }
    }
    return false;
}