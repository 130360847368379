import {useNavigate} from "react-router";
import {cleanLocalStorage} from "../../../utils/login.util";


export default function useDashboardCoachHook() {

    const navigate = useNavigate();

    const dashboard = () => {
        navigate('/coach/');
    }

    const logout = () => {
        cleanLocalStorage();
        navigate('/signin');
    }

    const purchase = () => {
        navigate('/coach/purchases');
    }
    const sessions = () => {
        navigate('/coach/sessions');
    }

    const learners = () => {
        navigate('/coach/learners');
    }
    const resume = () => {
        navigate('/coach/resume');
    }
    const modules = () => {
        navigate('/coach/modules');
    }
    const paiement = () => {
        navigate('/coach/payment');
    }

    const pendingConfirmation = () => {
        navigate('/coach/pendingConfirmation');
    }

    const profile = () => {
        navigate('/coach/profile');
    }

    return {logout, dashboard, purchase, sessions, resume, paiement, profile, learners, pendingConfirmation, modules}
}