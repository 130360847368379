import React, {useEffect} from 'react';
// @ts-ignore
import {SketchPicker} from "react-color";
import {FormikErrors} from "formik";
import {BilanProModel} from "../../../../../models/bilanpro.model";


export const useBilanProStepsHook = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
                                     validateForm: (values?: any) => Promise<FormikErrors<BilanProModel>>,
                                     setFieldTouched: any,
                                     ...keys: string[]) => {

    useEffect(() => {
        // Always validate form at component mount
        for (const key of keys) {
            setFieldTouched(key, true)
        }
        validateForm();
    }, []);


}

export default useBilanProStepsHook;