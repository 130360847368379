import axios from "axios";

axios.interceptors.request.use((config) => {
    const token: string | null = localStorage.getItem('token');
    config.baseURL = process.env.REACT_APP_BACK_URL;
    config.timeout = 180 * 1000;
    if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
    }
    return config;
});
