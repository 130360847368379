import React, {useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
// @ts-ignore
import {SketchPicker} from "react-color";
import Alert from "@material-ui/lab/Alert";
import {Accordion, AccordionDetails, AccordionSummary, Container, Grid, Paper, Typography} from "@material-ui/core";
import {SettingsContainer} from "../../../container/SettingsContainer";
import BybButton from "../BybButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {SettingsUserEnum} from "../../../models/settings-user.model";
import {SettingsUserService} from "../../../services/settings-user.service";
import PageTitle from "../PageTitle";


export const MySettings = () => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [sucessMessage, setSucessMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const settingsContainer = SettingsContainer.useContainer();
    const settingsUserService = new SettingsUserService();

    const handleChangeBackgroundComplete = (color: any) => {
        settingsContainer.setBackground(color.hex);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        /*
                const primaryPromise = settingsUserService.update(SettingsUserEnum.FOREGROUND_COLOR, settingsContainer.primary);
        */
        const backgroundPromise = settingsUserService.update(SettingsUserEnum.BACKGROUND_COLOR, settingsContainer.background);
        Promise.all([backgroundPromise]).then(() => {
            setIsSubmitting(false);
            setSucessMessage(t("mySettings.successMessage"));
        }).catch(() => {
            setErrorMessage(t("mySettings.errorMessage"));
            setIsSubmitting(false);
        });
    }

    return (
        <Container maxWidth="lg" className={classes.root}>
            <PageTitle title={t("mySettings.title")}/>

            <Grid container className={classes.row} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                <Paper square className={classes.paper} elevation={1}>
                    <div className={classes.content}>
                        {/* Disable foreground color change for the moment */}
                        {/*
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{t("mySettings.foreground")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <SketchPicker
                                    color={settingsContainer.primary}
                                    onChangeComplete={handleChangePrimaryComplete}
                                />
                            </AccordionDetails>
                        </Accordion>
*/}
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography className={classes.heading}>{t("mySettings.background")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <SketchPicker
                                    color={settingsContainer.background}
                                    onChangeComplete={handleChangeBackgroundComplete}
                                />
                            </AccordionDetails>
                        </Accordion>
                        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
                        {sucessMessage && <Alert severity="success" className={classes.row}>{sucessMessage}</Alert>}
                        <BybButton classes={classes.button} onClick={handleSubmit} color="primary" variant="contained"
                                   fullWidth={true}
                                   loading={isSubmitting}
                                   label={t("mySettings.save")}>
                        </BybButton>
                    </div>
                </Paper>
            </Grid>
        </Container>


    );
}

export default MySettings;