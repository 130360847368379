import {
    Container,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {PurchaseService} from "../../../services/purchase.service";
import {GridRowsProp} from "@material-ui/data-grid";
import {EnterpriseService} from "../../../services/enterprise.service";
import {getFullnameOrEmail} from "../../../utils/user.utils";
import {formatDateToStringDate} from "../../../utils/date.util";
import {nanoid} from "nanoid";
import {MeetingConfirmationService} from "../../../services/meeting-confirmation.service";
import {MeetingModel} from "../../../models/meeting.model";
import PageTitle from "../../Common/PageTitle";


export default function CoachPendingPaiements() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const meetingConfirmationService = new MeetingConfirmationService();

    const [errorPaymentList, setErrorPaymentList] = React.useState<string>("");
    const [page, setPage] = React.useState<number>(0);
    const [loadingTable, setLoadingTable] = React.useState<boolean>(false);
    const [rowCount, setRowCount] = React.useState(0);
    const [rows, setRows] = React.useState<GridRowsProp>([]);

    const labelDisplayedRows = ({from, to, count}: { from: number, to: number, count: number }) => {
        const currentPage = page + 1;
        const pageCount = Math.ceil(count / EnterpriseService.defaultPageSize);
        const total = pageCount < 1 ? 1 : pageCount;
        return t('pagination', {current: currentPage, total: total});
    };

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const serviceTitle = (service: string): string => {
        return t(`catalogue.services.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.${service}`)
    };
    const phaseTitle = (phase: string): string => {
        return t(`catalogue.phases.${phase}`).split(':')?.[0].trim()
            || t(`catalogue.phases.${phase}`)
    };

    const rowData = (data: any): MeetingModel | null => data as MeetingModel | null;

    React.useEffect(() => {
            (async () => {
                setLoadingTable(true);
                const pageToFetch = (page < 0) ? 0 : page;
                const {
                    total,
                    data
                } = await meetingConfirmationService.getForCoach(pageToFetch)
                    .then((res) => {
                        return res;
                    })
                    .catch(() => {
                        setErrorPaymentList(t('unexpectedErrorLoading'))
                        return {total: 0, data: []};
                    })
                    .finally(() => {
                        setLoadingTable(false);
                    });
                setRows(data);
                setRowCount(total);
            })();
        },
        // eslint-disable-next-line
        [page]);


    // @ts-ignore
    return (<>
        <Container maxWidth="lg" className={classes.root}>

            <PageTitle title={t("coach.pendingPaiement.title")}/>

            <Grid container className={classes.row} direction="row"
                  justify={'flex-start'} alignItems={'stretch'}>
                {errorPaymentList && <Alert severity="error" className={classes.row}>{errorPaymentList}</Alert>}
                <Paper className={classes.paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('coach.pendingPaiement.learner')}</TableCell>
                                <TableCell>{t('coach.pendingPaiement.phase')}</TableCell>
                                <TableCell>{t('coach.pendingPaiement.service')}</TableCell>
                                <TableCell>{t('coach.pendingPaiement.session')}</TableCell>
                                <TableCell>{t('coach.pendingPaiement.date')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loadingTable && <TableRow key={nanoid()} className={classes.rowProgress}>
                                <TableCell colSpan={6}><LinearProgress/></TableCell>
                            </TableRow>}
                            {(rows).map((row, index) => (
                                <TableRow key={nanoid() + index}>
                                    <TableCell>
                                        {getFullnameOrEmail(
                                            rowData(row)?.learner?.firstname || null,
                                            rowData(row)?.learner?.lastname || null,
                                            rowData(row)?.learner?.email || '')
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {phaseTitle(rowData(row)?.phase || '')}
                                    </TableCell>
                                    <TableCell>
                                        {serviceTitle(rowData(row)?.service || '')}
                                    </TableCell>
                                    <TableCell>
                                        {rowData(row)?.session}
                                    </TableCell>
                                    <TableCell>
                                        {formatDateToStringDate(rowData(row)?.meetingStartDate || null)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow key={nanoid() + 'pagination'}>
                                <TablePagination
                                    colSpan={5}
                                    count={+rowCount}
                                    page={page}
                                    onChangePage={handlePageChange}
                                    rowsPerPage={+PurchaseService.defaultPageSize}
                                    rowsPerPageOptions={[]}
                                    labelDisplayedRows={labelDisplayedRows}/>
                            </TableRow>
                        </TableFooter>
                    </Table>

                </Paper>
            </Grid>
        </Container>
    </>);
}
