import {useState} from "react";
import {createContainer} from "unstated-next";


function LanguageContainerHook(initialState = '') {
    let [language, setLanguage] = useState(initialState);
    return {language, setLanguage}
}

export const LanguageContainer = createContainer(LanguageContainerHook)

