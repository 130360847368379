import {AppBar, Avatar, Dialog, IconButton, Paper, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {CoachSummaryModel} from "../../../models/coach-summary.model";
import {useTranslation} from "react-i18next";
import CustomVideoPlayer from "../../Common/VideoPlayer";
import CloseIcon from '@material-ui/icons/Close';
import {userHasRole} from "../../../utils/login.util";
import {UserRole} from "../../../models/user.model";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: 'white',
        color: theme.palette.text.primary
    },
    toolbar: {
        display: 'flex',
    },
    left: {
        flexGrow: 1,
    },
    close: {},
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.primary,
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    photoWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },
    photo: {},
    description: {},
    coachImage: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    button: {
        justifySelf: 'flex-end',
        alignSelf: 'flex-end',
    }
}));

export interface CoachResumePopupProps {
    coachSummary: CoachSummaryModel | null;
    open: boolean;
    handleClose: () => void
}


const CoachResumePopup: React.FC<CoachResumePopupProps> = (props: CoachResumePopupProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const getCoachName = (coachSummary: CoachSummaryModel | null) => {
        const firstName = coachSummary?.user?.firstname || '';
        const lastname = coachSummary?.user?.lastname || '';
        return firstName + ' ' + lastname;
    }

    const shortPresentation = props.coachSummary?.coachResume?.shortPresentation || '';
    const experiences = props.coachSummary?.coachResume?.experiences || '';
    const video = props.coachSummary?.coachResume?.video || '';
    const services = props.coachSummary?.coachResume?.services || '';


    return (
        <Dialog fullWidth maxWidth={'md'} open={props.open} onClose={props.handleClose}>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography className={classes.left} variant="h6">{t('catalogue.lecoach')}</Typography>
                    <IconButton className={classes.close} edge="start" color="inherit" onClick={props.handleClose}
                                aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Paper square className={classes.paper} elevation={1}>
                <div className={classes.photoWrapper}>
                    <div className={classes.photo}>
                        <Avatar
                            variant="square"
                            className={classes.coachImage}
                            alt={props.coachSummary?.user?.email}
                            src={props.coachSummary?.user?.avatar || ''}/>
                    </div>
                    <div className={classes.description}>
                        <Typography variant="h6">
                            {getCoachName(props.coachSummary)}
                        </Typography>
                        <Typography variant="body1" dangerouslySetInnerHTML={{__html: shortPresentation}}/>
                    </div>
                </div>
            </Paper>
            <Paper square className={classes.paper} elevation={1}>
                <Typography variant="h6">
                    {t('catalogue.coachResume.experiences')}
                </Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{__html: experiences}}/>
            </Paper>
            {userHasRole(UserRole.COACH) && <Paper square className={classes.paper} elevation={1}>
                <Typography variant="h6">
                    {t('catalogue.coachResume.services')}
                </Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{__html: services}}/>
            </Paper>}
            <Paper square className={classes.paper} elevation={1}>
                {video && <Typography variant="h6">
                    {t('catalogue.coachResume.video')}
                </Typography>}
                {video && <CustomVideoPlayer url={video}/>}
            </Paper>
        </Dialog>
    )
}

export default CoachResumePopup;