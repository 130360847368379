import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        column: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'stretch',
            alignContent: 'stretch',
            minheight: '100%',
            maxheight: '100%',
        },
        elementCV: {},
        elementContentSessionDoc: {
            display: 'flex',
            flexDirection: 'column'
        },
        sessionDocument: {},
        elementBilanPro: {
            marginTop: theme.spacing(2),
        },
        formElement: {
            marginTop: theme.spacing(1),
            color: theme.palette.text.primary
        },
        elementTitle: {},
        elementContent: {
            padding: theme.spacing(2)
        },
        button: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1)
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            borderRadius: theme.spacing(1)
        },
        link: {
            marginRight: theme.spacing(1)
        },
    })
);