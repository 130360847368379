import {Container, Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {CustomAccordion} from "../../Common/Accordion";
import CoachResumeItem from "../CoachResumeItem";
import CustomVideoPlayer from "../../Common/VideoPlayer";
import {CoachResumeContainer} from "../../../container/CoachResumeContainer";
import MyProfilePicture from "../../Common/MyProfilePicture";
import CoachServiceList from "../CoachServiceList";
import CustomVideoRecorderButtons from "../../Common/CustomVideoRecorderButtons";
import PageTitle from "../../Common/PageTitle";

export default function CoachResume() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const userService = new UserService();

    const [errorMessage, setErrorMessage] = useState("");
    const coachResumeState = CoachResumeContainer.useContainer();


    const userID = userService.getCurrentUser().userID;

    useEffect(() => {
        coachResumeState.loadCoachResume(userID);
        coachResumeState.loadCoachModules(userID);
    }, []);

    const submitVideo = async (userID: string, file: File | null): Promise<void> => {
        if (file) {
            await coachResumeState.submitVideo(userID, file);
        } else {
            await coachResumeState.deleteVideo(userID);
        }
    }


    return (<Container maxWidth="lg" className={classes.root}>
        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}

        <PageTitle title={t("menu.fichePresentation")}/>

        <Grid container className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <div className={classes.root}>
                <CustomAccordion
                    summary={<Typography variant="subtitle1">{t("coach.resume.avatar")}</Typography>}>
                    <div className={classes.accordionDetailsClass}>
                        <MyProfilePicture user={userService.getCurrentUser()}/>
                    </div>
                </CustomAccordion>
            </div>
        </Grid>
        <CoachServiceList coachModules={coachResumeState.coachModules || []}/>
        <CoachResumeItem submit={coachResumeState.submitShortPresentation} title={t("coach.resume.presentYourself")}
                         initialValue={coachResumeState.initialSummary}/>
        <CoachResumeItem submit={coachResumeState.submitExperiences} title={t("coach.resume.experiences")}
                         initialValue={coachResumeState.initialExperiences}/>


        <Grid container className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <div className={classes.root}>
                <CustomAccordion
                    summary={<Typography variant="subtitle1">{t("coach.resume.video")}</Typography>}>
                    <div className={classes.accordionDetailsClass}>
                        {coachResumeState.initialVideo && <CustomVideoPlayer url={coachResumeState.initialVideo}/>}
                        <div className={classes.buttonWrapper}>
                            <CustomVideoRecorderButtons submitVideo={submitVideo}
                                                        file={coachResumeState.initialVideo || null}/>
                        </div>
                    </div>
                </CustomAccordion>
            </div>
        </Grid>

    </Container>);
}