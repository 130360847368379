import {SessionDetail} from "../models/session-detail";
import {SessionDetailCoachModel} from "../models/session-detail-coach.model";

export function sessionDetails(sessionDetails: SessionDetail[], order: number): SessionDetail | null {
    return sessionDetails.find((session: SessionDetail) => {
        return session.order === order;
    }) || null;
}

export function sessionDetailsCoach(sessionDetailsCoach: SessionDetailCoachModel[], order: number): SessionDetailCoachModel | null {
    return sessionDetailsCoach.find((session: SessionDetailCoachModel) => {
        return session.order === order;
    }) || null;
}