import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Box, Collapse, IconButton, TableCell, TableRow, Typography} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {useTranslation} from "react-i18next";
import {nanoid} from "nanoid";
import AvancementTable from "../../Common/AvancementTable";
import {formatDateToStringDate} from "../../../utils/date.util";
import {getFullname} from "../../../utils/user.utils";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function EnterpriseUsersCollapsibleRow(props: { row: ReturnType<any> }) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <TableRow key={nanoid() + row.id} className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {getFullname(row.firstname, row.lastname)}
                </TableCell>
                <TableCell>
                    {row.email}
                </TableCell>
                <TableCell>
                    {row.firstPasswordChanged ?
                        t('enterprise.dashboard.users.active') :
                        t('enterprise.dashboard.users.invited')}
                </TableCell>
                <TableCell>
                    {row.firstPasswordChanged ? formatDateToStringDate(row.firstPasswordChanged) : '-'}
                </TableCell>
                <TableCell>
                    {row.avancement} %
                </TableCell>
            </TableRow>
            <TableRow key={nanoid()}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                {t('enterprise.dashboard.users.parcours')}
                            </Typography>
                            <AvancementTable suiviConsos={row.suiviConsommation}/>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}