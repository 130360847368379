import {Container, Grid} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {AccountCircleOutlined, AssignmentOutlined, Euro, TimerOutlined} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import DashboardUserContentPaper from "../../Learner/DashboardLearnerContentPaper";
import {useTranslation} from "react-i18next";
import useDashboardCoachHook from "../DashboardCoachHook";
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';

export default function DashboardCoachContent() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const {learners, resume, paiement, profile, pendingConfirmation, modules} = useDashboardCoachHook()


    return (<Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3} className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={profile}>
                <DashboardUserContentPaper icon={() => <AccountCircleOutlined/>}
                                           menuTitle={t('menu.profile')}
                                           menuDescription={t('menu.infos')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={resume}>
                <DashboardUserContentPaper icon={() => <AssignmentOutlined/>}
                                           menuTitle={t('menu.fichePresentation')}
                                           menuDescription={t('menu.fichePresentationDescription')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={modules}>
                <DashboardUserContentPaper icon={() => <ListAltIcon/>}
                                           menuTitle={t('menu.myModules')}
                                           menuDescription={t('menu.myModulesDescription')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={learners}>
                <DashboardUserContentPaper icon={() => <PeopleOutlinedIcon/>}
                                           menuTitle={t('menu.learner')}
                                           menuDescription={t('menu.learnerDescription')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={paiement}>
                <DashboardUserContentPaper icon={() => <Euro/>}
                                           menuTitle={t('menu.paiement')}
                                           menuDescription={t('menu.paiementDescription')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={pendingConfirmation}>
                <DashboardUserContentPaper icon={() => <TimerOutlined/>}
                                           menuTitle={t('menu.waitingConfirmation')}
                                           menuDescription={t('menu.waitingConfirmationDescription')}/>
            </Grid>
        </Grid>

    </Container>);
}