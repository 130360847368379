import React from 'react';
import {useField} from 'formik';
import _ from "lodash";
import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';

function SelectField(props: any) {
    const {label, data, ...rest} = props;
    const [field, meta] = useField(props); // Take props and return field/meta/helpers
    const {value: selectedValue} = field;
    const [touched, error] = _.at(meta, 'touched', 'error');
    const isError = touched && error && true;

    function _renderHelperText() {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    return (
        <FormControl {...rest} error={isError}>
            <InputLabel>{label}</InputLabel>
            <Select {...field} value={selectedValue ? selectedValue : ''}>
                {data.map((item: any, index: number) => (
                    <MenuItem key={index} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {_renderHelperText()}
        </FormControl>
    );
}

SelectField.defaultProps = {
    data: []
};


export default SelectField;
