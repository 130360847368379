import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {LIGHT_GRAY} from "../../../utils/colors.util";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            flexGrow: 1,
            textAlign: 'center',
            marginBottom: '30px',
            fontSize: "35px",
            fontWeight: theme.typography.fontWeightBold
        },
        subtitle: {
            flexGrow: 1,
            textAlign: 'center',
            marginBottom: '30px',
            fontSize: "35px",
            color: "#707070"
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            margin: theme.spacing(3),
            maxWidth: '75%',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        formElement: {
            margin: theme.spacing(1)
        },
        formSubmit: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        formProfile: {},
        formPassword: {},
        delete: {
            marginTop: theme.spacing(1),
            '&:hover': {
                textDecoration: 'underline',
                textDecorationColor: theme.palette.error.main,
                color: theme.palette.error.main,
                cursor: 'pointer'
            }
        },
        signInRoot: {},
        fieldset: {
            borderRadius: theme.spacing(2),
            borderColor: LIGHT_GRAY,
            borderWidth: '1px',
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        }
    }),
);