import {SuiviConsommationModel} from "../models/suivi-consommation.model";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";
import {JwtTokenModel} from "../models/jwt-token.model";
import {User} from "../models/user.model";
import {LearnerCoachModel} from "../models/learner-coach.model";

export function getTauxAvancement(suiviConsommation: SuiviConsommationModel[]): number {
    const nbModules = suiviConsommation.length || 1;
    const nbCompletedModules = suiviConsommation.filter(conso => conso.heuresConsommes === conso.heuresTotal).length;
    return Math.round((nbCompletedModules / nbModules) * 100);
}

export function getPageFromService(phase: PhaseEnum, service: ServiceEnum): string {
    switch (service) {
        case ServiceEnum.BILAN_PRO: {
            return '/learner/bilanpro/guidelines';
        }
        case ServiceEnum.WELL_BEING: {
            return '/learner/wellbeing';
        }
        case ServiceEnum.NETWORKING: {
            return '/learner/networking';
        }
        case ServiceEnum.DIGITAL_IMAGE: {
            return '/learner/digitalImage';
        }
        case ServiceEnum.RESILIENCE: {
            return '/learner/resilience';
        }
        case ServiceEnum.SELF_CONFIANCE: {
            return '/learner/selfConfiance';
        }
        case ServiceEnum.DEVELOP_COMMUNICATION: {
            return '/learner/communication';
        }
        case ServiceEnum.UNDERSTAND_MY_PERSONALITY: {
            return '/learner/understandPersonality';
        }
        case ServiceEnum.PITCH: {
            return '/learner/pitch';
        }
        case ServiceEnum.CV: {
            return '/learner/cv';
        }
        case ServiceEnum.DIVERSITY: {
            return '/learner/diversity';

        }
        case ServiceEnum.TRAINING_AND_DEBRIEF: {
            return '/learner/trainingAndDebrief';
        }
        case ServiceEnum.VISION_360: {
            return '/learner/360View';
        }
        case ServiceEnum.RAYONNER: {
            return '/learner/shine';
        }
        default: {
            return '';
        }
    }
}

export const getFullname = (firstname: string | null, lastname: string | null) => {
    return ((lastname || '') + ' ' + (firstname || ''))?.trim();
}

export const getFullnameOfUser = (user: JwtTokenModel) => {
    return getFullname(user?.firstname, user?.lastname);
}

export const getFullnameOrEmail = (firstname: string | null, lastname: string | null, email: string) => {
    const fullName = ((lastname || '') + ' ' + (firstname || ''))?.trim();
    return fullName || email;
}

export const getFullnameOrEmailForUser = (user: User | JwtTokenModel) => {
    const fullName = ((user.lastname || '') + ' ' + (user.firstname || ''))?.trim();
    return fullName || user.email;
}

export const sortCoachByLastName = (coach1: LearnerCoachModel, coach2: LearnerCoachModel) => coach1.user.firstname < coach2.user.firstname ? -1 : 1;


