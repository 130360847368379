import {Grid, Snackbar, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {RichTextEditor} from "../../Common/RichTextEditor";
import {CustomAccordion} from "../../Common/Accordion";
import BybButton from "../../Common/BybButton";
import {UserService} from "../../../services/user.service";

interface CoachResumeItemProps {
    title: string;
    submit: (userID: string, value: any) => Promise<any>;
    initialValue: any;
}

export default function CoachResumeItem(props: CoachResumeItemProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const userID = new UserService().getCurrentUser().userID;

    useEffect(() => {
        setValue(props.initialValue)
    }, [props.initialValue]);

    const handleOnClick = () => {
        setErrorMessage("");
        setIsSubmitting(true);
        props.submit(userID, value).then(() => {
            setIsSubmitting(false);
            setSnackBarOpen(true);
        }).catch((err: any) => {
            setIsSubmitting(false);
            setErrorMessage(err);
        });
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };


    return (
        <Grid container className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert severity="success" onClose={handleClose}>
                    {t('successMessage')}
                </Alert>
            </Snackbar>
            <div className={classes.root}>
                <CustomAccordion
                    detailsClass={classes.accordionDetailsClass}
                    summary={<Typography variant="subtitle1">{props.title}</Typography>}>
                    <div className={classes.accordionDetailsClass}>
                        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
                        <RichTextEditor className={classes.richText} setValue={setValue}
                                        initialState={props.initialValue}/>
                        <BybButton
                            classes={classes.button}
                            onClick={handleOnClick}
                            variant="contained"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            label={t("coach.resume.save")}>
                        </BybButton>
                    </div>
                </CustomAccordion>
            </div>
        </Grid>);
}