import {Avatar, Typography} from "@material-ui/core";
import React from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {UserContainer} from "../../../container/UserContainer";

interface UserAvatarProps {
    classesWrapper?: any,
    classesTypography?: any,
    classesAvatar?: any,
    showIcon?: boolean,
    showName?: boolean
}

const UserAvatar: React.FC<UserAvatarProps> = (props: UserAvatarProps) => {
    const userState = UserContainer.useContainer();
    const user = userState.user;


    return (
        <div className={props.classesWrapper}>
            {props.showName &&
                <Typography className={props.classesTypography}>{user?.firstname}</Typography>}
            <span>&nbsp;</span>
            <Avatar
                className={props.classesAvatar}
                alt={user?.email}
                src={user?.avatar || ''}/>
            {props.showIcon && <ArrowDropDownIcon/>}
        </div>
    );
}
UserAvatar.defaultProps = {
    classesWrapper: undefined,
    classesTypography: undefined,
    classesAvatar: undefined,
    showIcon: true,
    showName: true
}
export default UserAvatar;