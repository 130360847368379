import {Container, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {CoachModuleService} from "../../../services/coach-module.service";
import {LearnerCoachModel} from "../../../models/learner-coach.model";
import CoachListGrid from "../CoachListGrid";
import PageTitle from "../../Common/PageTitle";


export default function LearnerCoachList() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [coachSummaries, setCoachSummaries] = React.useState<LearnerCoachModel[] | null>(null);

    useEffect(() => {
        new CoachModuleService().getAllCoachForUser().then(coachs => {
            setCoachSummaries(coachs);
        }).catch(() => {
            setErrorMessage(t('unexpectedErrorLoading'));
        });
    }, []);

    return (<Container maxWidth="lg" className={classes.root}>
            <Grid container className={classes.row} direction="row" alignItems={'stretch'}>
                <PageTitle title={t('menu.coachsTitle')}/>
                <div className={classes.paper}>
                    <div className={classes.content}>
                        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
                        {coachSummaries && <CoachListGrid coachSummaries={coachSummaries}/>}
                    </div>
                </div>
            </Grid>


        </Container>
    );
}
