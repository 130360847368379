// @ts-ignore
import {createSimpleLogger} from 'simple-node-logger';
import {isProduction} from "./index.util";

export const log = createSimpleLogger({timestampFormat: 'YYYY-MM-DD HH:mm:ss.SSS'});
if (isProduction()) {
    log.setLevel('info');
} else {
    log.setLevel('debug');
}
log.info = (value: any) => {
    // const args = Array.prototype.slice.call( value );
    log.log('info', '✅ - ' + value);
};
log.debug = (value: any) => {
    // const args = Array.prototype.slice.call( value );
    log.log('debug', '👀 - ' + value);
};

log.warn = (value: any) => {
    // const args = Array.prototype.slice.call( value );
    log.log('warn', '😞 - ' + value);
};

log.error = (value: any) => {
    // const args = Array.prototype.slice.call( value );
    log.log('error', '🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥');
    log.log('error', '🔥 - ' + value);
    log.log('error', '🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥');
};

