import React, {useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {ONE_MEGA_BYTES} from "../../../utils/file.utils";

const baseStyle = {
    flex: 1,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column' as any,
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: '100%'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export interface CustomDropzoneProps {
    accept: string;
    maxSize: number; // Maximum file size (in bytes)
    onFileAccepted: (file: File) => any;
}

export default function CustomDropzone(props: CustomDropzoneProps) {
    const [error, setError] = useState("");
    const maxSizeInMB = props.maxSize / ONE_MEGA_BYTES;

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: props.accept,
        maxSize: props.maxSize,
        maxFiles: 1,
        multiple: false,
        noDrag: true,
        onDrop: (acceptedFiles, fileRejections) => {
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "file-too-large") {
                        setError(t('documents.sizeError', {size: maxSizeInMB}));
                    }
                    if (err.code === "file-invalid-type") {
                        setError(t('documents.typeError', {types: props.accept}));
                    }
                });
            });
            acceptedFiles.forEach((file) => {
                props.onFileAccepted(file);
            });
        }
    });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const {t} = useTranslation();


    return (
        <>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <Typography variant="subtitle1">{t('documents.dropzoneTitle')}</Typography>
                <Typography variant="caption">{t('documents.acceptedFiles')} {props.accept}</Typography>
            </div>
            {error && <Alert severity="error">{error}</Alert>}
        </>
    );
}
