import {Container, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {useNavigate} from "react-router";
import {UserService} from "../../../services/user.service";
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import DashboardUserContentPaper from "../../Learner/DashboardLearnerContentPaper";
import {getServiceLogo} from "../../Learner/LearnerPhaseServiceList";
import {CoachResumeContainer} from "../../../container/CoachResumeContainer";
import PageTitle from "../../Common/PageTitle";

export default function CoachModules() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const coachResumeState = CoachResumeContainer.useContainer();
    const userService = new UserService();


    const moduleTitle = (service: ServiceEnum) => {
        return t(`catalogue.services.learner.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.learner.${service}`)
    };


    const listOfModules = coachResumeState.coachModules;

    const goToModuleDetails = (phase: PhaseEnum, service: ServiceEnum) => {
        navigate(`/coach/module/details?phase=${phase}&service=${service}`);
    }


    useEffect(() => {
        if (!coachResumeState?.coachModules?.length) {
            coachResumeState.loadCoachModules(userService.getCurrentUser().userID).catch(() => {
                setErrorMessage(t('unexpectedErrorLoading'));
            });
        }
    }, []);

    return (<Container maxWidth="lg" className={classes.root}>
        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
        <PageTitle title={t("menu.myModules")}/>

        <Grid container spacing={3} className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            {listOfModules?.map(module =>
                <Grid
                    key={module.coachModuleID}
                    item xs={12} md={6} lg={4} className={classes.column}
                    onClick={() => goToModuleDetails(module.phase as PhaseEnum, module.service as ServiceEnum)}>
                    <DashboardUserContentPaper icon={() => getServiceLogo(module.service as ServiceEnum)}
                                               menuTitle={moduleTitle(module.service as ServiceEnum)}
                                               menuDescription={t('coach.learnerModules.modulesDescription')}/>
                </Grid>
            )}
        </Grid>
    </Container>);
}
