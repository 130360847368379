import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {Pagination} from "../models/purchase.model";

export class CoachSessionService {

    endpoint = '/api/v1/coachSession';
    public static defaultPageSize = 10;


    public async getLearners(coachModuleID: string, page: number, filterName: string = '', maxByPage: number = CoachSessionService.defaultPageSize): Promise<any> {
        return await axios.get(`${this.endpoint}/learners?range=[${page},${maxByPage}]&module=${coachModuleID}&filterName=${filterName}`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in getting coachSession learners: " + error);
                throw error;
            });
    }


}
