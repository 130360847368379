import {Grid, Paper, Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


interface Props {
    icon: () => any,
    menuTitle: string,
    menuDescription: string
}

export default function DashboardUserContentPaper({icon, menuTitle, menuDescription}: Props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Paper square className={classes.paper} elevation={1}>
            <Grid container direction={'column'}>
                <Grid item className={classes.menuIcon}>
                    {icon()}
                </Grid>
                <Grid item className={classes.menuTitle}>
                    <Typography className={classes.menuTitleTypo} variant={'subtitle1'}
                                display={'inline'}>{menuTitle}</Typography>
                    <ChevronRightIcon/>
                </Grid>
                <Grid item className={classes.menuDescription}>
                    <Typography variant={'caption'}
                                dangerouslySetInnerHTML={{__html: `${menuDescription}`}}/>
                </Grid>

            </Grid>
        </Paper>);
}