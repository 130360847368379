import {Navigate, Route, RouteProps, useLocation} from 'react-router';
import {RouterContainer} from "../../../container/RouterContainer";
import {useEffect} from "react";
import {log} from "../../../utils/log";
import {getIsLoggedIn, userHasRole} from "../../../utils/login.util";
import {UserRole} from "../../../models/user.model";


export type ProtectedRouteProps = {
    isAuthenticated: boolean;
    authenticationPath: string;
    role?: UserRole;
} & RouteProps;

export const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: getIsLoggedIn(),
    authenticationPath: '/signin',
    // redirectPath: window.location.href,
};

export default function ProtectedRoute({
                                           isAuthenticated,
                                           authenticationPath,
                                           role,
                                           ...routeProps
                                       }: ProtectedRouteProps) {
    const routerState = RouterContainer.useContainer();
    const location = useLocation();

    useEffect(() => {
        //log.debug("Setting last asked url: " + location.pathname);
        routerState.setLastAskedUrl(location.pathname);
    }, []);

    if (getIsLoggedIn()) {
        // log.debug("isAuthenticated in ProtectedRoute");
        if (role && !userHasRole(role)) {
            log.error("You have not the permission to see the page" + location.pathname);
            return <Navigate to={{pathname: authenticationPath}}/>;
        }
        return <Route {...routeProps} />;
    } else {
        log.debug("Not authenticated in ProtectedRoute");
        return <Navigate to={{pathname: authenticationPath}}/>;
    }
};