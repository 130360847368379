import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {JwtTokenModel} from "../../../models/jwt-token.model";
import {useNavigate} from "react-router";

interface DeleAccountProps {
    user: JwtTokenModel;
}

export const DeleteAccount = (props: DeleAccountProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [errorMessageProfile, setErrorMessageProfile] = useState("");


    const [openDelete, setOpenDelete] = React.useState(false);

    const openDeleteModal = () => {
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleConfirmDelete = () => {
        setOpenDelete(false);
        setErrorMessageProfile(t(""));

        new UserService().deleteUser(props.user).then(() => {
            navigate('/signin');
            setErrorMessageProfile("");
        }).catch(() => {
            setErrorMessageProfile(t("myProfile.deleteError"));
        });
    }


    return (

        <>
            {errorMessageProfile && <Alert severity="error">{errorMessageProfile}</Alert>}
            <div className={classes.delete}>
                <Typography onClick={openDeleteModal} className={classes.paragraph}>
                    {t('myProfile.supressionCompte')}
                </Typography>
            </div>
            <AlertDeleteProfileDialog open={openDelete} handleClose={handleCloseDelete}
                                      handleConfirmDelete={handleConfirmDelete}/>


        </>
    );
}

export function AlertDeleteProfileDialog(props: { open: boolean, handleClose: () => void, handleConfirmDelete: () => void }) {
    const {t} = useTranslation();
    const theme = useTheme();
    const useStyles = makeStyles({
        danger: {
            color: theme.palette.error.light
        },
        dangerDark: {
            color: theme.palette.error.main
        }
    });
    const classes = useStyles();


    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="delete user account"
                aria-describedby="delete user account"
            >
                <DialogTitle id="alert-dialog-title" className={classes.danger}>
                    {t('myProfile.supressionCompte')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('myProfile.deleteAccountModal')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary" autoFocus>
                        {t('cancel')}
                    </Button>
                    <Button onClick={props.handleConfirmDelete} className={classes.dangerDark}>
                        {t('agree')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}