import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import PasswordInput from "../Password";
import {useTranslation} from "react-i18next";
import {ChangePassword, UserProfileDAO, UserRole} from "../../../models/user.model";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {JwtTokenModel} from "../../../models/jwt-token.model";
import MyEnterpriseProfile from "../../Enterprise/MyProfileEnterprise";
import {phoneRegExp, preventDefault} from "../../../utils/index.util";
import {UserContainer} from "../../../container/UserContainer";
import MyProfilePicture from "../MyProfilePicture";
import {userHasRole} from "../../../utils/login.util";
import {Snackbar} from "@material-ui/core";

export const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._"'€()*+,/;<=>[\]`|~{}\\]).{12,}$/);

interface MyProfileProps {
    user: JwtTokenModel;
}

const MyProfile = (props: MyProfileProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const [errorMessageProfile, setErrorMessageProfile] = useState("");
    const [sucessMessageProfile, setSucessMessageProfile] = useState("");
    const [errorMessagePassword, setErrorMessagePassword] = useState("");
    const [sucessMessagePassword, setSucessMessagePassword] = useState("");
    const [enterpriseID, setEnterpriseID] = useState("");
    const [avatarFullUrl, setAvatarFullUrl] = useState("");
    const userState = UserContainer.useContainer();
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const passwordHintText = t("signUp.errors.password.passwordHintText");

    useEffect(() => {
        setEnterpriseID(props.user.enterpriseID);
    }, [props.user.enterpriseID]);
    useEffect(() => {
        setAvatarFullUrl(props.user.avatar || '');
    }, [props.user.avatar]);

    const validationSchemaProfile = yup.object({
        email: yup
            .string()
            .email(t("signUp.errors.email.invalid"))
            .required(t("signUp.errors.email.required")),
        firstname: yup
            .string()
            .min(2, t("signUp.errors.firstname.min"))
            .max(150, t("signUp.errors.firstname.max"))
            .required(t("signUp.errors.firstname.required")),
        lastname: yup
            .string()
            .min(2, t("signUp.errors.lastname.min"))
            .max(150, t("signUp.errors.lastname.max"))
            .required(t("signUp.errors.lastname.required")),
        title: yup.string(), //.required(),
        telephone: yup
            .string()
            .matches(phoneRegExp, t("contactUs.errors.telephone"))
    });
    const validationSchemaPassword = yup.object({
        oldPassword: yup
            .string()
            .matches(passwordRegex, passwordHintText)
            .required(t("signUp.errors.password.required")),
        newPassword: yup
            .string()
            .matches(passwordRegex, passwordHintText)
            .required(t("signUp.errors.password.required")),
    });

    const formikProfileInfo = useFormik({
        initialValues: {
            email: props.user.email || '',
            firstname: props.user.firstname || '',
            lastname: props.user.lastname || '',
            telephone: props.user.telephone || '',
            title: props.user.title || '',
        } as UserProfileDAO,
        enableReinitialize: true,
        validationSchema: validationSchemaProfile,
        onSubmit: async (values, formikHelpers) => {
            formikHelpers.setSubmitting(true);
            setSucessMessageProfile("")
            setErrorMessageProfile("");
            await new UserService().updateProfileInfos(props.user.userID, values as UserProfileDAO).then(() => {
                setSucessMessageProfile(t("myProfile.success"));
                setSnackBarOpen(true);
                userState.setUser(new UserService().getCurrentUser());
            }).catch(() => {
                formikHelpers.setSubmitting(false);
                setErrorMessageProfile(t("myProfile.error"));
                formikHelpers.resetForm();
            })
            formikHelpers.setSubmitting(false);
        },
    });
    const formikPasswordInfo = useFormik({
        initialValues: {
            newPassword: '',
            oldPassword: '',
        } as ChangePassword,
        validationSchema: validationSchemaPassword,
        onSubmit: async (values, formikHelpers) => {
            formikHelpers.setSubmitting(true);
            setSucessMessagePassword("")
            setErrorMessagePassword("");
            await new UserService().updateProfilePassword(props.user.userID, values as ChangePassword).then(() => {
                setSucessMessagePassword(t("myProfile.success"));
                formikHelpers.resetForm();
                setSnackBarOpen(true);
            }).catch(() => {
                formikHelpers.setSubmitting(false);
                setErrorMessagePassword(t("myProfile.error"));
                formikHelpers.resetForm();
            })
            formikHelpers.setSubmitting(false);
        },
    });

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    return (

        <div className={classes.root}>
            <div className={classes.content}>

                {errorMessageProfile && <Alert severity="error">{errorMessageProfile}</Alert>}
                {sucessMessageProfile && <Alert severity="success">{sucessMessageProfile}</Alert>}

                <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert severity="success" onClose={handleClose}>
                        {t('myProfile.success')}
                    </Alert>
                </Snackbar>

                <div className={classes.formProfile}>
                    <form onSubmit={preventDefault && formikProfileInfo.handleSubmit}>
                        <fieldset className={classes.fieldset}>
                            <MyProfilePicture user={props.user}/>
                            <TextField
                                className={classes.formElement}
                                fullWidth
                                id="email"
                                name="email"
                                label={t('signUp.email')}
                                value={formikProfileInfo.values.email}
                                onChange={formikProfileInfo.handleChange}
                                error={formikProfileInfo.touched.email && Boolean(formikProfileInfo.errors.email)}
                                helperText={formikProfileInfo.touched.email && formikProfileInfo.errors.email}
                            />
                            <TextField
                                className={classes.formElement}
                                fullWidth
                                id="lastname"
                                name="lastname"
                                label={t('signUp.lastname')}
                                type="text"
                                value={formikProfileInfo.values.lastname}
                                onChange={formikProfileInfo.handleChange}
                                error={formikProfileInfo.touched.lastname && Boolean(formikProfileInfo.errors.lastname)}
                                helperText={formikProfileInfo.touched.lastname && formikProfileInfo.errors.lastname}
                            />
                            <TextField
                                className={classes.formElement}
                                fullWidth
                                id="firstname"
                                name="firstname"
                                label={t('signUp.firstname')}
                                type="text"
                                value={formikProfileInfo.values.firstname}
                                onChange={formikProfileInfo.handleChange}
                                error={formikProfileInfo.touched.firstname && Boolean(formikProfileInfo.errors.firstname)}
                                helperText={formikProfileInfo.touched.firstname && formikProfileInfo.errors.firstname}
                            />
                            <TextField
                                className={classes.formElement}
                                fullWidth
                                id="telephone"
                                name="telephone"
                                label={t('contactUs.telephone')}
                                value={formikProfileInfo.values.telephone}
                                onChange={formikProfileInfo.handleChange}
                                error={formikProfileInfo.touched.telephone && Boolean(formikProfileInfo.errors.telephone)}
                                helperText={formikProfileInfo.touched.telephone && formikProfileInfo.errors.telephone}
                            />
                            {!userHasRole(UserRole.COACH) && <TextField
                                className={classes.formElement}
                                fullWidth
                                id="title"
                                name="title"
                                label={t('signUp.userTitle')}
                                type="text"
                                value={formikProfileInfo.values.title}
                                onChange={formikProfileInfo.handleChange}
                                error={formikProfileInfo.touched.title && Boolean(formikProfileInfo.errors.title)}
                                helperText={formikProfileInfo.touched.title && formikProfileInfo.errors.title}
                            />}

                            <Button className={classes.formSubmit}
                                    disabled={formikProfileInfo.isValid && formikProfileInfo.isSubmitting}
                                    color="primary" variant="contained"
                                    fullWidth
                                    type="submit">
                                {t('myProfile.submitProfile')}
                            </Button>
                        </fieldset>
                    </form>
                </div>
                {UserService.isEnterpriseUser(props.user) &&
                    <MyEnterpriseProfile enterpriseID={enterpriseID}/>}
                <div className={classes.formPassword}>
                    {errorMessagePassword && <Alert severity="error">{errorMessagePassword}</Alert>}
                    {sucessMessagePassword && <Alert severity="success">{sucessMessagePassword}</Alert>}
                    <form onSubmit={preventDefault && formikPasswordInfo.handleSubmit}>
                        <fieldset className={classes.fieldset}>

                            <PasswordInput
                                className={classes.formElement}
                                fullWidth
                                id="oldPassword"
                                name="oldPassword"
                                label={t('myProfile.oldPassword')}
                                value={formikPasswordInfo.values.oldPassword}
                                onChange={formikPasswordInfo.handleChange}
                                error={formikPasswordInfo.touched.oldPassword && Boolean(formikPasswordInfo.errors.oldPassword)}
                                helperText={formikPasswordInfo.touched.oldPassword && formikPasswordInfo.errors.oldPassword}
                            />
                            <PasswordInput
                                className={classes.formElement}
                                fullWidth
                                id="newPassword"
                                name="newPassword"
                                label={t('myProfile.newPassword')}
                                value={formikPasswordInfo.values.newPassword}
                                onChange={formikPasswordInfo.handleChange}
                                error={formikPasswordInfo.touched.newPassword && Boolean(formikPasswordInfo.errors.newPassword)}
                                helperText={formikPasswordInfo.touched.newPassword && formikPasswordInfo.errors.newPassword}
                            />
                            <Button className={classes.formSubmit}
                                    disabled={formikPasswordInfo.isValid && formikPasswordInfo.isSubmitting}
                                    color="primary" variant="contained"
                                    fullWidth
                                    type="submit">
                                {t('myProfile.submitPassword')}
                            </Button>
                        </fieldset>

                    </form>

                </div>

            </div>
        </div>
    );
}

export default MyProfile;
