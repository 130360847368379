import {DiscountCodeModel} from "./discount-code.model";
import {User} from "./user.model";
import {SuiviConsommationModel} from "./suivi-consommation.model";
import {ServiceEnum} from "./catalogue.model";

// object.key = parcoursID; object.value = emails to add for this parcours
export declare type EmailsByParcoursID = { [id: string]: string[]; };


export interface AddEnterpriseUserModel {
    emails: EmailsByParcoursID;
    discountCode: DiscountCodeModel;
    payment_intent_id: string;
    payment_method_id: string;
    enterpriseID: string;
    entrepriseName: string
}

export enum SuccessEnum {
    OK = 'OK',
    KO = 'KO',
    PARTIAL = 'PARTIAL',
    PROCESSING = 'PROCESSING'
}

export class AddEnterpriseUsersResponse {
    success: SuccessEnum;
    users: User[];
    payment_intent_client_secret: string | null;

    constructor(success: SuccessEnum, users: User[], payment_intent_client_secret: string) {
        this.success = success;
        this.users = users;
        this.payment_intent_client_secret = payment_intent_client_secret;
    }
}

export interface EntrepriseUsersModel {
    firstname: string;
    lastname: string;
    email: string;
    firstPasswordChanged: Date;
    suiviConsommation: SuiviConsommationModel[];
}

export interface EnterpriseModel {
    enterpriseID: string;
    address: string;
    country: string;
    entrepriseName: string;
}

export interface CheckAddEnterpriseUserOutput {
    modulesUserAlreadyHas: ServiceEnum[];
    userAlreadyExist: boolean;
}

export interface CheckAddEnterpriseUser {
    email: string;
    parcours: string;
}