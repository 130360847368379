import React from 'react'
// @ts-ignore
import VideoRecorder from 'react-video-recorder'
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import ReactPlayer from "react-player";

interface CustomVideoPlayerProps {
    url: string;
    onPlay?: any;
    onStart?: any;
}

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = (props: CustomVideoPlayerProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);


    return (

        <div className={classes.root}>
            <ReactPlayer url={props.url} controls={true} playing={false} width={'100%'}
                         onPlay={props.onPlay} onStart={props.onStart}/>
        </div>
    )
}


CustomVideoPlayer.defaultProps = {
    onPlay: () => null,
    onStart: () => null
}

export default CustomVideoPlayer;