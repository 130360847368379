export function formatDateToStringDate(date: Date | null, locale: string | null = null): string {
    if (!date) {
        return '';
    }
    if (locale) {
        return new Intl.DateTimeFormat(locale).format(new Date(date))
    }
    return new Intl.DateTimeFormat().format(new Date(date))
}

export function formatDateToStringDateTime() {

}