import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";
import {LearnerSelfEvaluationModel} from "../models/learner-self-evaluation.model";
import {UserService} from "./user.service";

export class LearnerSelfEvaluationService {

    endpoint = '/api/v1/selfEvaluation';
    public static defaultPageSize = 10;
    private userService = new UserService();

    public async update(id: string, learnerSelfEvaluationModel: LearnerSelfEvaluationModel): Promise<LearnerSelfEvaluationModel> {
        learnerSelfEvaluationModel.userID = this.userService.getCurrentUser().userID;
        return await axios.put(`${this.endpoint}/${id}`, learnerSelfEvaluationModel).then((response) => {
            return response.data;
        }).catch((error) => {
            log.error(error);
            throw error;
        });
    }

    public async create(learnerSelfEvaluationModel: LearnerSelfEvaluationModel): Promise<LearnerSelfEvaluationModel> {
        learnerSelfEvaluationModel.userID = this.userService.getCurrentUser().userID;
        return await axios.post(`${this.endpoint}`, learnerSelfEvaluationModel).then((response) => {
            return response.data;
        }).catch((error) => {
            log.error(error);
            throw error;
        });
    }



    public async get(phase: PhaseEnum, service: ServiceEnum, session: number): Promise<LearnerSelfEvaluationModel> {
        return await axios
            .get(this.endpoint, {
                params: {phase, service, session}
            })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw error;
            });
    }


}
