import React, {useEffect, useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ErrorIcon from '@material-ui/icons/Error';
import {AlertTitle} from "@material-ui/lab";
import {UserService} from "../../../../services/user.service";
import CustomCalendlyButton from "../../../Common/CalendlyButton";
import {getFullname} from "../../../../utils/user.utils";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {isNullOrUndefinedOrEmpty} from "../../../../utils/index.util";
import TextField from "@material-ui/core/TextField";
import BybButton from "../../../Common/BybButton";
import {LearnerSelfEvaluationModel} from "../../../../models/learner-self-evaluation.model";
import {LearnerSelfEvaluationService} from "../../../../services/learner-self-evaluation.service";
import InfoIcon from "@material-ui/icons/Info";
import {SessionDetail} from "../../../../models/session-detail";
import PitchAdditionnalPrerequis from "../../LearnerParcours/Pitch/PitchAdditionnalPrerequis";
import {SessionDetailCoachModel} from "../../../../models/session-detail-coach.model";
import useCalendlyUrls from "../../LearnerParcours/useCalendlyUrls";


export interface LearnerSessionProps {
    prefillCalendly?: any;
    sessionDone: boolean;
    sessionDetails: SessionDetail | null;
    sessionsDetailsCoach: SessionDetailCoachModel | null;
    phase: PhaseEnum;
    service: ServiceEnum;
    numeroSession: number;
}

export default function LearnerSession(props: LearnerSessionProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const user = new UserService().getCurrentUser();
    const learnerSelfEvaluationService = new LearnerSelfEvaluationService();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSucessMessage] = useState("");
    const [learnerSelfEvaluation, setLearnerSelfEvaluationModel] =
        useState<LearnerSelfEvaluationModel | Partial<LearnerSelfEvaluationModel> | null>(null);
    const calendlyUrl = useCalendlyUrls(props.phase, props.service, props.numeroSession);

    const sessionPrefill = {
        email: user.email,
        firstName: user.firstname,
        lastName: user.lastname,
        name: getFullname(user.firstname, user.lastname),
    };

    const disabled = isNullOrUndefinedOrEmpty(learnerSelfEvaluation?.whatILearn) ||
        isNullOrUndefinedOrEmpty(learnerSelfEvaluation?.whatIWillDo);

    const handleSubmit = () => {
        setErrorMessage("");
        if (isNullOrUndefinedOrEmpty(learnerSelfEvaluation?.selfEvaluationID)) {
            setLoading(true);
            setSucessMessage("");
            learnerSelfEvaluationService.create(learnerSelfEvaluation as LearnerSelfEvaluationModel)
                .then(value => {
                    setLearnerSelfEvaluationModel(value);
                    setSucessMessage(t('updateSuccessMessage'));
                }).catch(() => {
                setErrorMessage(t('unexpectedError'));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(true);
            setSucessMessage("");
            learnerSelfEvaluationService.update(
                learnerSelfEvaluation?.selfEvaluationID || '',
                learnerSelfEvaluation as LearnerSelfEvaluationModel)
                .then(value => {
                    setLearnerSelfEvaluationModel(value);
                    setSucessMessage(t('updateSuccessMessage'));
                }).catch(() => {
                setErrorMessage(t('unexpectedError'));
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleQuestion1change = (event: any) => {
        setLearnerSelfEvaluationModel({
            ...learnerSelfEvaluation,
            phase: props.phase,
            service: props.service,
            session: props.sessionDetails?.order || 0,
            whatILearn: event.target.value,
        } as Partial<LearnerSelfEvaluationModel>);
    }
    const handleQuestion2change = (event: any) => {
        setLearnerSelfEvaluationModel({
            ...learnerSelfEvaluation,
            phase: props.phase,
            service: props.service,
            session: props.sessionDetails?.order || 0,
            whatIWillDo: event.target.value
        } as Partial<LearnerSelfEvaluationModel>);
    }

    useEffect(() => {
        (() => {
            setSucessMessage("");
            setErrorMessage("");
            learnerSelfEvaluationService.get(props.phase, props.service, props.sessionDetails?.order || 0)
                .then(value => {
                    setLearnerSelfEvaluationModel(value);
                }).catch((error) => {
                setLearnerSelfEvaluationModel(null);
                if (error?.response?.status !== 404) {
                    setErrorMessage(t('unexpectedErrorLoading'));
                }
            });
        })();
    }, [props.sessionDetails?.order || 0]);


    const getAdditionalPrequesiteForModule = (service: ServiceEnum) => {
        const documentKey = `${props.phase}_${props.service}_${props.sessionDetails?.order}`;

        switch (service) {
            case ServiceEnum.PITCH:
                return (<PitchAdditionnalPrerequis documentKey={documentKey} readonly={false}/>);
            default:
                return <></>
        }
    }

    const getPostSessionContent = () => {
        const adminPostsession = isNullOrUndefinedOrEmpty(props.sessionDetails) ? '' : (props.sessionDetails?.postsession || '');
        const coachPostsession = isNullOrUndefinedOrEmpty(props.sessionsDetailsCoach) ? '' : (props.sessionsDetailsCoach?.postsession || '');
        return (adminPostsession || '') + (coachPostsession || '');
    }

    const getAdminPreRequesiteContent = () => {
        const adminPrerequesite = isNullOrUndefinedOrEmpty(props.sessionDetails) ? '' : (props.sessionDetails?.prerequesite || '');
        return (adminPrerequesite || '');
    }

    const getCoachPreRequesiteContent = () => {
        const coachPrerequesite = isNullOrUndefinedOrEmpty(props.sessionsDetailsCoach) ? '' : (props.sessionsDetailsCoach?.prerequesite || '');
        return (coachPrerequesite || '');
    }

    return (
        <>
            <div>
                <Typography variant="h6">{props.sessionDetails?.title}</Typography>
            </div>
            {!isNullOrUndefinedOrEmpty(props.sessionDetails?.description) && <div className={classes.description}>
                <Typography variant="subtitle1"
                            className={classes.subtitle1}>{t("catalogue.objectifs")}</Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{__html: props.sessionDetails!.description}}/>
            </div>}
            {!isNullOrUndefinedOrEmpty(props.sessionDetails?.deroule || '') && <div className={classes.deroule}>
                <Typography variant="subtitle1" className={classes.subtitle1}>{t("catalogue.deroule")}</Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{__html: props.sessionDetails!.deroule}}/>
            </div>}
            {!isNullOrUndefinedOrEmpty(props.sessionDetails?.prerequesite) && <div className={classes.prerequesite}>
                <Alert severity="warning" icon={<ErrorIcon fontSize="inherit"/>}>
                    <AlertTitle className={classes.subtitle1}>{t("catalogue.prerequis")}</AlertTitle>
                    <Typography className={classes.prePostSession} variant="body1"
                                dangerouslySetInnerHTML={{__html: getAdminPreRequesiteContent()}}/>
                    {getAdditionalPrequesiteForModule(props.service)}
                    <Typography className={classes.prePostSession} variant="body1"
                                dangerouslySetInnerHTML={{__html: getCoachPreRequesiteContent()}}/>
                </Alert>
            </div>}
            {props.sessionDone && <div className={classes.deroule}>
                <div>
                    <Typography className={classes.subtitle1}
                                variant="subtitle1">{t("catalogue.postsession")}</Typography>
                    <div className={classes.accordionDetailsClass}>
                        {!isNullOrUndefinedOrEmpty(props.sessionDetails?.postsession) &&
                            <div className={classes.htmlPostsession}>
                                <Alert severity="info" icon={<InfoIcon fontSize="inherit"/>}>
                                    <Typography variant="body1" className={classes.prePostSession}
                                                dangerouslySetInnerHTML={{__html: getPostSessionContent()}}/>
                                </Alert>
                            </div>}
                        {errorMessage && <Alert severity="error" className={classes.row}
                                                onClose={() => setErrorMessage("")}>{errorMessage}</Alert>}
                        {successMessage && <Alert severity="success" className={classes.row}
                                                  onClose={() => setSucessMessage("")}>{successMessage}</Alert>}
                        <TextField
                            className={classes.formElement}
                            fullWidth
                            multiline
                            variant="outlined"
                            rows={7}
                            id="whatILearn"
                            name="whatILearn"
                            label={t('catalogue.postSessionQuestion1')}
                            required
                            value={learnerSelfEvaluation?.whatILearn || ''}
                            onChange={event => handleQuestion1change(event)}
                            error={isNullOrUndefinedOrEmpty(learnerSelfEvaluation?.whatILearn)}
                        />
                        <TextField
                            className={classes.whatIWillDo}
                            fullWidth
                            multiline
                            variant="outlined"
                            rows={7}
                            id="whatIWillDo"
                            name="whatIWillDo"
                            label={t('catalogue.postSessionQuestion2')}
                            type="text"
                            value={learnerSelfEvaluation?.whatIWillDo || ''}
                            onChange={event => handleQuestion2change(event)}
                            required
                            error={isNullOrUndefinedOrEmpty(learnerSelfEvaluation?.whatIWillDo)}
                        />
                        <BybButton classes={classes.formSubmit}
                                   disabled={disabled}
                                   onClick={handleSubmit}
                                   loading={loading}
                                   label={t('catalogue.savePostSession')}
                                   color="default" variant="outlined"
                                   type="button">
                        </BybButton>
                    </div>
                </div>
            </div>}
            <Divider/>
            <div className={classes.calendlyButton}>
                <CustomCalendlyButton disabled={props.sessionDone} prefill={props.prefillCalendly || sessionPrefill}
                                      url={calendlyUrl}/>
            </div>
        </>
    );
}

