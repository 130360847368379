import {useTheme} from "@material-ui/core/styles";
import React from "react";
import {Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {nanoid} from "nanoid";
import {SuiviConsommationModel} from "../../../models/suivi-consommation.model";
import {getTauxAvancement} from "../../../utils/user.utils";
import {useStyles} from "./style";
import {UserRole} from "../../../models/user.model";
import {UserService} from "../../../services/user.service";
import {sortSuiviConso} from "../../../utils/suiviConso.util";


interface AvancementTableProps {
    suiviConsos: SuiviConsommationModel[];
    displayAvancement?: boolean
}

interface AvancementTableDetailsProps {
    index: number;
    isEnterpriseUser: boolean;
    suiviConso: SuiviConsommationModel;
    nbSuiviConsos: number;
}

function AvancementTableDetails(props: AvancementTableDetailsProps) {
    const {t} = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);


    function getServiceByRole(service: string): string {
        return props.isEnterpriseUser ?
            t(`catalogue.services.${service}`) :
            t(`catalogue.services.learner.${service}`);
    }

    return (<TableRow key={nanoid() + props.index}>
        {props.isEnterpriseUser && !props.index &&
            <TableCell className={classes.parcours} rowSpan={props.nbSuiviConsos}>
                {props.suiviConso.parcours?.name}
            </TableCell>}
        <TableCell component="th" scope="row">
            {t(`catalogue.phases.${props.suiviConso.phase}`)}
        </TableCell>
        <TableCell className={classes.root}>
            {getServiceByRole(props.suiviConso.service)}
        </TableCell>
        <TableCell align="right">{props.suiviConso.heuresConsommes}</TableCell>
        <TableCell align="right">
            {props.suiviConso.heuresTotal - props.suiviConso.heuresConsommes}
        </TableCell>
    </TableRow>)
}

export default function AvancementTable(props: AvancementTableProps) {
    const {t} = useTranslation();

    function isEnterpriseUser(): boolean {
        const user = new UserService().getCurrentUser();
        return user.roles.includes(UserRole.ENTREPRISE);
    }


    return (
        <React.Fragment>
            <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow key={nanoid()}>
                        {isEnterpriseUser() && <TableCell>{t('enterprise.dashboard.users.parcours')}</TableCell>}
                        <TableCell>{t('enterprise.dashboard.users.phase')}</TableCell>
                        <TableCell>{t('enterprise.dashboard.users.service')}</TableCell>
                        <TableCell
                            align="right">{t('enterprise.dashboard.users.consumed')}</TableCell>
                        <TableCell
                            align="right">{t('enterprise.dashboard.users.remaining')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortSuiviConso(props.suiviConsos).map((suiviConso: SuiviConsommationModel, index: number) => (
                        <AvancementTableDetails index={index} isEnterpriseUser={isEnterpriseUser()}
                                                nbSuiviConsos={props.suiviConsos.length}
                                                suiviConso={suiviConso}/>
                    ))}
                </TableBody>
                {
                    props.displayAvancement &&
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={4}>
                                {t('enterprise.dashboard.users.avancement')}: {getTauxAvancement(props.suiviConsos)} %
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                }
            </Table>
        </React.Fragment>
    );
}