import React, {useEffect, useState} from 'react';

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography
} from "@material-ui/core";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {CoachModuleService} from "../../../../services/coach-module.service";


export default function useCalendlyUrls(phase: PhaseEnum, service: ServiceEnum, numeroSession: number) {
    const [calendlyUrl, setCalendlyUrl] = useState<string>("");


    useEffect(() => {
        (async () => {
            const coachModule = await new CoachModuleService().getOneByPhaseAndService(phase, service);
            const urlPrefix = coachModule.calendlyUrl;
            const url = `${urlPrefix}_session_${numeroSession}`;
            setCalendlyUrl(url);
        })();
    }, [numeroSession]);
    return calendlyUrl;
}

