import _ from "lodash";
import {useState} from "react";
import {createContainer} from "unstated-next";
import {SuiviConsommationModel, SuiviConsommationsByPhase} from "../models/suivi-consommation.model";
import {SuiviConsommationService} from "../services/suivi-consommation.service";
import {ParcoursService} from "../services/parcours.service";
import {UserService} from "../services/user.service";
import {ParcoursModel} from "../models/parcours.model";


function SuiviConsommationContainerHook() {
    const suiviConsoService = new SuiviConsommationService();
    const parcoursService = new ParcoursService();
    const userService = new UserService();

    let [suiviConso, setSuiviConso] = useState<SuiviConsommationModel[]>([]);
    let [suiviConsoByPhase, setSuiviConsoByPhase] = useState<SuiviConsommationsByPhase>({});
    let [userParcours, setUserParcours] = useState<ParcoursModel | null>(null);

    let loadSuiviConsommationsAndParcours = async (userID: string) => {
        const consos = await suiviConsoService.getSuiviConsommations(userID);
        setSuiviConso(consos);
        const groupedByPhases = _.groupBy(consos, conso => conso.phase);
        setSuiviConsoByPhase(groupedByPhases);
        // Parcours
        const user = userService.getCurrentUser();
        const parcours = await parcoursService.getParcours(consos?.[0].parcoursID, UserService.isEnterpriseCadreUser(user));
        setUserParcours(parcours);
    };


    return {
        suiviConso,
        suiviConsoByPhase,
        loadSuiviConsommationsAndParcours,
        userParcours
    }
}

export const SuiviConsommationContainer = createContainer(SuiviConsommationContainerHook)

