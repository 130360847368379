import {useState} from "react";
import {createContainer} from "unstated-next";
import {BilanProModel} from "../models/bilanpro.model";
import {BilanProService} from "../services/biilan-pro.service";
import {setFormValuesInLocalStorage} from "../utils/form.util";

function BianProContainerHook() {
    let [bilanPro, setBilanPro] = useState<BilanProModel | null>(null);
    const bilanProService = new BilanProService();

    let loadBilanPro = async (userID: string) => {
        setBilanPro(await bilanProService.getParcoursPro(userID));
    }
    let updateBilanPro = async (userID: string, values: BilanProModel) => {
        await bilanProService.updateParcoursPro(userID, values).then((res) => {
            setFormValuesInLocalStorage('bilanPro', res);
            setBilanPro(res);
        });
    }

    return {bilanPro, loadBilanPro, updateBilanPro}
}

export const BilanProContainer = createContainer(BianProContainerHook)

