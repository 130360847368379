import {Button, Container, Grid, Paper, Select, Snackbar, TextField} from "@material-ui/core";
import React, {ReactNode, useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {getFullname} from "../../../utils/user.utils";
import {useLocation} from "react-router";
import {User} from "../../../models/user.model";
import {UserService} from "../../../services/user.service";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";
import {MeetingConfirmationService} from "../../../services/meeting-confirmation.service";
import {MeetingModel} from "../../../models/meeting.model";
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import {AxiosError} from "axios";
import clsx from "clsx";
import {CoachResumeContainer} from "../../../container/CoachResumeContainer";
import {SuiviConsommationService} from "../../../services/suivi-consommation.service";
import {SuiviConsommationModel} from "../../../models/suivi-consommation.model";
import PageTitle from "../../Common/PageTitle";


export default function CoachLearnerConfirmSession() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);

    const [errorMessage, setErrorMessage] = useState("");
    const [dateFormat] = useState("dd/MM/yyyy HH:mm");
    const [learner, setLearner] = useState<User | null>(null);
    const [sessionsNumber, setSessionsNumber] = useState(0);
    const [learnerID] = useState<string | null>(params.get('user'));
    const [service, setService] = useState<string | null>(params.get('service') || '');
    const [session, setSession] = useState<number | null>(1);
    const [phase, setPhase] = useState<string | null>(params.get('phase') || '');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [startDateHelperText, setStartDateHelperText] = useState("");
    const [startDateError, setStartDateError] = useState(false);
    const [endDateHelperText, setEndDateHelperText] = useState("");
    const [endDateError, setEndDateError] = useState(false);

    const userService = new UserService();
    const meetingConfirmationService = new MeetingConfirmationService();
    const coachResumeState = CoachResumeContainer.useContainer();
    const userID = userService.getCurrentUser().userID;


    const handleSelectedService = (event: any) => {
        const value = event.target.value;
        setPhase(getPhaseFromService(value));
        setService(value);
    }

    const getPhaseFromService = (service: ServiceEnum): PhaseEnum | null => {
        return coachResumeState.coachModules?.find(module => module.service === service)?.phase || null;
    }

    const handleSelectedSession = (event: any) => {
        const value = event.target.value;
        setSession(+value);
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };


    const learnerName = getFullname(learner?.firstname || null, learner?.lastname || null)
        || learner?.email;

    const serviceTitle = (service: string): string => {
        return t(`catalogue.services.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.${service}`)
    };

    const getSessionNumbers = (): number[] => {
        const array = [];
        for (let i = 1; i <= sessionsNumber; i++) {
            array.push(i);
        }
        return array;
    }

    const submit = () => {
        setErrorMessage("");
        const meeting = {
            coachID: userService.getCurrentUser().userID,
            learnerID: learnerID,
            phase: phase,
            service: service,
            meetingStartDate: startDate,
            session: session,
            meetingEndDate: endDate
        } as MeetingModel;
        meetingConfirmationService.create(meeting).then(() => {
            setSnackBarOpen(true);
            setErrorMessage("");
            // navigate("/coach/learners");
        }).catch((err: AxiosError) => {
            if (err?.response?.status === 409) {
                setErrorMessage(t('coach.confirmSession.sessionConflict'));
            } else if (err?.response?.status === 400 && err.response?.data?.message?.startsWith("Les dates ne sont pas valides")) {
                setErrorMessage(t('coach.confirmSession.invalidDate'));
            } else if (err?.response?.status === 400) {
                setErrorMessage(t('coach.confirmSession.sessionBadNumber', {
                    precedent: meeting.session - 1,
                    current: meeting.session
                }));
            } else {
                setErrorMessage(t('unexpectedError'));
            }
        });
    }

    const isDisabled = () => {
        return isNullOrUndefinedOrEmpty(startDate) ||
            isNullOrUndefinedOrEmpty(endDate) ||
            isNullOrUndefinedOrEmpty(phase) ||
            isNullOrUndefinedOrEmpty(service) ||
            isNullOrUndefinedOrEmpty(session);
    }

    const handleStartDatePickerError = (error: ReactNode, value: any) => {
        if (value === null) {
            setStartDateError(true);
            setStartDateHelperText(t('coach.confirmSession.dateInvalid'));
        }
    }

    const handleEndDatePickerError = (error: ReactNode, value: any) => {
        if (value === null) {
            setEndDateError(true);
            setEndDateHelperText(t('coach.confirmSession.dateInvalid'));
        }
    }
    const handleSetStartDate = (value: any) => {
        if (value !== null) {
            setStartDateError(false);
            setStartDateHelperText("");
            setStartDate(value);
        }
    }

    const handleSetEndDate = (value: any) => {
        if (value !== null) {
            setEndDateError(false);
            setEndDateHelperText("");
            setEndDate(value);
        }
    }

    useEffect(() => {
        if (!coachResumeState.coachModules) {
            coachResumeState.loadCoachModules(userID).then(modules => {
                // setService(modules[0]?.service);
            });
        }
    }, []);

    React.useEffect(() => {
            (async () => {
                if (learnerID && phase && service) {
                    setErrorMessage("");
                    new UserService().getUser(learnerID).then((user: User) => {
                        setLearner(user)
                    }).catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'));
                    });
                    new SuiviConsommationService().getSuiviConsommation(learnerID, phase as PhaseEnum, service as ServiceEnum)
                        .then((suiviConso: SuiviConsommationModel) => {
                            setSessionsNumber(suiviConso.heuresTotal);
                        })
                        .catch((error: AxiosError) => {
                            if (error?.response?.status === 404) {
                                setErrorMessage(t("coach.confirmSession.phaseServiceNotFound"));
                            } else {
                                setErrorMessage(t('unexpectedErrorLoading'));
                            }
                        });
                }

            })();
        },
        [learnerID, phase, service]);


    // @ts-ignore
    return (<Container maxWidth="lg" className={classes.root}>
        <Snackbar open={snackBarOpen} autoHideDuration={20000} onClose={handleClose}>
            <Alert severity="success" onClose={handleClose}>
                {t('coach.confirmSession.successMessage')}
            </Alert>
        </Snackbar>
        <PageTitle title={t("coach.confirmSession.title", {name: learnerName})}/>
        
        <Grid container className={classes.row} direction="row"
              justify={'flex-start'} alignItems={'stretch'}>
            <Paper className={classes.paper}>
                <div className={classes.formWrapper}>
                    <TextField
                        className={classes.formElement}
                        fullWidth
                        id="learner"
                        name="learner"
                        label={t('coach.confirmSession.learner')}
                        disabled={true}
                        value={learnerName || ""}
                    />

                    {coachResumeState.coachModules && <FormControl className={classes.formElement}>
                        <InputLabel id="service">{t("coach.confirmSession.service")}</InputLabel>
                        <Select
                            labelId="service"
                            fullWidth
                            native
                            value={service}
                            onChange={(event) => handleSelectedService(event)}
                            inputProps={{
                                name: t("coach.confirmSession.service"),
                            }}
                        >
                            {coachResumeState.coachModules.map(module =>
                                <option aria-label="None" value={module.service} key={module.service}>
                                    {serviceTitle(module.service)}
                                </option>)
                            }
                        </Select>
                    </FormControl>}
                    {sessionsNumber && <FormControl className={classes.formElement}>
                        <InputLabel id="session">{t("coach.confirmSession.session")}</InputLabel>
                        <Select
                            labelId="session"
                            fullWidth
                            native
                            value={session}
                            onChange={(event) => handleSelectedSession(event)}
                            inputProps={{
                                name: t("coach.confirmSession.session"),
                            }}
                        >
                            {getSessionNumbers().map(sess =>
                                <option aria-label="None" value={sess} key={sess}>
                                    {sess}
                                </option>)
                            }
                        </Select>
                    </FormControl>}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker value={startDate}
                                        format={dateFormat}
                                        onError={handleStartDatePickerError}
                                        error={startDateError}
                                        helperText={startDateHelperText}
                                        className={classes.formElement}
                                        label={t("coach.confirmSession.startDate")}
                                        disableFuture={true}
                                        onChange={handleSetStartDate}/>
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker value={endDate}
                                        format={dateFormat}
                                        onError={handleEndDatePickerError}
                                        error={endDateError}
                                        helperText={endDateHelperText}
                                        disableFuture={true}
                                        className={classes.formElement}
                                        label={t("coach.confirmSession.endDate")}
                                        onChange={handleSetEndDate}/>
                    </MuiPickersUtilsProvider>
                </div>

                <Button variant={"contained"}
                        className={classes.button}
                        onClick={submit}
                        disabled={isDisabled()}
                        color="primary" autoFocus>
                    {t('coach.confirmSession.save')}
                </Button>
                {errorMessage &&
                    <Alert severity="error" className={clsx(classes.row, classes.alert)}>{errorMessage}</Alert>}
            </Paper>

        </Grid>
    </Container>);
}
