import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        downloadedFile: {
            display: 'flex',
            alignItems: 'center'
        },
        link: {
          marginRight: theme.spacing(1)
        },
        buttonEdit: {
            /*color: theme.palette.success.main*/
            padding:0
        },
        buttonDelete: {
            padding:0,
            color: theme.palette.error.main
        },
        formElement: {
            margin: theme.spacing(1)
        },
        row: {
            marginBottom: theme.spacing(2),
            display: 'flex'
        },
        docTitle: {
            fontWeight: theme.typography.fontWeightMedium
        },
        titleRow: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            alignItems: 'flex-end'
        },
        title: {
          flexGrow: 1
        },
        content: {
            marginTop: theme.spacing(2)
        },
        rowProgress: {
            border: 0
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
        },
        table: {
            border: '1px solid #e0e0e0'
        },
    })
);