import {Divider, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import clsx from 'clsx';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Cancel, Home} from "@material-ui/icons";
import GroupIcon from '@material-ui/icons/Group';
import {useTheme} from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {cleanLocalStorage} from "../../../utils/login.util";
import {useNavigate} from "react-router";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import {useTranslation} from "react-i18next";

interface Props {
    toggleDrawer: (open: boolean) => any
}

export default function SliderMenuEnterpriseContent({toggleDrawer}: Props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const logout = () => {
        cleanLocalStorage();
        navigate('/signin');
    }
    const purchase = () => {
        navigate('/enterprise/purchases');
    }
    const addUser = () => {
        navigate('/enterprise/addUser');
    }
    const profile = () => {
        navigate('/enterprise/profile');
    }
    const dashboard = () => {
        navigate('/enterprise/');
    }
    const myUsers = () => {
        navigate('/enterprise/users');
    }

    return (<div
        className={clsx(classes.list)}
        role="presentation">
        <div
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            className={classes.close}>
            <Typography variant={'h6'} className={classes.closeText}>Bring Your Best</Typography>
            <Typography variant={'h6'} className={classes.closeIcon}><Cancel/></Typography>
        </div>
        <Divider/>
        <List>
            <ListItem button key={'home'} onClick={dashboard}>
                <ListItemIcon><Home/></ListItemIcon>
                <ListItemText>{t('menu.home')}</ListItemText>
            </ListItem>
            <ListItem button key={'info'} onClick={profile}>
                <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                <ListItemText>{t('menu.profile')}</ListItemText>
            </ListItem>
            <ListItem button key={'addUsers'} onClick={addUser}>
                <ListItemIcon><GroupAddOutlinedIcon/></ListItemIcon>
                <ListItemText>{t('menu.addUsers')}</ListItemText>
            </ListItem>
            <ListItem button key={'users'} onClick={myUsers}>
                <ListItemIcon><GroupIcon/></ListItemIcon>
                <ListItemText>{t('menu.myUsers')}</ListItemText>
            </ListItem>
            <ListItem button key={'purchase'} onClick={purchase}>
                <ListItemIcon><ShoppingCartIcon/></ListItemIcon>
                <ListItemText>{t('menu.myPurchases')}</ListItemText>
            </ListItem>
            <ListItem button key={'logout'} onClick={logout}>
                <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                <ListItemText>{t('menu.logout')}</ListItemText>
            </ListItem>
        </List>
    </div>);
}