import {Alert} from '@material-ui/lab';
import {Formik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';
import * as yup from 'yup';
import {UserService} from '../../services/user.service';
import './styles.sass';
import {log} from "../../utils/log";
import {getProfilePageByRole, getTokenAfterLogin} from "../../utils/login.util";
import {useNavigate} from "react-router";
import {preventDefault} from "../../utils/index.util";
import {passwordRegex} from "../../components/Common/MyProfile";
import {useTranslation} from "react-i18next";
import {UserContainer} from "../../container/UserContainer";
import {CustomCheckbox} from "../../components/Common/CustomCheckbox";


const FirstPasswordChange: React.FC = () => {
    const {t} = useTranslation();

    const ResetPasswordSchema = Yup.object({
        password: yup
            .string()
            .matches(passwordRegex, t("signUp.errors.password.passwordHintText"))
            .required(t("signUp.errors.password.required")),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t('signUp.errors.password.mismatch')),
        acceptTermsOfService: yup
            .boolean()
            .oneOf([true], t("signUp.errors.acceptTermsOfService.required"))
            .required(t("signUp.errors.acceptTermsOfService.required"))
    });


    const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
    const [resetPasswordError, setResetPasswordError] = useState("");
    const navigate = useNavigate();

    const userService = new UserService();
    const userState = UserContainer.useContainer();

    return (
        <div className="wrapper">
            <div className="form-container">
                <h1>BringYourBest</h1>
                <h2>Changement de mot de passe</h2>
                {resetPasswordSuccess && <Alert severity="success">{resetPasswordSuccess}</Alert>}
                {resetPasswordError && <Alert severity="error">{resetPasswordError}</Alert>}

                <Formik
                    initialValues={{password: '', passwordConfirmation: '', acceptTermsOfService: false}}
                    validationSchema={ResetPasswordSchema}
                    onSubmit={async (values, {setSubmitting}) => {
                        setResetPasswordError('');
                        const currUser = userService.getCurrentUser();
                        await userService.firstPasswordChange(currUser.userID, values.password).then(accessToken => {
                            setSubmitting(false);
                            setResetPasswordSuccess(t('signUp.success.passwordReset'));
                            const jwtToken = getTokenAfterLogin(accessToken);
                            userState.setUser(userService.getCurrentUser());
                            log.debug("Navigating to dashboard...");
                            navigate(getProfilePageByRole(jwtToken));
                        }).catch(ko => {
                            setSubmitting(false);
                            setResetPasswordError(userService.UNEXPECTED_ERROR);
                            log.error(ko);
                        })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={preventDefault && handleSubmit}>
                            <div className="input-wrapper">
                                <label htmlFor="password">Entrer votre nouveau Mot de passe</label>
                                <input
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                <span>
                                  {errors.password && touched.password && errors.password}
                                </span>
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="passwordConfirmation">Confirmer votre nouveau Mot de passe</label>
                                <input
                                    type="password"
                                    name="passwordConfirmation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.passwordConfirmation}
                                />
                                <span>
                                  {errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation}
                                </span>
                            </div>
                            <div className="checkbox-wrapper">
                                <CustomCheckbox handleChange={handleChange} errors={errors}
                                                formName="acceptTermsOfService"/>
                            </div>
                            <button
                                type="submit"
                                className={`button ${
                                    errors.password || errors.passwordConfirmation || isSubmitting
                                        ? 'disabled'
                                        : ''
                                }`}
                                disabled={isSubmitting}
                            >
                                Continuer
                            </button>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default FirstPasswordChange;
