import {createStyles, makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) =>
    createStyles({
        coachImageBlock: {
            display: 'flex',
            flexDirection: 'column'
        },
        coachImageLegend: {
            alignSelf: 'center'
        },
        coachImage: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            '&:hover': {
                transform: 'scale(1.1)',
                cursor: 'pointer'
            },
        },
        row: {
            paddingTop: '40px',
            paddingBottom: '40px',
        },
        coachTextContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        coachText: {
            color: theme.palette.primary.main
        }
    }),
);