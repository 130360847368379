import {useState} from "react";
import {createContainer} from "unstated-next";
import {StripeAccountModel} from "../models/stripe-account.model";
import {StripeAccountService} from "../services/stripe-account.service";

function CoachStripeAccountContainerHook() {
    const [stripeAccount, setStripeAccount] = useState<StripeAccountModel | null>(null);


    const stripeAccountService = new StripeAccountService();

    let loadStripeAccount = async (userID: string) => {
        await stripeAccountService.getByUser(userID).then((stripeAccountModel: StripeAccountModel) => {
            setStripeAccount(stripeAccountModel);
        });
    }
    let oauthStart = async (scope: string, code: string, state: string, error: string, error_description: string):Promise<StripeAccountModel> => {
        return stripeAccountService.oauthStart(scope, code, state, error, error_description).then((stripeAccountModel: StripeAccountModel) => {
            setStripeAccount(stripeAccountModel);
            return stripeAccountModel;
        });
    }

    let getStripeAccount = (): StripeAccountModel | null => stripeAccount || null;



    return {
        loadStripeAccount, getStripeAccount, oauthStart
    }
}

export const CoachStripeAccountContainer = createContainer(CoachStripeAccountContainerHook)

