export interface SettingsUserModel {
    id: number;
    key: SettingsUserEnum;
    value: string;
    userID: string;
    enterpriseID: string;
}

export enum SettingsUserEnum {
    FOREGROUND_COLOR = 'FOREGROUND_COLOR',
    BACKGROUND_COLOR = 'BACKGROUND_COLOR',
    WATCH_WELCOME_VIDEO = 'WATCH_WELCOME_VIDEO'
}