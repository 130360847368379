import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
// @ts-ignore
import {SketchPicker} from "react-color";
import {setFormValuesInLocalStorage} from "../../../../../utils/form.util";
import {InputField} from "../../../../Common/FormFields";
import useBilanProStepsHook from "../BilanProStepsHook";
import {BilanProStepProps} from "../FormModel/validationSchema";
import {Link} from "@material-ui/core";


export const BilanProStep2 = (props: BilanProStepProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    useBilanProStepsHook(props.setFieldValue, props.validateForm, props.setFieldTouched, 'weaknesses', 'strengths');
    const strengthsHintLink = process.env.REACT_APP_PUBLIC_CDN + '/bilanpro/Exemples - Qualités et Défauts - Document support.pdf';

    const handleOnChange = (event: any) => {
        props.handleChange(event);
        const values = localStorage.getItem('bilanPro');
        let parsed = values ? JSON.parse(values) : null;
        const key = event.target.name;
        const value = event.target.value
        props.setFieldValue(key, value, true);
        parsed = {...parsed, ...{[key]: value}}
        parsed = Object.assign({[key]: value}, parsed || {});
        setFormValuesInLocalStorage('bilanPro', parsed);
    }

    return (
        <>
            <InputField
                className={classes.formElement}
                multiline
                fullWidth
                variant={'outlined'}
                id="strengths"
                name="strengths"
                rows={10}
                label={t('catalogue.BILAN_PRO.strengths')}
                type="text"
                style={props.style}
                onChange={handleOnChange}
                helperTextInput={
                    <Link href={strengthsHintLink} target="_blank" rel="noopener">
                        {t('catalogue.BILAN_PRO.uniqueHint', {fieldName: 'qualités'})}
                    </Link>
                }
            />
            <InputField
                className={classes.formElement}
                multiline
                fullWidth
                variant={'outlined'}
                id="weaknesses"
                name="weaknesses"
                rows={10}
                label={t('catalogue.BILAN_PRO.weaknesses')}
                type="text"
                style={props.style}
                onChange={handleOnChange}
            />
        </>
    );
}

export default BilanProStep2;