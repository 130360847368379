export interface User {
    email: string;
    roles: UserRole[];
    firstname: string;
    lastname: string;
    telephone: string;
    avatar: string;
    enabled: boolean;
    firstPasswordChanged: Date;
    userID: string;
    enterpriseID: string;
    title: string;
}

export enum UserRole {
    COACH = 'COACH',
    CADRE = 'CADRE',
    ENTREPRISE = 'ENTREPRISE',
    ENTREPRISE_CADRE = 'ENTREPRISE_CADRE',
    ADMIN = 'ADMIN',
    NONE = 'NONE'
}

export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
}

export interface UserProfileDAO {
    telephone: string;
    firstname: string;
    lastname: string;
    email: string;
    title: string;
}


