import {Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import BybButton from "../../Common/BybButton";
import {AddEnterpriseUserModel, EmailsByParcoursID} from "../../../models/enterprise.model";
import Alert from "@material-ui/lab/Alert";
import {ParcoursAmount} from "../../../models/parcours.model";
import CreateUserChipInput from "../CreateUserChipInput";
import {EnterpriseService} from "../../../services/enterprise.service";
import {AlertDialog} from "../../Common/Dialog";
import * as Yup from "yup";
import {ServiceEnum} from "../../../models/catalogue.model";

export interface DialogProps {
    parcoursAmount: ParcoursAmount[];
    reinitForm: boolean;
    onSubmit: (value: any) => void;
    submitting: boolean;
}


export default function CreateUser(props: DialogProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const {
        onSubmit,
        reinitForm,
        submitting,
        parcoursAmount
    } = props;
    const [chipValuesDictionnary, setChipValuesDictionary] = React.useState<EmailsByParcoursID>({});
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [disabledPayment] = React.useState(false);
    const [globalError, setGlobalError] = React.useState('');
    const [emailsChangedCount, setEmailsChangedCount] = React.useState(0);
    const [openAddUserConfirmDialog, setOpenAddUserConfirmDialog] = React.useState(false);
    const [addConfirmDialogBody, setAddConfirmDialogBody] = React.useState('');
    const [emailToAdd, setEmailToAdd] = React.useState<string[]>([]);
    const [saveEmailToAdd, setSaveEmailToAdd] = React.useState('');
    const [saveParcours, setSaveParcours] = React.useState('');
    const [displayAddUserConfirm, setDisplayAddUserConfirm] = React.useState(false);

    const enterpriseService = new EnterpriseService();


    const schema = Yup.object().shape({
        email: Yup.string().email(),
    });

    // Reinit all forms values
    React.useEffect(() => {
        if (reinitForm) {
            setSubmitting(false);
            setChipValuesDictionary({});
            setGlobalError('');
        }
    }, [reinitForm]);
    React.useEffect(() => {
        setSubmitting(submitting);
    }, [submitting]);

    const modulesTitle = (services: ServiceEnum[]): string[] => {
        return services.map(service =>
            t(`catalogue.services.learner.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.learner.${service}`)
        );
    };
    
    const handleSubmit = async () => {
        const value = {
            emails: chipValuesDictionnary,
        } as AddEnterpriseUserModel;
        onSubmit(value);
    };

    const handleChipValuesChanged = (values: string[], parcoursAmount: ParcoursAmount, index: number) => {
        const newDictionnary = chipValuesDictionnary;
        newDictionnary[parcoursAmount.parcoursID] = values;
        setChipValuesDictionary(newDictionnary);
        const newvalue = emailsChangedCount + 1;
        setEmailsChangedCount(newvalue);
    }

    const findParcoursIndex = (parcoursID: string): number => {
        let index = -1;
        parcoursAmount.forEach((p, i) => {
            if (p.parcoursID == parcoursID) {
                index = i;
            }
        });
        return index;
    }

    const setEmailToAddForParcours = (email: string, parcoursID: string) => {
        const index = findParcoursIndex(parcoursID);
        const emailsToAddCopy = [...emailToAdd];
        if (index >= 0) {
            emailsToAddCopy[index] = email;
        }
        setEmailToAdd(emailsToAddCopy);
    }

    const emailAlreadyAddedToThisParcours = (email: string, parcours: string): boolean => {
        return chipValuesDictionnary[parcours]?.includes(email);
    }

    const checkAddUser = async (email: string, parcours: string) => {
        if (!schema.isValidSync({email}) || emailAlreadyAddedToThisParcours(email, parcours)) {
            // If email is not valid or already added to this parcours, stop here
            return;
        }
        const checkAddUser = await enterpriseService.checkAddUser({email, parcours});
        setSaveEmailToAdd(email);
        setSaveParcours(parcours);
        if (checkAddUser.userAlreadyExist && checkAddUser.modulesUserAlreadyHas?.length > 0) {
            // If user has already modules in this parcours, display the dialog without possibility to confirm
            setDisplayAddUserConfirm(true);
            setOpenAddUserConfirmDialog(true);
            setAddConfirmDialogBody(t('enterprise.dashboard.checkAddUser.dialogBodyDuplicateModules',
                {modules: modulesTitle(checkAddUser.modulesUserAlreadyHas)})
            );
        } else if (checkAddUser.userAlreadyExist) {
            // If the user already exists, display the dialog with confirmation
            setDisplayAddUserConfirm(false);
            setOpenAddUserConfirmDialog(true);
            setAddConfirmDialogBody(t('enterprise.dashboard.checkAddUser.dialogBody'));

        } else {
            setDisplayAddUserConfirm(false);
            setOpenAddUserConfirmDialog(false);
            setEmailToAddForParcours(email, parcours);
            setSaveEmailToAdd("");
            setSaveParcours("");
            setAddConfirmDialogBody("");
        }
    }

    const handleConfirmAddUser = () => {
        setEmailToAddForParcours(saveEmailToAdd, saveParcours);
        setOpenAddUserConfirmDialog(false);
        setSaveEmailToAdd("");
        setSaveParcours("");
    }

    const isPayButtonDisabled = (Object.values(chipValuesDictionnary).flat().length === 0) || isSubmitting;


    return (<div>
        {globalError && <Alert severity="error">{globalError}</Alert>}

        <AlertDialog open={openAddUserConfirmDialog}
                     handleConfirmDelete={handleConfirmAddUser}
                     displayConfirm={displayAddUserConfirm}
                     title={t('enterprise.dashboard.checkAddUser.dialogTitle')}
                     contentText={addConfirmDialogBody}
                     handleClose={() => setOpenAddUserConfirmDialog(false)}/>

        <div className={classes.content}>
            <Typography variant={'caption'}>
                <span dangerouslySetInnerHTML={{__html: t("enterprise.dashboard.popup.caption")}}/>
            </Typography>
            {
                parcoursAmount.map((value, index) =>
                    <CreateUserChipInput key={'CreateUserChipInput' + value.parcoursID}
                                         emailToAddFromParent={emailToAdd[findParcoursIndex(value.parcoursID)]}
                                         index={index}
                                         parcoursAmount={value}
                                         disabled={disabledPayment}
                                         onChipValuesChanged={handleChipValuesChanged}
                                         onCheckAddUser={checkAddUser}
                                         reinitForm={reinitForm}
                                         chipValuesDictionnary={chipValuesDictionnary}/>
                )

            }
        </div>
        <div className={classes.dialogActions}>
            <BybButton classes={classes.button} onClick={handleSubmit} color="primary" variant="contained"
                       disabled={isPayButtonDisabled} fullWidth={true}
                       loading={isSubmitting}
                       label={t("enterprise.dashboard.popup.goToPayButton")}>
            </BybButton>
        </div>

    </div>);
}