import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import {TextField} from '@material-ui/core';
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";

export default function InputField(props: any) {
    // Rest = onChange, multiline, fullWidth, id, variant, rows...
    // meta == {value, error, touched, intitialvalue, initialTouched, initialErrors}
    // helpers = {setValue, setTouched, setError}
    // fieldInputprops = {value: Value, name : string,  multiple?: boolean, checked?: boolean, onChange: FormikHandlers['handleChange'], onBlur}

    const {label, errorText, name, ...rest} = props;
    const [fieldInputProps, fieldMetaProps, fieldHelpersProps] = useField(props);
    const [helperText, setHelperText] = useState<any[]>([]);

    useEffect(() => {
        let textComponent = [];
        if (!isNullOrUndefinedOrEmpty(fieldMetaProps.error)) {
            textComponent.push(fieldMetaProps.error || '', <br/>);
        }
        if (!isNullOrUndefinedOrEmpty(rest.helperTextInput)) {
            textComponent.push(rest.helperTextInput);
        }
        setHelperText(textComponent);
    }, [fieldMetaProps.error]);


    return (
        <TextField
            type="text"
            error={(fieldMetaProps.error != null)}
            helperText={helperText}
            {...fieldInputProps}
            {...props}
        />
    );
}
