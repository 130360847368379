import {Container, Grid, Paper, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import {useLocation} from "react-router";
import {BilanProModel} from "../../../../models/bilanpro.model";
import {BilanProService} from "../../../../services/biilan-pro.service";
import GoBackButton from "../../../Common/GoBackButton";
import {getFullname} from "../../../../utils/user.utils";
import {UserService} from "../../../../services/user.service";
import {User} from "../../../../models/user.model";

export default function CoachLearnerDetailsBilanPro() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);

    const [errorMessage, setErrorMessage] = useState("");
    const [bilanPro, setBilanPro] = useState<BilanProModel | null>(null);
    const [userID] = useState<string | null>(params.get('user'));

    const bilanProService = new BilanProService();
    const [user, setUser] = useState<User | null>(null);
    const learnerName = getFullname(user?.firstname || null, user?.lastname || null)
        || user?.email;

    React.useEffect(() => {
            (async () => {
                if (userID) {
                    new UserService().getUser(userID).then((user: User) => {
                        setUser(user)
                    }).catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'));
                    });
                    bilanProService.getParcoursPro(userID).then((bilanPro: BilanProModel) => {
                        setBilanPro(bilanPro);
                    }).catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'));
                    });
                }

            })();
        },
        [userID]
    );


    return (
        <Container maxWidth="lg" className={classes.root}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <Grid container className={classes.row} direction="row"
                  justify={'flex-end'} alignItems={'stretch'}>
                <GoBackButton/>
            </Grid>
            <Grid container className={classes.row} direction="row"
                  justify={'flex-start'} alignItems={'stretch'}>
                <Typography variant="h6"
                            dangerouslySetInnerHTML={{__html: t("coach.learnerDetailsBilanPro.title", {name: learnerName})}}/>
            </Grid>
            <Grid container className={classes.row} direction="row"
                  justify={'flex-start'} alignItems={'stretch'}>
                <Paper square className={classes.paper} elevation={1}>
                    {bilanPro &&
                        <TextField
                            variant={"outlined"}
                            multiline
                            rows={10}
                            inputProps={
                                {'readOnly': true}
                            }
                            className={classes.formElement}
                            fullWidth
                            label={t('catalogue.BILAN_PRO.parcoursProDescription')}
                            value={bilanPro.parcoursProDescription}
                        />
                    }{bilanPro?.talents &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.talents')}
                        value={bilanPro.talents}
                    />
                }{bilanPro?.strengths &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.strengths')}
                        value={bilanPro.strengths}
                    />
                }{bilanPro?.weaknesses &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.weaknesses')}
                        value={bilanPro.weaknesses}
                    />
                }{bilanPro?.plaisirs &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.plaisirs')}
                        value={bilanPro.plaisirs}
                    />
                }{bilanPro?.irritants &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.irritants')}
                        value={bilanPro.irritants}
                    />
                }{bilanPro?.valeurs &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.valeurs')}
                        value={bilanPro.valeurs}
                    />
                }{bilanPro?.unique &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.unique')}
                        value={bilanPro.unique}
                    />
                }{bilanPro?.doubts &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.doubts')}
                        value={bilanPro.doubts}
                    />
                }{bilanPro?.evolution &&
                    <TextField
                        variant={"outlined"}
                        multiline
                        rows={10}
                        inputProps={
                            {'readOnly': true}
                        } className={classes.formElement}
                        fullWidth
                        label={t('catalogue.BILAN_PRO.evolution')}
                        value={bilanPro.evolution}
                    />
                }
                </Paper>
            </Grid>

        </Container>);
}
