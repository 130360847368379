import {createStyles, makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                transform: 'scale(1.1)',
                background: '#ede7f6'
            }
        },
        menuIcon: {},
        menuTitle: {
            display: 'flex',
            alignItems: 'center'
        },
        menuDescription: {
            color: theme.palette.text.secondary,
        },
        menuTitleTypo: {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.primary.main,
        }
    }),
);