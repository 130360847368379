import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            alignItems: 'left',
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        title: {
            textAlign: 'left',
            marginTop: theme.spacing(2)
        },
        description: {
            textAlign: 'left',
            marginTop: theme.spacing(3)
        },
        deroule: {
            textAlign: 'left',
            marginTop: theme.spacing(3)
        },
        subtitle1: {
            fontWeight: theme.typography.fontWeightBold,
        },
        prerequesite: {
            textAlign: 'left',
            marginTop: theme.spacing(3),
        },
        htmlPostsession: {
            textAlign: 'left',
            marginTop: theme.spacing(0),
        },
        calendly: {
            minWidth: '320px',
            height: '630px'
        },
        calendlyButton: {
            marginTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center'
        },
        goal: {
            textAlign: 'left',
            marginTop: theme.spacing(2),
        },
        session: {
            textAlign: 'left',
            marginTop: theme.spacing(1),
        },
        accordionTitle: {
            fontWeight: theme.typography.fontWeightMedium
        },
        coachResume: {
            backgroundColor: '#ececec',
            width: '100%',
            display: 'flex',
            padding: theme.spacing(2),
            flexWrap: 'wrap'
        },
        coachTitle: {
            width: '100%'
        },
        avatar: {
            display: 'flex',
            width: '100%',
            alignItems: 'stretch'
        },
        coachImage: {
            margin: theme.spacing(1),
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
        formElement: {
            marginTop: theme.spacing(1)
        },
        whatIWillDo: {
            marginTop: theme.spacing(3)
        },
        prePostSession: {
            //color: theme.palette.text.primary
        },
        accordionDetailsClass: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            width: '100%'
        },
        formSubmit: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: 'fit-content'
        },
        row: {
            marginTop: theme.spacing(1),
        },
        contentWrapper: {},
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        content: {
            flexGrow: 1,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(3),
        },
        stepper: {
            flexGrow: 1,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        step: {
            cursor: 'pointer'
        },
        button: {
            marginTop: theme.spacing(2),
        },
        heading: {},
    }),
);