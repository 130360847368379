import React from 'react';
import {createMuiTheme, ThemeProvider, useTheme} from '@material-ui/core/styles';
import {useStyles} from "./style";
import {SwipeableDrawer} from "@material-ui/core";
import {Outlet} from "react-router-dom";
import AppTopBar from "../../components/Common/AppTopBar";
import SliderCoachContent from "../../components/Coach/SliderCoachContent";
import StripeAccountNotLoaded from "../../components/Coach/PaymentNotLoaded";


export const dashboardTheme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                color: "#311b92",
            }
        }
    },
    palette: {
        primary: {
            main: '#311b92',
        },
    },
});

export default function DashboardCoach() {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const classes = useStyles(theme);


    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return; // Ignore Tab and Shift keys
        }
        setOpen(open);
    };

    return (
        <div className={classes.root}>
            <AppTopBar open={open} toggleDrawer={toggleDrawer}/>
            <main className={classes.content}>
                <div className={classes.drawerHeader}/>
                <StripeAccountNotLoaded/>
                <ThemeProvider theme={dashboardTheme}>
                    <Outlet/>
                </ThemeProvider>
            </main>
            <React.Fragment>
                <SwipeableDrawer
                    anchor={'right'}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    className={classes.drawer}
                >
                    <SliderCoachContent toggleDrawer={toggleDrawer}/>
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
