import {Checkbox, FormControlLabel, FormHelperText, Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

interface CustomCheckboxProps {
    handleChange: any;
    errors: any;
    formName: string;
}

export const TermsAndConditionsCheckBoxText = () => {
    const {t} = useTranslation();
    const mentionsLegales = process.env.REACT_APP_PUBLIC_CDN + '/legal/term-of-conditions.pdf';
    const cguCgv = process.env.REACT_APP_PUBLIC_CDN + '/legal/cgu-and-cgv.pdf';
    const confidentiality = process.env.REACT_APP_PUBLIC_CDN + '/legal/privacy-policy.pdf';
    return (
        <Typography variant={"caption"}
                    dangerouslySetInnerHTML={{
                        __html:
                            t("signUp.acceptTermsOfService", {
                                mentionsLegales,
                                cguCgv,
                                confidentiality
                            })
                    }}
        >

        </Typography>
    )
}

export const CustomCheckbox = (props: CustomCheckboxProps) => {
    const {t} = useTranslation();

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        id={props.formName}
                        name={props.formName}
                        color="primary"
                        required
                    />
                }
                id={props.formName}
                name={props.formName}
                label={<TermsAndConditionsCheckBoxText/>}
                onChange={props.handleChange}
            />
            <FormHelperText error>
                {props.errors.acceptTermsOfService ?? ""}
            </FormHelperText>
        </>
    );

}