import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
import {Avatar, Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {LearnerCoachModel} from "../../../models/learner-coach.model";
import {CoachModuleService} from "../../../services/coach-module.service";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import CoachResumePopup from "../../Coach/CoachResumePopup";
import {sortCoachByLastName} from "../../../utils/user.utils";

export default function MyCoachListFooter() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");
    const [groupedCoachSummariesPerCoach, setGroupedCoachSummariesPerCoach] = React.useState<_.Dictionary<LearnerCoachModel[]> | null>(null);
    const [open, setOpen] = React.useState(false);
    const [coachSummary, setCoachSummary] = React.useState<LearnerCoachModel | null>(null);

    const showCoachResume = (coachSummaryModel: LearnerCoachModel) => {
        setOpen(true);
        setCoachSummary(coachSummaryModel);
    }

    const handleClosePopup = () => {
        setOpen(false);
    };

    // @ts-ignore
    useEffect(() => {
        new CoachModuleService().getAllCoachForUser().then(coachs => {
            setGroupedCoachSummariesPerCoach(_.groupBy(coachs?.sort(sortCoachByLastName), coachSummary => coachSummary.user.userID));
        }).catch(() => {
            setErrorMessage(t('unexpectedErrorLoading'));
        });
    }, []);


    return (
        <Grid container spacing={2} className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <CoachResumePopup coachSummary={coachSummary} open={open} handleClose={handleClosePopup}/>
            <Grid item xs={12} md={3} className={classes.coachTextContainer}>
                <Typography className={classes.coachText} color={'primary'}>
                    {t('learner.dashboard.coachList')}
                </Typography>
            </Grid>
            {groupedCoachSummariesPerCoach && Object.keys(groupedCoachSummariesPerCoach)?.map((item, index) => {
                return (
                    <Grid item xs={12} md={1} key={'coach_footer_' + index}>
                        <div className={classes.coachImageBlock}>
                            <Avatar
                                onClick={() => showCoachResume(groupedCoachSummariesPerCoach[item][0] as LearnerCoachModel)}
                                key={groupedCoachSummariesPerCoach[item][0]?.service + index + 'coachAvatarFooter'}
                                className={classes.coachImage}
                                alt={groupedCoachSummariesPerCoach[item][0]?.user?.email}
                                src={groupedCoachSummariesPerCoach[item][0]?.user?.avatar || ''}/>
                            <Typography
                                className={classes.coachImageLegend}
                                variant="caption">{groupedCoachSummariesPerCoach[item][0]?.user?.firstname}</Typography>
                        </div>
                    </Grid>
                );
            })}
        </Grid>
    );
}