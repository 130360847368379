import {Divider, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import clsx from 'clsx';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {AttachFile, Cancel, Home, InsertChart, Notifications, PeopleAlt} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {cleanLocalStorage} from "../../../utils/login.util";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import FlagIcon from '@material-ui/icons/Flag';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import WelcomeVideo from "../../Common/WelcomeVideo";

interface Props {
    toggleDrawer: (open: boolean) => any
}

export default function SliderMenuContent({toggleDrawer}: Props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [showVideo, setShowVideo] = useState(false);

    const logout = () => {
        cleanLocalStorage();
        navigate('/signin');
    }

    const profile = () => {
        toggleDrawer(false);
        navigate('/learner/profile');
    }

    const progression = () => {
        toggleDrawer(false);
        navigate('/learner/progression');
    }

    const dashboard = () => {
        navigate('/learner/');
    }

    const parcours = () => {
        navigate('/learner/parcours');
    }

    const settings = () => {
        navigate('/learner/settings');
    }

    const documents = () => {
        navigate('/learner/documents');
    }

    const notifications = () => {
        navigate('/learner/notifications');
    }

    const coachs = () => {
        navigate('/learner/coachs');
    }

    const help = () => {
        setShowVideo(true);
    }

    const closeHelpPopup = () => {
        setShowVideo(false);
    }

    return (<div
        className={clsx(classes.list)}
        role="presentation">
        {showVideo && <WelcomeVideo showVideo={showVideo} onCloseVideoPopup={() => closeHelpPopup()}/>}
        <div
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            className={classes.close}>
            <Typography variant={'h6'} className={classes.closeText}>Bring Your Best</Typography>
            <Typography variant={'h6'} className={classes.closeIcon}><Cancel/></Typography>
        </div>
        <Divider/>
        <ListItem button key={'home'} onClick={dashboard}>
            <ListItemIcon><Home/></ListItemIcon>
            <ListItemText>{t('menu.home')}</ListItemText>
        </ListItem>
        <List>
            <ListItem button key={'parcours'} onClick={parcours}>
                <ListItemIcon><FlagIcon/></ListItemIcon>
                <ListItemText>{t('menu.parcoursTitle')}</ListItemText>
            </ListItem>
            <ListItem button key={'info'} onClick={profile}>
                <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                <ListItemText>{t('menu.infosTitle')}</ListItemText>
            </ListItem>
            <ListItem button key={'settings'} onClick={settings}>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                <ListItemText>{t('menu.settingsTitle')}</ListItemText>
            </ListItem>
            <ListItem button key={'conso'} onClick={progression}>
                <ListItemIcon><InsertChart/></ListItemIcon>
                <ListItemText>{t('menu.consosTitle')}</ListItemText>
            </ListItem>
            <ListItem button key={'notifs'} onClick={notifications}>
                <ListItemIcon><Notifications/></ListItemIcon>
                <ListItemText>{t('menu.notifsTitle')}</ListItemText>
            </ListItem>
            <ListItem button key={'docs'} onClick={documents}>
                <ListItemIcon><AttachFile/></ListItemIcon>
                <ListItemText>{t('menu.docsTitle')}</ListItemText>
            </ListItem>
            <ListItem button key={'coachs'} onClick={coachs}>
                <ListItemIcon><PeopleAlt/></ListItemIcon>
                <ListItemText>{t('menu.coachsTitle')}</ListItemText>
            </ListItem>
            <ListItem button key={'help'} onClick={help}>
                <ListItemIcon><HelpIcon/></ListItemIcon>
                <ListItemText>{t('menu.help')}</ListItemText>
            </ListItem>
            <ListItem button key={'logout'} onClick={logout}>
                <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                <ListItemText>{t('menu.logout')}</ListItemText>
            </ListItem>
        </List>
    </div>);
}