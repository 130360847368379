import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formElement: {
            margin: theme.spacing(1),
        },
        helperText: {
            marginBottom: theme.spacing(2),
        }
    }),
);