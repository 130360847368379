import {Container, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {getFullname} from "../../../utils/user.utils";
import {useLocation, useNavigate} from "react-router";
import {User} from "../../../models/user.model";
import {UserService} from "../../../services/user.service";
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import DashboardUserContentPaper from "../../Learner/DashboardLearnerContentPaper";
import {getServiceLogo} from "../../Learner/LearnerPhaseServiceList";
import {CoachResumeContainer} from "../../../container/CoachResumeContainer";
import {SuiviConsommationService} from "../../../services/suivi-consommation.service";
import {CoachModuleModel} from "../../../models/coach-module.model";
import {AxiosError} from "axios";
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";
import {SuiviConsommationModel} from "../../../models/suivi-consommation.model";
import {log} from "../../../utils/log";
import PageTitle from "../../Common/PageTitle";

export default function CoachLearnerDetails() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [learner, setLearner] = useState<User | null>(null);
    const [learnerModulesAllowed, setLearnerModulesAllowed] = useState<CoachModuleModel[]>([]);
    const [learnerID] = useState<string | null>(params.get('user'));
    const coachResumeState = CoachResumeContainer.useContainer();
    const userService = new UserService();
    const suiviConsommationService = new SuiviConsommationService();

    const learnerName = getFullname(learner?.firstname || null, learner?.lastname || null)
        || learner?.email;

    const moduleTitle = (service: ServiceEnum) => {
        return t(`catalogue.services.learner.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.learner.${service}`)
    };

    const goToCv = () => {
        goToModule(PhaseEnum.OPTIMIZE_IMAGE_PRO, ServiceEnum.CV);
    }
    const goToBilanPro = () => {
        navigate(`./bilanPro?user=${learner?.userID}`);
    }
    const goToModule = (phase: PhaseEnum, service: ServiceEnum) => {
        navigate(`./module?user=${learner?.userID}&phase=${phase}&service=${service}`);
    }

    const isNotCVModule = (service: ServiceEnum) => ServiceEnum.CV !== service;

    async function learnerHasAccess(phase: PhaseEnum, service: ServiceEnum): Promise<boolean> {
        if (learnerID) {
            return await suiviConsommationService.getSuiviConsommation(learnerID, phase, service)
                .then((suiviConso: SuiviConsommationModel) => {
                    return !isNullOrUndefinedOrEmpty(suiviConso);
                })
                .catch((error: AxiosError) => {
                    log.error(error);
                    return false;
                });
        }
        return Promise.resolve(false);
    }

    function coachAndLearnerCanSeeModule(module: CoachModuleModel) {
        return !!learnerModulesAllowed.find(coachModule => coachModule.service == module.service)
            && isNotCVModule(module.service);
    }

    React.useEffect(() => {
            (async () => {
                if (learnerID) {
                    userService.getUser(learnerID).then((user: User) => {
                        setLearner(user)
                    }).catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'));
                    });
                }

            })();
        },
        [learnerID]);


    useEffect(() => {
        if (!coachResumeState?.coachModules?.length) {
            coachResumeState.loadCoachModules(userService.getCurrentUser().userID);
        }
    }, []);


    useEffect(() => {
        (async () => {
            if (coachResumeState.coachModules) {
                const temp = [];
                for (const module of coachResumeState.coachModules) {
                    const doLearnerHasAccess = await learnerHasAccess(module.phase, module.service);
                    if (doLearnerHasAccess) {
                        temp.push(module);
                    }
                }
                setLearnerModulesAllowed(temp);
            }
        })();
    }, [coachResumeState.coachModules?.length, learnerID]);


    return (<Container maxWidth="lg" className={classes.root}>
        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
        <PageTitle title={t('enterprise.dashboard.homeButton')}/>
        <Grid container spacing={3} className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={goToCv}>
                <DashboardUserContentPaper icon={() => getServiceLogo(ServiceEnum.CV)}
                                           menuTitle={t('coach.learnerDetails.CV.title')}
                                           menuDescription={t('coach.learnerDetails.CV.description', {name: learnerName})}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={goToBilanPro}>
                <DashboardUserContentPaper icon={() => getServiceLogo(ServiceEnum.BILAN_PRO)}
                                           menuTitle={t('coach.learnerDetails.BILAN_PRO.title')}
                                           menuDescription={t('coach.learnerDetails.BILAN_PRO.description', {name: learnerName})}/>
            </Grid>
            {learnerModulesAllowed && coachResumeState.coachModules?.map((module, index) => {
                if (coachAndLearnerCanSeeModule(module)) {
                    return (<Grid item xs={12} md={6} lg={4} className={classes.column}
                                  key={`dashboardUserContentPaper_${index}`}
                                  onClick={() => goToModule(module.phase as PhaseEnum, module.service as ServiceEnum)}>
                        <DashboardUserContentPaper icon={() => getServiceLogo(module.service as ServiceEnum)}
                                                   menuTitle={moduleTitle(module.service as ServiceEnum)}
                                                   menuDescription={t('coach.learnerDetails.module.description', {learner: learnerName})}/>
                    </Grid>);
                }
            })}
        </Grid>
    </Container>);
}
