import {Divider, Link, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {CustomAccordion} from "../../../Common/Accordion";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {UserService} from "../../../../services/user.service";
import {UserDocumentModel} from "../../../../models/user-document.model";
import {UserDocumentsContainer} from "../../../../container/UserDocumentContainer";
import CustomVideoPlayer from "../../../Common/VideoPlayer";
import BybButton from "../../../Common/BybButton";
import {RichTextEditor} from "../../../Common/RichTextEditor";
import {SessionDetailsCoachService} from "../../../../services/session-details-coach.service";
import {SessionDetailCoachModel} from "../../../../models/session-detail-coach.model";
import Alert from "@material-ui/lab/Alert";
import {CoachModuleService} from "../../../../services/coach-module.service";
import {CoachModuleModel} from "../../../../models/coach-module.model";

export interface CoachLearnerDetailsModuleProps {
    sessionNumber: number;
    phase: PhaseEnum | null;
    service: ServiceEnum | null;
    learnerID: string | null;
}

export default function CoachLearnerDetailsModuleSession(props: CoachLearnerDetailsModuleProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();


    const userDocumentState = UserDocumentsContainer.useContainer();
    const sessionDetailsCoachService = new SessionDetailsCoachService();
    const
        [postSession, setPostSession] = useState<string | null>(null);
    const [preSession, setPreSession] = useState<string | null>(null);
    const [initialPostSession, setInitialPostSession] = useState<string>("");
    const [initialPreSession, setInitialPreSession] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [coachHasRigth, setCoachHasRigth] = useState(false);
    const [loadingCompleted, setLoadingCompleted] = useState(false);

    const getDocKey = (sessionNumber: number) => `${props.phase}_${props.service}_${sessionNumber}`;
    const getDocument = (sessionNumber: number): UserDocumentModel | null => {
        const key = `${props.phase}_${props.service}_${sessionNumber}`;
        return userDocumentState.getUserDoc(key);
    }

    const getPitchDocument = (sessionNumber: number, order: number): UserDocumentModel | null => {
        return userDocumentState.getUserDoc(`${getDocKey(sessionNumber)}_${order}`);
    };

    const getDocumentName = (sessionNumber: number): string => {
        return getDocument(sessionNumber)?.originalName ||
            t('documents.sessionDefaultName', {phase: props.phase, service: props.service, session: sessionNumber});
    };

    const getFileLinkOrVideoPlayer = (service: ServiceEnum | null, sessionNumber: number) => {
        switch (service) {
            case ServiceEnum.PITCH:
                const file1 = getPitchDocument(props.sessionNumber, 1)?.file || '';
                const file2 = getPitchDocument(props.sessionNumber, 2)?.file || '';
                return (
                    <>
                        <Typography variant="subtitle1">{t('coach.learnerDetails.module.learnerVideo')}</Typography>
                        {file1 && <CustomVideoPlayer url={file1}/>}
                        {file2 && <CustomVideoPlayer url={file2}/>}
                    </>
                );
            default:
                const file = getDocument(props.sessionNumber)?.file || '';
                return (file && <div key={'elementContent_' + sessionNumber}>
                    <Typography variant="subtitle1">
                        <span className={classes.subtitle1}>{t('coach.learnerDetails.module.learnerFile')}</span>
                        <Link href={file}
                              target="_blank"
                              className={classes.link}>
                            {getDocumentName(sessionNumber)}
                        </Link>
                    </Typography>
                </div>);

        }
    }

    const updatePostSession = () => {
        const me = new UserService().getCurrentUser();
        const data = {
            postsession: postSession,
            learnerID: props.learnerID,
            coachID: me.userID,
            order: props.sessionNumber
        } as SessionDetailCoachModel;
        if (props.phase && props.service && props.learnerID) {
            setSuccessMessage('');
            setErrorMessage('');
            setIsSubmitting(true);
            sessionDetailsCoachService.updateByQueryParams(props.phase, props.service, props.sessionNumber, me.userID, props.learnerID, data)
                .then(() => {
                    setSuccessMessage(t('successMessage'))
                })
                .catch(() => {
                    setErrorMessage(t('unexpectedError'));
                }).finally(() => {
                setIsSubmitting(false);
            });
        }
    }

    const updatePreSession = () => {
        const me = new UserService().getCurrentUser();
        const data = {
            prerequesite: preSession,
            learnerID: props.learnerID,
            coachID: me.userID,
            order: props.sessionNumber
        } as SessionDetailCoachModel;
        if (props.phase && props.service && props.learnerID) {
            setSuccessMessage('');
            setErrorMessage('');
            setIsSubmitting(true);
            sessionDetailsCoachService.updateByQueryParams(props.phase, props.service, props.sessionNumber, me.userID, props.learnerID, data)
                .then(() => {
                    setSuccessMessage(t('successMessage'))
                })
                .catch(() => {
                    setErrorMessage(t('unexpectedError'));
                }).finally(() => {
                setIsSubmitting(false);
            });
        }
    }


    useEffect(() => {
            (async () => {
                const me = new UserService().getCurrentUser();
                if (props.phase && props.service && props.learnerID && props.sessionNumber) {
                    sessionDetailsCoachService.getByModuleAndCoachIDAndLearner(props.phase, props.service, props.sessionNumber, me?.userID, props.learnerID)
                        .then((value: SessionDetailCoachModel | null) => {
                            setInitialPostSession(value?.postsession || '');
                            setInitialPreSession(value?.prerequesite || '');
                        });
                }
            })();
        },
        // eslint-disable-next-line
        [props.sessionNumber, props.phase, props.service, props.learnerID]);

    useEffect(() => {
        const coachID = new UserService().getCurrentUser().userID;
        new CoachModuleService().getCoachModules(coachID).then((coachModules: CoachModuleModel[]) => {
            if (coachModules.find((module: CoachModuleModel) => module.service === props.service)) {
                setCoachHasRigth(true);
            }
        });
    }, [props.service]);

    useEffect(() => {
        (async () => {
            const key = `${props.phase}_${props.service}_${props.sessionNumber}`;
            if (props.learnerID && props.service === ServiceEnum.PITCH) {
                await userDocumentState.loadUserDocument(props.learnerID, key + '_1');
                await userDocumentState.loadUserDocument(props.learnerID, key + '_2')
                    .finally(() => setLoadingCompleted(true));
            } else if (props.learnerID) {
                await userDocumentState.loadUserDocument(props.learnerID, key)
                    .finally(() => setLoadingCompleted(true));
            }
        })();
    }, [props.learnerID, props.service]);

    const showUserDocument = (coachHasRigth || props.service === ServiceEnum.CV) &&
        userDocumentState.getUserDoc(getDocKey(props.sessionNumber))?.file;

    const showPitchDocument = coachHasRigth &&
        (getPitchDocument(props.sessionNumber, 1)?.file || getPitchDocument(props.sessionNumber, 2)?.file);


    return (
        <>
            <CustomAccordion
                key={"accordion_" + props.sessionNumber}
                summary={<Typography variant="h6"
                                     dangerouslySetInnerHTML={{__html: t("coach.learnerDetails.module.session", {sessionNumber: props.sessionNumber})}}/>}>

                <div className={classes.accordionDetails}>
                    {(showUserDocument || showPitchDocument) &&
                        <div key={'elementContent_' + props.sessionNumber}>
                            {getFileLinkOrVideoPlayer(props.service as ServiceEnum, props.sessionNumber)}
                        </div>
                    }
                    {showUserDocument &&
                        <Divider className={classes.divider} variant="fullWidth"/>
                    }
                    {coachHasRigth && <>
                        <div className={classes.customRichText}>
                            <Typography
                                className={classes.subtitle1}
                                variant="subtitle1">{t("coach.learnerDetails.module.customTitlePre")}</Typography>
                            <RichTextEditor className={classes.richText} setValue={setPreSession}
                                            initialState={initialPreSession}/>
                            <BybButton
                                onClick={updatePreSession}
                                classes={classes.button}
                                loading={isSubmitting}
                                label={t('coach.learnerDetails.module.save')}
                                color="primary"
                                variant="outlined"
                                type="button">
                            </BybButton>
                        </div>
                        <Divider className={classes.divider} variant="fullWidth"/></>
                    }
                    {coachHasRigth && <div className={classes.customRichText}>
                        <Typography
                            className={classes.subtitle1}
                            variant="subtitle1">{t("coach.learnerDetails.module.customTitle")}</Typography>
                        <RichTextEditor className={classes.richText} setValue={setPostSession}
                                        initialState={initialPostSession}/>
                        <BybButton
                            onClick={updatePostSession}
                            classes={classes.button}
                            loading={isSubmitting}
                            label={t('coach.learnerDetails.module.save')}
                            color="primary"
                            variant="outlined"
                            type="button">
                        </BybButton>
                    </div>}
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    {successMessage && <Alert severity="success">{successMessage}</Alert>}
                </div>
            </CustomAccordion>
        </>);
}
