import {openPopupWidget} from "react-calendly";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {UserDocumentsContainer} from "../../../container/UserDocumentContainer";
import React, {useState} from "react";
import {useNavigate} from "react-router";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {CV_KEY} from "../../../utils/userDocs.utils";
import {UserContainer} from "../../../container/UserContainer";
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";

export interface CustomButtonProps {
    url: string;
    prefill: any;
    pageSettings?: any;
    utm?: any;
    disabled: boolean;
}

export default function CustomCalendlyButton({url, prefill, pageSettings, utm, disabled}: CustomButtonProps) {
    const userDocumentState = UserDocumentsContainer.useContainer();
    const userState = UserContainer.useContainer();
    const onClick = () => {
        const CV = userDocumentState.getUserDoc(CV_KEY);
        const currentUser = userState.getConnectedUser();
        const isNameFilled = !isNullOrUndefinedOrEmpty(currentUser?.firstname) && !isNullOrUndefinedOrEmpty(currentUser?.lastname);
        if (!CV) {
            setOpenCVDialog(true);
        } else if (!isNameFilled) {
            setOpenNameDialog(true);
        } else {
            openPopupWidget({url, prefill, pageSettings, utm});
        }
    };
    const {t} = useTranslation();
    const [openCVDialog, setOpenCVDialog] = useState(false);
    const [openNameDialog, setOpenNameDialog] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const useStyles = makeStyles({
        danger: {
            color: theme.palette.error.light
        }
    });
    const classes = useStyles();

    return (
        <>
            <Button color="primary" variant="contained" onClick={onClick} disabled={disabled}>
                {t('catalogue.calendly.button')}
            </Button>
            <Dialog open={openCVDialog} onClose={() => setOpenCVDialog(false)}>
                <DialogTitle className={classes.danger}>
                    {t('documents.cv.dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('documents.cv.dialog.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCVDialog(false)} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={() => navigate("/learner/documents")} color="primary" autoFocus>
                        {t('documents.cv.dialog.button')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openNameDialog} onClose={() => setOpenNameDialog(false)}>
                <DialogTitle className={classes.danger}>
                    {t('documents.cv.dialogNameNotFilled.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('documents.cv.dialogNameNotFilled.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenNameDialog(false)} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={() => navigate("/learner/profile")} color="primary" autoFocus>
                        {t('documents.cv.dialogNameNotFilled.button')}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};