import React, {useEffect, useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {LinearProgress, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {useLocation, useNavigate} from "react-router";
import {log} from "../../utils/log";
import {UserService} from "../../services/user.service";
import {getDashboardByRole, getTokenAfterLogin} from "../../utils/login.util";
import {UserContainer} from "../../container/UserContainer";


const SignUpEnd = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const [loginSucessMessage, setLoginSucessMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const userState = UserContainer.useContainer();

    // Similaire à componentDidMount et componentDidUpdate :
    useEffect(() => {
            const search = location.search;
            const params = new URLSearchParams(search);
            const code = params.get('code') as string;
            const email = params.get('email') as string;

            async function verifyCode() {
                setLoading(true);
                await new UserService().verifyEmail({code, email}).then((accessToken: any) => {
                    setLoading(false);
                    setLoginSucessMessage(t("signUpConfirm.success"));
                    const jwtToken = getTokenAfterLogin(accessToken);
                    userState.setUser(new UserService().getCurrentUser());
                    navigate(getDashboardByRole(jwtToken));
                }).catch(ko => {
                    log.error(ko);
                    setLoading(false);
                    setLoginErrorMessage(t("signUpConfirm.error"));
                });
            }

            verifyCode();

        },  // eslint-disable-next-line
        [location.search]);


    return (

        <div className={classes.root}>
            <div className={classes.content}>
                <Typography variant="h5" noWrap className={classes.title}>
                    BringYourBest
                </Typography>
                <Typography variant="h6" noWrap className={classes.subtitle}>
                    {t('signUpConfirm.title')}
                </Typography>
                {loginErrorMessage && <Alert severity="error">{loginErrorMessage}</Alert>}
                {loginSucessMessage && <Alert severity="success">{loginSucessMessage}</Alert>}
                {loading && <LinearProgress/>}
            </div>
        </div>
    );
}

export default SignUpEnd;