import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        session: {
            textAlign: 'left',
            marginTop: theme.spacing(1),
        },
        subtitle1: {
            fontWeight: theme.typography.fontWeightBold,
        },
        accordionTitle: {
            fontWeight: theme.typography.fontWeightMedium
        },
        accordionDetailsClass: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            width: '100%'
        },
    }),
);