import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import GoBackButton from "../GoBackButton";

export interface PageTitleProps {
    title: string,
    goToParcours?: boolean
}

export default function PageTitle(props: PageTitleProps) {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Grid container className={classes.row} direction="row" alignItems={'flex-end'}>
            <GoBackButton goToParcours={props.goToParcours}/>
            <Typography variant="h6">{props.title}</Typography>
        </Grid>
    );
}