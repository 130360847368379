import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {LIGHT_GRAY} from "../../../utils/colors.util";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notification: {
            marginRight: theme.spacing(1),
            /*border: '1px solid black',
            borderRadius: '50%'*/
        },
        notificationActivated: {
            backgroundColor: theme.palette.grey.A200
        },
        notificationIcon: {
            color: "white"
        },
        row: {
            marginBottom: theme.spacing(1),
        },
        button: {
            color: 'white'//theme.palette.text.primary
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
        },
        notifWrapper: {
            textTransform: 'none'
        },
        popper: {
            marginTop: theme.spacing(2),
            maxHeight: '75vh',
            overflow: 'auto',
            border: '1px solid gray',
            borderColor: LIGHT_GRAY,
            borderRadius: theme.spacing(1)
        }
    }),
);