import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {BilanProModel} from "../models/bilanpro.model";

export class BilanProService {

    endpoint = '/api/v1/bilanPro';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;

    public async updateParcoursPro(userID: string, bilanPro: BilanProModel): Promise<any> {
        if (userID && bilanPro) {
            return await axios.put(`${this.endpoint}/${userID}`, bilanPro)
                .then(response => {
                    return response.data;
                }).catch((error: AxiosError) => {
                    log.error("Error in bilanpro retrieving: " + error);
                    throw this.UNEXPECTED_ERROR;
                });
        }

    }

    public async getParcoursPro(userID: string): Promise<BilanProModel> {
        return await axios.get(`${this.endpoint}/${userID}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in bilanpro retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });

    }

}
