import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {SettingsUserEnum, SettingsUserModel} from "../models/settings-user.model";

export class SettingsUserService {

    endpoint = '/api/v1/settingsuser';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;


    public async update(key: SettingsUserEnum, value: string): Promise<SettingsUserModel> {
        return await axios
            .post(this.endpoint + `/${key}`, {value: value})
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async getByUserIdAndKey(key: SettingsUserEnum): Promise<SettingsUserModel> {
        return await axios
            .get(this.endpoint + `/${key}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

}
