import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            minHeight: '100%',
            margin: 'auto'
        },
        title: {
            marginBottom: theme.spacing(4),
            fontWeight: theme.typography.fontWeightMedium
        },
        subtitle: {
            color: theme.palette.text.secondary
        },
        formContainer: {
            display: 'flex',
            width: '50%',
            padding: '48px',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                margin: 'auto',
                paddingTop: '48px'
            },
        },
        formElement: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        button: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: '100px',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText
            }
        },
        inputWrapper: {}

    }),
);