import {Container, Grid, Paper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {log} from "../../../utils/log";
import Alert from "@material-ui/lab/Alert";
import {AddEnterpriseUserModel, AddEnterpriseUsersResponse, SuccessEnum} from "../../../models/enterprise.model";
import {EnterpriseService} from "../../../services/enterprise.service";
import CreateUser from "../CreateUser";
import {AlertTitle} from "@material-ui/lab";
import {UserService} from "../../../services/user.service";
import {ParcoursAmount} from "../../../models/parcours.model";
import {useLocation} from "react-router";
import PageTitle from "../../Common/PageTitle";


export default function EnterpriseAddUser() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const user = (new UserService()).getCurrentUser();

    const [parcoursAmount, setParcoursAmount] = React.useState<ParcoursAmount[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [warnMessage, setWarnMessage] = useState("");
    const [reinitCreateForm, setReinitCreateForm] = React.useState(true);
    const [submittingPayment, setSubmittingPayment] = React.useState(false);

    const enterpriseService = new EnterpriseService();

    const location = useLocation();

    const handleSubmit = async (value: Partial<AddEnterpriseUserModel>) => {
        setReinitCreateForm(false);
        setSubmittingPayment(true);
        setSuccessMessage('');
        setErrorMessage('');
        setWarnMessage('');
        setInfoMessage('');
        value.enterpriseID = user.enterpriseID;

        await enterpriseService.checkoutCreateUser(value)
            .then((response: string) => {
                setSubmittingPayment(false);
                if (response) {
                    window.location.href = response;
                } else {
                    setErrorMessage(t("enterprise.dashboard.popup.paymentError"));
                }
            }).catch((error) => {
                log.error(error);
                setSubmittingPayment(false);
                setErrorMessage(t("enterprise.dashboard.popup.paymentError"));
            });
    }


    useEffect(() => {
        async function getAmount() {
            await new EnterpriseService().getAmount().then((amount: ParcoursAmount[]) => {
                setParcoursAmount(amount);
            }).catch(ko => {
                log.error(ko);
                setErrorMessage(t('enterprise.dashboard.popup.unexpectedError'));
            });
        }

        getAmount();
    }, [t]); // Change state when input changed

    useEffect(() => {
            const search = location.search;
            const params = new URLSearchParams(search);
            const sessionID = params.get('session_id');
            if (params.get('success')?.toLowerCase() === "true" && sessionID) {
                enterpriseService.checkoutPayUser(sessionID)
                    .then((response: AddEnterpriseUsersResponse) => {
                        if (response.success === SuccessEnum.OK) {
                            setSuccessMessage(t('enterprise.dashboard.popup.successMessage'))
                        } else if (response.success === SuccessEnum.PARTIAL) {
                            setWarnMessage(t('enterprise.dashboard.popup.warnMessage'))
                        } else if (response.success === SuccessEnum.PROCESSING) {
                            setInfoMessage(t('enterprise.dashboard.popup.processingMessage'))
                        } else {
                            setErrorMessage(t('enterprise.dashboard.popup.unexpectedError'));
                        }
                    })
                    .catch(() => setErrorMessage(t('enterprise.dashboard.popup.unexpectedError')));
            } else if (params.get('success')?.toLowerCase() === "false") {
                setErrorMessage(t('enterprise.dashboard.popup.unexpectedError'));
            }

        },  // eslint-disable-next-line
        [location.search]);


    return (<Container maxWidth="lg" className={classes.root}>
        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
        {successMessage &&
            <Alert severity="success" className={classes.row}>
                <AlertTitle>{t('enterprise.dashboard.popup.successMessageTitle')}</AlertTitle>
                {successMessage}
            </Alert>}
        {warnMessage && <Alert severity="warning" className={classes.row}>{warnMessage}</Alert>}
        {infoMessage && <Alert severity="info" className={classes.row}>{infoMessage}</Alert>}

        <PageTitle title={t("enterprise.dashboard.popup.title")}/>
        <Grid container className={classes.row} direction="row" alignItems={'stretch'}>
            <Paper className={classes.paper}>
                <CreateUser
                    parcoursAmount={parcoursAmount}
                    onSubmit={handleSubmit}
                    reinitForm={reinitCreateForm}
                    submitting={submittingPayment}/>
            </Paper>
        </Grid>


    </Container>);
}