import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
// @ts-ignore
import {SketchPicker} from "react-color";
import {InputField} from "../../../../Common/FormFields";
import useBilanProStepsHook from "../BilanProStepsHook";
import {BilanProStepProps} from "../FormModel/validationSchema";
import {setFormValuesInLocalStorage} from "../../../../../utils/form.util";


export const BilanProStep1 = (props: BilanProStepProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    useBilanProStepsHook(props.setFieldValue, props.validateForm, props.setFieldTouched, 'parcoursProDescription', 'talents');


    const handleOnChange = (event: any) => {
        props.handleChange(event);
        const values = localStorage.getItem('bilanPro');
        let parsed = values ? JSON.parse(values) : null;
        const key = event.target.name;
        const value = event.target.value
        props.setFieldValue(key, value, true);
        parsed = {...parsed, ...{[key]: value}}
        parsed = Object.assign({[key]: value}, parsed || {});
        setFormValuesInLocalStorage('bilanPro', parsed);
    }


    return (
        <>
            <InputField
                className={classes.formElement}
                multiline
                fullWidth
                variant={'outlined'}
                id="parcoursProDescription"
                name="parcoursProDescription"
                rows={10}
                label={t('catalogue.BILAN_PRO.parcoursProDescription')}
                onChange={handleOnChange}
                type="text"
                style={props.style}
            />
            <InputField
                className={classes.formElement}
                multiline
                fullWidth
                variant={'outlined'}
                id="talents"
                name="talents"
                rows={10}
                label={t('catalogue.BILAN_PRO.talents')}
                onChange={handleOnChange}
                type="text"
                style={props.style}
            />
        </>
    );
}

export default BilanProStep1;