import React from 'react';

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import LearnerSession from "../LearnerSession";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {SessionDetail} from "../../../../models/session-detail";
import {SessionDetailCoachModel} from "../../../../models/session-detail-coach.model";

export interface LearnerSessionHookProps {
    sessionDone?: boolean;
    phase: PhaseEnum;
    service: ServiceEnum;
    sessionDetails: SessionDetail | null;
    sessionsDetailsCoach: SessionDetailCoachModel | null;
    numeroSession: number;
}

export default function LearnerSessionWrapper(props: LearnerSessionHookProps) {


    return (
        <>
            <LearnerSession phase={props.phase}
                            service={props.service}
                            sessionDetails={props.sessionDetails}
                            sessionsDetailsCoach={props.sessionsDetailsCoach}
                            sessionDone={props.sessionDone || false}
                            numeroSession={props.numeroSession}/>
        </>
    );
}

