import {createStyles, makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            color: theme.palette.primary.main,
            minWidth: 0,
            paddingLeft: 0,
            marginRight: theme.spacing(1),
            '&:hover': {
                transform: 'scale(1.3)',
                backgroundColor: 'transparent'
            }
        },
        icon: {}
    }),
);