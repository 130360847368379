import {Grid} from "@material-ui/core";
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import DashboardUserContentPaper from "../../Learner/DashboardLearnerContentPaper";
import {EmailOutlined} from "@material-ui/icons";
import {useStyles} from "./style";

export default function ContactUsBox() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const contact = () => {
        navigate('/contactUs');
    }

    return (
        <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={contact}>
            <DashboardUserContentPaper icon={() => <EmailOutlined/>}
                                       menuTitle={t('menu.contactUs')}
                                       menuDescription={t('menu.contactUsDescription')}/>
        </Grid>
    );
}