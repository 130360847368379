import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import {CustomAccordion} from "../../../Common/Accordion";
import {ServiceEnum} from "../../../../models/catalogue.model";
import {isNullOrUndefinedOrEmpty} from "../../../../utils/index.util";
import {SessionDetail} from "../../../../models/session-detail";
import {sessionDetails} from "../../../../utils/sessionDetails.util";

export interface LearnerSessionAccordeonListProps {
    service: ServiceEnum;
    nbSession: number;
    sessionsDetails: SessionDetail[];
}

export default function LearnerSessionAccordeonList(props: LearnerSessionAccordeonListProps) {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();


    const sessionAccordeonList = () => {
        const sessionList = [];
        for (let i = 1; i <= props.nbSession; i++) {
            const description = sessionDetails(props.sessionsDetails, i)?.description
            const sessionDescriptionTitle = isNullOrUndefinedOrEmpty(description)
                ? t('catalogue.deroule') : t('catalogue.objectifs');
            const sessionDescriptionHtml = isNullOrUndefinedOrEmpty(description)
                ? sessionDetails(props.sessionsDetails, i)?.deroule || '' : description || '';
            sessionList.push(
                <div className={classes.session} key={`session_accordeon_${i}`}>
                    <CustomAccordion
                        summary={<Typography className={classes.accordionTitle}
                                             variant="subtitle1">{sessionDetails(props.sessionsDetails, i)?.title}</Typography>}>
                        {!isNullOrUndefinedOrEmpty(sessionDescriptionHtml) &&
                            <div className={classes.accordionDetailsClass}>
                                <Typography className={classes.subtitle1}
                                            variant="body1">{sessionDescriptionTitle}</Typography>
                                <Typography variant="body1"
                                            dangerouslySetInnerHTML={{__html: sessionDescriptionHtml}}/>
                            </div>}
                    </CustomAccordion>
                </div>
            );
        }
        return sessionList;
    }

    return (<>{sessionAccordeonList()}</>);

}

