import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {CoachModuleModel} from "../models/coach-module.model";
import {MeetingModel} from "../models/meeting.model";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";
import {Pagination} from "../models/purchase.model";

export class MeetingConfirmationService {

    endpoint = '/api/v1/meetingConfirmation';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;


    public async create(meeting: MeetingModel): Promise<CoachModuleModel[]> {
        return await axios.post(`${this.endpoint}`, meeting)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in meetingconfirmation retrieving: " + error);
                throw error;
            });
    }

    public async update(meeting: MeetingModel | null): Promise<MeetingModel | void> {
        if (meeting) {
            return await axios.put(`${this.endpoint}/${meeting.id}`, meeting)
                .then(response => {
                    return response.data;
                }).catch((error: AxiosError) => {
                    log.error("Error in meetingconfirmation updating: " + error);
                    throw error;
                });
        }
    }

    public async confirm(meeting: MeetingModel | null): Promise<MeetingModel | void> {
        if (meeting) {
            return await axios.put(`${this.endpoint}/confirm/${meeting.id}`, meeting)
                .then(response => {
                    return response.data;
                }).catch((error: AxiosError) => {
                    log.error("Error in meetingconfirmation updating: " + error);
                    throw error;
                });
        }
    }

    public async get(page: number, maxByPage = 10): Promise<any> {
        return await axios.get(`${this.endpoint}/learner?range=[${page},${maxByPage}]`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in meeting confirmation fetching: " + error);
                throw error;
            });
    }

    public async getForCoach(page: number, maxByPage = 10): Promise<any> {
        return await axios.get(`${this.endpoint}/coach?range=[${page},${maxByPage}]`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in meeting confirmation fetching: " + error);
                throw error;
            });
    }

    public async getMeetingConfirmation(phase: PhaseEnum, service: ServiceEnum, session: number): Promise<MeetingModel> {
        return await axios.get(`${this.endpoint}?phase=${phase}&service=${service}&session=${session}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in meeting confirmation retrieving: " + error);
                throw error;
            });

    }

}
