import React from 'react';
import clsx from 'clsx';
import {useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {useStyles} from "./style";
import UserAvatar from "../UserAvatar";
import {useNavigate, useResolvedPath} from "react-router";
import {userHasRole} from "../../../utils/login.util";
import {UserRole} from "../../../models/user.model";
import {NotificationBadge} from "../Notification";

interface AppTopBarProps {
    toggleDrawer: (open: boolean) => any;
    open: boolean
}

export default function AppTopBar(props: AppTopBarProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();
    const resolver = useResolvedPath('./dashboard');
    const goToDashboard = () => {
        navigate(resolver.pathname);
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6" noWrap className={classes.title} onClick={goToDashboard}>
                    Bring Your Best
                </Typography>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={props.toggleDrawer(true)}
                    className={clsx(classes.button, props.open && classes.hide)}>
                    <UserAvatar classesWrapper={classes.avatarWrapper} classesTypography={classes.avatarTypography}
                                showIcon={true}/>
                </IconButton>
                {userHasRole(UserRole.ENTREPRISE_CADRE) && <NotificationBadge/>}
            </Toolbar>
        </AppBar>
    );
}
