import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import {UserService} from '../../services/user.service';
import {PersistFormikValues} from 'formik-persist-values';
import {getDashboardByRole, getIsLoggedIn, getTokenAfterLogin} from "../../utils/login.util";
import {UserContainer} from "../../container/UserContainer";
import {log} from "../../utils/log";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {preventDefault} from "../../utils/index.util";
import PasswordInput from "../../components/Common/Password";
import {InputField} from "../../components/Common/FormFields";
import {createMuiTheme, ThemeProvider, useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import BybButton from "../../components/Common/BybButton";
import {Container, Grid, Typography} from "@material-ui/core";


const SignIn: React.FC = () => {
    // TODO rajouter un boutton pour redemander un code de confirmation / captcha

    // State
    const navigate = useNavigate();
    const {t} = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);

    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const userState = UserContainer.useContainer();

    const loginTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#311b92',
            },
        },
    });

    const SignInSchema = Yup.object().shape({
        email: Yup.string().email(t("signUp.errors.email.invalid")).required(`L'email est obligatoire`),
        password: Yup.string().required('Le mot de passe est obligatoire'),
    });

    useEffect((
        () => {
            if (getIsLoggedIn()) {
                navigate(getDashboardByRole(userState.getConnectedUser()));
            }
        }
    ), []);


    return (
        <ThemeProvider theme={loginTheme}>
            <Container maxWidth="lg" className={classes.root}>
                <div className={classes.formContainer}>
                    <Typography className={classes.title} variant="h4">BringYourBest</Typography>
                    <Typography className={classes.subtitle} variant="h5">Connexion</Typography>
                    {loginErrorMessage && <Alert severity="error">{loginErrorMessage}</Alert>}

                    <Formik
                        initialValues={{email: '', password: ''}}
                        validationSchema={SignInSchema}
                        onSubmit={async (values, {setSubmitting}) => {
                            await new UserService().login(values as any).then((accessToken) => {
                                // TODO If a previous url was asked and is different from /login path, then redirect to this url
                                log.debug("logged in in SignIn form !");
                                const jwtToken = getTokenAfterLogin(accessToken);
                                userState.setUser(new UserService().getCurrentUser());
                                setSubmitting(false);
                                navigate(getDashboardByRole(jwtToken));
                            }).catch(ko => {
                                setSubmitting(false);
                                setLoginErrorMessage(ko);
                                log.info("Error in signin: " + loginErrorMessage);
                            });
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={preventDefault && handleSubmit}>
                                <Grid container className={'input-wrapper'} direction="row"
                                      justify={'center'} alignItems={'stretch'}>
                                    <InputField
                                        type="email"
                                        name="email"
                                        label={'Email'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        autoComplete={'username'}
                                        fullWidth
                                        className={classes.formElement}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>

                                <Grid container className={'input-wrapper'} direction="row"
                                      justify={'center'} alignItems={'stretch'}>
                                    <PasswordInput
                                        name="password"
                                        label={'Mot de passe'}
                                        onChange={handleChange}
                                        className={classes.formElement}
                                        fullWidth
                                        onBlur={handleBlur}
                                        value={values.password}
                                        autoComplete="current-password"
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                </Grid>
                                <Grid container className={'input-wrapper'} direction="row"
                                      justify={'center'} alignItems={'stretch'}>
                                    <Link to="/forgot-password">Mot de passe oublié?</Link>
                                </Grid>
                                <BybButton
                                    type="submit"
                                    classes={classes.button}
                                    onClick={handleSubmit}
                                    size={'large'}
                                    disabled={isSubmitting}
                                    fullWidth={true}
                                    loading={isSubmitting}
                                    label={"Connexion"}>
                                </BybButton>

                                <PersistFormikValues name="signin-form" storage={window.localStorage}
                                                     ignoreValues={['password']}/>
                            </form>
                        )}
                    </Formik>


                    <Grid container className={'sign-in-wrapper'} direction="row"
                          justify={'center'} alignItems={'stretch'}>
                    <span>
                        Pas encore de compte <b>BringYourBest</b>?
                    </span>
                        <Link className="sign-in-link" to="/signup">Inscrivez-vous!</Link>
                    </Grid>
                </div>
            </Container>
        </ThemeProvider>
    );
}
export default SignIn;
