import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        parcours: {
            verticalAlign: 'top',
            textAlign: 'left'
        },
        root: {
            red: {
                color: "red"
            }
        },
        red: {
            color: "red"
        }

    })
);