/**
 * @return an array of files splitted
 * @param file The file to split
 * @param chunksize The chunk size in bytes
 */
export const createFileChunks = (file: File, chunksize: number/* cSize should be byte 1024*1 = 1KB */): File[] => {
    let startPointer = 0;
    let endPointer = file.size;
    let chunks = [];
    while (startPointer < endPointer) {
        let newStartPointer = startPointer + chunksize;
        chunks.push(blobToFile(file.slice(startPointer, newStartPointer), file.name));
        startPointer = newStartPointer;
    }
    return chunks;
}
export const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
}
export const ONE_MEGA_BYTES = 1048576;
export const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const IMAGE_SIZE_MAX = 7 * ONE_MEGA_BYTES; // SIZE IN BYTES, 7MB