import React from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom";
import {UserDocumentsContainer} from "../../../container/UserDocumentContainer";
import {useLocation} from "react-router";
import {isModuleRoute} from "../../../utils/router.util";
import {CV_KEY} from "../../../utils/userDocs.utils";


export default function CVNotLoaded(){

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const location = useLocation();

    const userDocumentState = UserDocumentsContainer.useContainer();

    const showCVWarning = ():boolean=> {
        const CV = userDocumentState.getUserDoc(CV_KEY);
        return !CV && isModuleRoute(location.pathname);
    }

    return (
            <>
                {showCVWarning() && <Alert severity="warning" className={classes.cvWarning}>
                    {t('documents.cvMissingPre')} <Link to='/learner/documents'>{t('documents.here')}</Link> {t('documents.cvMissingPost')}
                </Alert>
                }
            </>
    );
}

