import React, {useEffect, useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography
} from "@material-ui/core";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {SessionDetail} from "../../../../models/session-detail";
import BybButton from "../../../Common/BybButton";
import {ModuleDetails} from "../../../../models/module-details";
import {useLocation} from "react-router";
import {ModuleDetailsService} from "../../../../services/module-details.service";
import {Alert} from "@material-ui/lab";
import {SessionDetailsService} from "../../../../services/session-details.service";
import CoachSessionHome from "../CoachSessionHome";
import CoachSessionWrapper from "../CoachSessionWrapper";
import {sessionDetails} from "../../../../utils/sessionDetails.util";
import PageTitle from "../../../Common/PageTitle";

function _renderStepContent(step: number, nbSessions: number, phase: PhaseEnum, service: ServiceEnum,
                            moduleDetails: ModuleDetails, sessionsDetails: SessionDetail[] = [],
                            handleNext: () => void) {
    if (step === 0) {
        return <CoachSessionHome nbSession={nbSessions} phase={phase}
                                 service={service} handleNext={handleNext}
                                 sessionsDetails={sessionsDetails}
                                 description={moduleDetails.description} goal={moduleDetails.goal}/>;
    } else if (step > 0) {
        /*Session*/
        return <CoachSessionWrapper phase={phase} service={service}
                                    sessionDetails={sessionDetails(sessionsDetails, step)}/>;
    }
}


export default function CoachSessionStepper() {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [activeStep, setActiveStep] = React.useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingNext, setLoadingNext] = React.useState(false);
    const [nbSessions, setNbSession] = useState(0);
    const labelStepButton = activeStep === 0 ? t('catalogue.start') : t('catalogue.next');
    const iconStepButton = <KeyboardArrowRight/>;
    const [moduleDetails, setModuleDetails] = useState<ModuleDetails>();
    const [sessionsDetails, setSessionsDetails] = useState<SessionDetail[]>();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const [service] = useState<string | null>(params.get('service') || '');
    const [phase] = useState<string | null>(params.get('phase') || '');

    const moduleDetailsService = new ModuleDetailsService();

    async function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        (async () => {
            if (phase && service) {
                moduleDetailsService.getOneByPhaseAndService(phase as PhaseEnum, service as ServiceEnum)
                    .then((moduleDetails) => {
                        setModuleDetails(moduleDetails);
                    }).catch(() => {
                    setErrorMessage(t('unexpectedErrorLoading'));
                });
            }
        })();
    }, [phase, service]);

    useEffect(() => {
        (() => {
            if (moduleDetails) {
                new SessionDetailsService().getByModule(moduleDetails.moduleDetailsID)
                    .then((sessions: SessionDetail[]) => {
                        setSessionsDetails(sessions);
                        setNbSession(sessions.length);
                    })
                    .catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'));
                    });
            }
        })();
    }, [moduleDetails]);

    return (
        <Container maxWidth="lg" className={classes.root}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <Grid container className={classes.row} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                <PageTitle title={'Module: ' + moduleDetails?.title}/>

                <Paper square className={classes.paper} elevation={1}>
                    <div className={classes.content}>
                        <MobileStepper
                            variant="dots"
                            steps={nbSessions + 1}
                            position="static"
                            activeStep={activeStep}
                            className={classes.stepper}
                            nextButton={
                                <BybButton size="small" onClick={handleNext}
                                           disabled={activeStep === nbSessions}
                                           loading={loadingNext}
                                           label={labelStepButton}>
                                    {iconStepButton}
                                </BybButton>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                                    {t('catalogue.previous')}
                                </Button>
                            }
                        />
                        {
                            moduleDetails && _renderStepContent(activeStep, nbSessions, phase as PhaseEnum,
                                service as ServiceEnum,
                                moduleDetails, sessionsDetails, handleNext)
                        }
                    </div>
                </Paper>
                <div className={classes.wizardButtons}>
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                        {t('catalogue.previous')}
                    </Button>
                    <BybButton size="medium" onClick={handleNext}
                               disabled={activeStep === nbSessions}
                               loading={loadingNext}
                               label={labelStepButton}>
                        {iconStepButton}
                    </BybButton>
                </div>
            </Grid>
        </Container>


    );
}

