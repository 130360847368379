import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {CoachResumeModel} from "../models/coach-resume.model";
import {createFileChunks, ONE_MEGA_BYTES} from "../utils/file.utils";
import {
    CreateMultipartOutput,
    MultipartInput,
    MultipartUploadInput,
    UploadMultipartOutput
} from "../models/amazon.model";

export class CoachResumeService {

    endpoint = '/api/v1/coachResume';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;

    public async updateCoachResume(userID: string, coachResumeModel: Partial<CoachResumeModel>): Promise<any> {
        if (userID && coachResumeModel) {
            return await axios.put(`${this.endpoint}/${userID}`, coachResumeModel)
                .then(response => {
                    return response.data;
                }).catch((error: AxiosError) => {
                    log.error("Error in coachresume retrieving: " + error);
                    throw this.UNEXPECTED_ERROR;
                });
        }
        return Promise.reject();

    }

    public async getCoachResume(userID: string): Promise<CoachResumeModel> {
        return await axios.get(`${this.endpoint}/${userID}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachresume retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });

    }

    public async multiPartUpload(userID: string, file: File): Promise<any> {
        let uploadID = null;
        let firstFilePart = null;
        let fileVersion = null;
        try {
            // Chunk the files
            const filesPart = createFileChunks(file, 5 * ONE_MEGA_BYTES);
            firstFilePart = filesPart[0];
            // Init the file parts upload
            log.debug(`Videopresentation chunk upload will start for coach ${userID} and ${filesPart.length} parts.`);
            const startMultiUploadOutput = await this.startMultiPartUpload(userID, firstFilePart);
            uploadID = startMultiUploadOutput.uploadID;
            fileVersion = startMultiUploadOutput.fileVersion;
            // Upload each file parts
            const multipartUploadOutputs: MultipartUploadInput[] = [];
            for (let partNumber = 1; partNumber <= filesPart.length; partNumber++) {
                log.debug(`Uploading filePart:   ${partNumber}/${filesPart.length}`);
                const multiPartInputData = <MultipartInput>{
                    uploadID: startMultiUploadOutput.uploadID,
                    fileVersion: startMultiUploadOutput.fileVersion,
                    partNumber,
                };
                const partUploadOutput = await this.uploadUserDocumentPart(userID, filesPart[partNumber - 1], multiPartInputData);
                multipartUploadOutputs.push({
                    ...partUploadOutput,
                    partNumber
                })
                log.debug(`filePart ${partNumber}/${filesPart.length} uploaded successfully`);
            }
            // Complete file parts upload
            const completeMultiPartUploadInput = <MultipartInput>{
                uploadID: startMultiUploadOutput.uploadID,
                fileVersion: startMultiUploadOutput.fileVersion,
                multiParts: multipartUploadOutputs
            }
            const res = await this.completeUploadUserDocumentParts(userID, firstFilePart, completeMultiPartUploadInput);
            log.info(`All videopresentation filesPart has ben uploaded successfully for coach ${res.userID}`);
            return res;
        } catch (error: any) {
            if (uploadID && firstFilePart && fileVersion) {
                const cancelMultiPartUploadInput = <MultipartInput>{
                    uploadID,
                    fileVersion,
                }
                await this.cancelUploadUserDocumentParts(userID, firstFilePart, cancelMultiPartUploadInput).catch(error => {
                    log.error(error);
                });
            }
            log.error(error);
            throw this.UNEXPECTED_ERROR;
        }
    }

    private async startMultiPartUpload(userID: string, file: File): Promise<CreateMultipartOutput> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        return await axios
            .post(this.endpoint + `/${userID}/videoPresentation/beginUpload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Content-Disposition': 'form-data; name=media',
                    },
                    timeout: 180 * 1000 // 3min de timeout pour les vidéos
                })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    private async uploadUserDocumentPart(userID: string, filePart: File, data: MultipartInput): Promise<UploadMultipartOutput> {
        const formData = new FormData();
        formData.append("file", filePart, filePart.name);
        formData.append("data", JSON.stringify(data));
        return await axios
            .post(this.endpoint + `/${userID}/videoPresentation/uploadPart`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Content-Disposition': 'form-data; name=media',
                    },
                    timeout: 180 * 1000 // 3min de timeout pour les vidéos
                })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    private async completeUploadUserDocumentParts(userID: string, file: File, data: MultipartInput): Promise<CoachResumeModel> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("data", JSON.stringify(data));
        return await axios
            .post(this.endpoint + `/${userID}/videoPresentation/completeUpload`,
                formData,
                {
                    headers: {
                        'Content-Type': 'form-data',
                        'Content-Disposition': 'form-data; name=media',
                    },
                    timeout: 180 * 1000 // 3min de timeout pour les vidéos
                })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    private async cancelUploadUserDocumentParts(userID: string, file: File, data: MultipartInput): Promise<void> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("data", JSON.stringify(data));
        return await axios
            .delete(this.endpoint + `/${userID}/videoPresentation/cancelUpload`,
                {
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Content-Disposition': 'form-data; name=media',
                    },
                    timeout: 180 * 1000 // 3min de timeout pour les vidéos
                })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async deleteVideo(userID: string): Promise<CoachResumeModel> {
        return await axios
            .delete(this.endpoint + `/${userID}/videoPresentation`,
                {
                    timeout: 180 * 1000 // 3min de timeout pour les vidéos
                })
            .then(response => {
                log.debug(`Video presentation removed successfully for coach ${userID}`);
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }
}

