import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        button: {
            alignSelf: 'flex-end',
            marginTop: theme.spacing(1)
        },
        buttonWrapper: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        videoButtonAction: {
            margin: theme.spacing(1)
        },
        richText: {
            width: '100%',
        },
        row: {
            marginBottom: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            borderRadius: theme.spacing(1)
        },
        accordionDetailsClass: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        }
    })
);