import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {JwtTokenModel} from "../../../models/jwt-token.model";
import {Avatar, CircularProgress} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {UserContainer} from "../../../container/UserContainer";
import {IMAGE_SIZE_MAX, IMAGE_SUPPORTED_FORMATS, ONE_MEGA_BYTES} from "../../../utils/file.utils";


interface MyProfilePictureProps {
    user: JwtTokenModel;
}

const MyProfilePicture = (props: MyProfilePictureProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [avatarFullUrl, setAvatarFullUrl] = useState("");
    const [imageError, setImageError] = useState("");
    const [isImageLoading, setIsImageLoading] = useState(false);
    const userState = UserContainer.useContainer();
    const [errorMessageProfile, setErrorMessageProfile] = useState("");
    const [sucessMessageProfile, setSucessMessageProfile] = useState("");


    useEffect(() => {
        setAvatarFullUrl(props.user.avatar || '');
    }, [props.user.avatar]);


    const handleImageUpload = async (event: any) => {
        setSucessMessageProfile("")
        setErrorMessageProfile("");
        const files = event?.currentTarget?.files;
        setImageError('');
        if (files?.length) {
            const file = files[0];
            const fileType = file.type || file.mimetype;

            if (file.size > IMAGE_SIZE_MAX) {
                setImageError(t('myProfile.photoMaxError', {maxSize: Math.round(IMAGE_SIZE_MAX / ONE_MEGA_BYTES)}));
            } else if (IMAGE_SUPPORTED_FORMATS.indexOf(fileType) < 0) {
                setImageError(t('myProfile.photoTypeError', {imageTypes: IMAGE_SUPPORTED_FORMATS.toString()}));
            } else {
                setImageError('');
                const tempFile = URL.createObjectURL(file);
                setAvatarFullUrl(tempFile);
                // formikProfileInfo.setFieldValue("file", file || null);
                setIsImageLoading(true);
                await new UserService().updateProfilePicture(props.user.userID, file).then(() => {
                    setSucessMessageProfile(t("myProfile.photoSuccess"));
                    userState.setUser(new UserService().getCurrentUser());
                    setIsImageLoading(false);
                }).catch(() => {
                    setAvatarFullUrl(props.user.avatar || '');
                    setErrorMessageProfile(t("myProfile.error"));
                    setIsImageLoading(false);
                });
            }
        }
    }

    return (

        <>
            {errorMessageProfile && <Alert severity="error">{errorMessageProfile}</Alert>}
            {sucessMessageProfile && <Alert severity="success">{sucessMessageProfile}</Alert>}

            <Avatar className={classes.imgFormElement}
                    alt={props.user.email}
                    src={avatarFullUrl}/>
            {imageError ? (
                <div className={classes.error}>{imageError}</div>
            ) : null}
            <Button className={classes.imgButton}
                    disabled={isImageLoading}
                    component="label" endIcon={<EditIcon/>}>
                {t('myProfile.changePhoto')}
                {isImageLoading && <CircularProgress size={20}/>}
                <input name="file"
                       type="file"
                       hidden
                       onChange={handleImageUpload}/>
            </Button>
        </>
    );
}

export default MyProfilePicture;
