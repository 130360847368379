import {useTranslation} from "react-i18next";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import BybButton from "../BybButton";

export interface AlterDialogProps {
    open: boolean;
    handleClose: () => void;
    handleConfirmDelete?: () => void;
    displayConfirm?: boolean;
    ariaLabelledby?: string;
    ariaDescribedby?: string;
    title: string;
    contentText?: string;
    confirmText?: string;
    loading?: boolean;
    children?: any;
}


export function AlertDialog(props: AlterDialogProps) {
    const {t} = useTranslation();
    const theme = useTheme();
    const useStyles = makeStyles({
        danger: {
            color: theme.palette.error.light
        },
        dangerDark: {
            color: theme.palette.error.main
        }
    });
    const classes = useStyles();


    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby={props.ariaLabelledby}
                aria-describedby={props.ariaDescribedby}
            >
                <DialogTitle id="alert-dialog-title" className={classes.danger}>
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    {props.children}
                    {props.contentText && <DialogContentText>
                        {props.contentText}
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary" autoFocus>
                        {t('cancel')}
                    </Button>
                    {(!props.displayConfirm || !props.handleConfirmDelete) &&
                    <BybButton onClick={props.handleConfirmDelete}
                                classes={classes.dangerDark}
                                loading={props.loading || false}
                                label={props.confirmText || t('agree')}/>}
                </DialogActions>
            </Dialog>
        </div>
    );
}



