import {Button, CircularProgress, PropTypes} from '@material-ui/core';
import React from 'react';


export interface BybButtonProps {
    classes?: any;
    onClick?: (event: any) => void;
    color?: PropTypes.Color;
    variant?: "text" | "outlined" | "contained" | undefined;
    disabled?: boolean;
    fullWidth?: boolean;
    loading: boolean;
    label?: string;
    children?: any;
    component?: any;
    endIcon?: any;
    circularProgressClasses?: any;
    circularProgressSize?: number;
    type?: "button" | "submit" | "reset" | undefined;
    size?: "small" | "medium" | "large" | undefined;
}

const BybButton: React.FC<BybButtonProps> = (props: BybButtonProps) => {

    return (
        <Button className={props.classes} onClick={props.onClick} color={props.color} variant={props.variant}
                component={props.component} endIcon={props.endIcon}
                size={props.size}
                disabled={props.disabled} fullWidth={props.fullWidth} type={props.type || 'button'}>
            {props.label}
            {props.children}
            {props.loading &&
            <CircularProgress className={props.circularProgressClasses} size={props.circularProgressSize}/>}
        </Button>
    );

}

BybButton.defaultProps = {
    disabled: false,
    circularProgressSize: 20,
    circularProgressClasses: undefined,
    classes: undefined,
    size: 'medium'
}


export default BybButton;