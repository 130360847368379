import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {JwtTokenModel} from "../models/jwt-token.model";
import {cleanLocalStorage, getTokenAfterLogin} from "../utils/login.util";
import {ChangePassword, User, UserProfileDAO, UserRole} from "../models/user.model";
import {ContactUsModel} from "../models/contactUs.model";

export class UserService {

    userEndpoint = '/api/v1/user';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;
    LOGIN_ERROR = `Identifiant ou mot de passe incorrect`
    FORGOT_PASSWORD_ERROR = `Identifiant incorrect`
    RESET_PASSWORD_ERROR = `Identifiant incorrect`
    private RESET_EMAIL_ALREADY_SENT_ERROR = `Un mail de modification de mot de passe vous a déjà été envoyé.` +
        `Veuillez vérifier vos spams ou attendre 24 heures pour renouveller la demande.`;

    public async loginGoogle(idToken: string): Promise<any> {
        return await axios.post(this.userEndpoint + "/signin/google", {idToken: idToken})
            .then(response => {
                log.info("response: " + response);
                return response.data.accessToken;
            }).catch((error: AxiosError) => {
                log.info("error in login: " + error.response?.data?.message);
                if (error?.response?.status === 404 || error?.response?.status === 401) {
                    throw this.LOGIN_ERROR;
                } else {
                    throw this.UNEXPECTED_ERROR;
                }
            });
    }

    public async login(credentials: { username: string, password: string }): Promise<any> {
        return await axios.post(this.userEndpoint + "/signin", credentials)
            .then(response => {
                // log.debug("Login ok!: " + response.data.accessToken);
                return response.data.accessToken;
            }).catch((error: AxiosError) => {
                log.error("error in login: " + error.response?.data?.message);
                const errorStatus = error?.response?.status || 0;
                if ([404, 401, 403].includes(+errorStatus)) {
                    throw this.LOGIN_ERROR;
                } else {
                    throw this.UNEXPECTED_ERROR;
                }
            });
    }

    public async verifyEmail(credentials: { email: string, code: string }): Promise<any> {
        return await axios.post(this.userEndpoint + "/verify-account", credentials)
            .then(response => {
                return response.data.accessToken;
            }).catch((error: AxiosError) => {
                log.info("error in login: " + error.response?.data?.message);
                throw error;
            });
    }


    public async deleteUser(user: JwtTokenModel): Promise<any> {
        return await axios.delete(`${this.userEndpoint}/${user.userID}`).then((response) => {
            log.debug("Success register" + JSON.stringify(response));
            this.logout();
            return "OK";
        }).catch((error) => {
            log.error("Error register" + JSON.stringify(error.response));
            throw error;
        });
    }


    public logout() {
        cleanLocalStorage();
    }

    public getCurrentUser(): JwtTokenModel {
        const user = localStorage.getItem('user');
        return user ? JSON.parse(user) : null;
    }


    public async forgotPassword(forgotPassword: { email: string }): Promise<any> {
        return await axios
            .post(this.userEndpoint + "/password/reset", forgotPassword)
            .then(response => {
                log.debug("Password forgot success");
                return response.data;
            }).catch((error: AxiosError) => {
                log.info("error in login: " + error.response?.data?.message);
                if (error?.response?.status === 404) {
                    throw this.FORGOT_PASSWORD_ERROR;
                } else if (error?.response?.status === 401) {
                    throw this.RESET_EMAIL_ALREADY_SENT_ERROR;
                } else {
                    throw this.UNEXPECTED_ERROR;
                }
            });
    }

    public async getUser(userID: string): Promise<User> {
        return await axios
            .get(this.userEndpoint + "/" + userID)
            .then(response => {
                log.debug("Password forgot success");
                return response.data;
            }).catch((error: AxiosError) => {
                log.info("error in login: " + error.response?.data?.message);
                throw this.UNEXPECTED_ERROR;
            });
    }


    public async resetPassword(email: string, password: string, token: string): Promise<any> {
        return await axios
            .post(this.userEndpoint + "/password/reseted", {email: email, password: password, token: token})
            .then(response => {
                log.debug("Password forgot success");
                return response.data;
            }).catch((error: AxiosError) => {
                log.info("error in login: " + error.response?.data?.message);
                if (error?.response?.status === 404) {
                    throw this.RESET_PASSWORD_ERROR;
                } else {
                    throw this.UNEXPECTED_ERROR;
                }
            });
    }

    public async firstPasswordChange(userID: string, password: string): Promise<any> {
        return await axios
            .put(this.userEndpoint + `/${userID}/firstPassword`, {
                password: password,
            })
            .then(response => {
                log.debug("Password update success: " + JSON.stringify(response));
                return response.data.accessToken;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async updateProfileInfos(userID: string, profile: UserProfileDAO): Promise<any> {
        return await axios
            .put(this.userEndpoint + `/${userID}/profile`, profile)
            .then(response => {
                const accessToken = response.data.accessToken;
                cleanLocalStorage();
                return getTokenAfterLogin(accessToken);
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async updateProfilePicture(userID: string, file: File): Promise<any> {
        const formData = new FormData();
        formData.append("file", file);
        return await axios
            .post(this.userEndpoint + `/${userID}/avatar`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Content-Disposition': 'form-data; name=media',
                    },
                })
            .then(response => {
                const accessToken = response.data.accessToken;
                cleanLocalStorage();
                return getTokenAfterLogin(accessToken);
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async updateProfilePassword(userID: string, changePassword: ChangePassword): Promise<any> {
        return await axios
            .put(this.userEndpoint + `/${userID}/password`, changePassword)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public static isEnterpriseUser(user: User | JwtTokenModel) {
        return user.roles.includes(UserRole.ENTREPRISE);
    }

    public static isEnterpriseCadreUser(user: User | JwtTokenModel) {
        return user.roles.includes(UserRole.ENTREPRISE_CADRE);
    }

    public static isCoachUser(user: User | JwtTokenModel | null) {
        return user?.roles?.includes(UserRole.COACH);
    }

    public async contactUs(contactUs: ContactUsModel): Promise<any> {
        return await axios.post(this.userEndpoint + "/contactUs", contactUs)
            .then(response => {
                // log.debug("Login ok!: " + response.data.accessToken);
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("error in contactUs: " + error.response?.data?.message);
                throw this.UNEXPECTED_ERROR;
            });
    }

}
