import {Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {CustomAccordion} from "../../Common/Accordion";
import {CoachModuleModel} from "../../../models/coach-module.model";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface CoachResumeItemProps {
    coachModules: CoachModuleModel[]
}

export default function CoachServiceList(props: CoachResumeItemProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();


    return (
        <Grid container className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <div className={classes.root}>
                <CustomAccordion
                    detailsClass={classes.accordionDetailsClass}
                    summary={<Typography variant="subtitle1">{t('coach.resume.competences')}</Typography>}>
                    <div className={classes.accordionDetailsClass}>
                        <List>
                            {props.coachModules?.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t(`catalogue.phases.${item.phase}`)}
                                        secondary={t(`catalogue.services.${item.service}`)}
                                    />
                                </ListItem>))}
                        </List>
                    </div>
                </CustomAccordion>
            </div>
        </Grid>);
}