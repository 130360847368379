import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: theme.shadows[1]
        },
        title: {
            flexGrow: 1,
            cursor: 'pointer'
        },
        notification: {
            marginRight: theme.spacing(2)
        },
        hide: {
            display: 'none',
        },
        button: {
            width: '15%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            borderRadius: 0
        },
        avatarWrapper: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-end'
        },
        avatarTypography: {
            marginLeft: theme.spacing(1),
            maxWidth: '50%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
    }),
);