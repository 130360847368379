import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {UserRole} from "../models/user.model";
import {ParcoursAmount, ParcoursModel} from "../models/parcours.model";

export class ParcoursService {

    parcoursEndpoint = '/api/v1/parcours';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;

    public async getAmount(type: UserRole): Promise<ParcoursAmount[]> {
        return await axios.get(`${this.parcoursEndpoint}/amount?type=${type}`)
            .then(response => {
                return response.data as ParcoursAmount[];
            }).catch((error: AxiosError) => {
                log.error("Error in parcours retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async getDefault(): Promise<ParcoursModel | null> {
        return await axios.get(`${this.parcoursEndpoint}/default`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in parcours retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async getParcours(parcoursID: string, expand: boolean | null = null): Promise<ParcoursModel | null> {
        return await axios.get(`${this.parcoursEndpoint}/${parcoursID}?expand=${expand}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in parcours retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }

}
