import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@material-ui/core";
import {UserService} from "../../../services/user.service";
import GoBackButton from "../../Common/GoBackButton";
import React from "react";

export interface WelcomeMessageProps {
    showSubtitle?: boolean;
    showTitle?: boolean;
    subTitle?: string;
}

export default function WelcomeMessage(props: WelcomeMessageProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const user = new UserService().getCurrentUser();

    const firstname = () => {
        return user.firstname ? ` ${user.firstname}` : '';
    }

    return (
        <Grid container direction="column" justify={'center'} alignItems={'center'} className={classes.main}>
            {props.showTitle && <Typography variant="h6"
                                            className={classes.title}
                                            dangerouslySetInnerHTML={{
                                                __html: t('welcomeMessage.title', {
                                                    name: firstname()
                                                })
                                            }}/>}
            {props.showSubtitle &&
                <div className={classes.subTitleWrapper}>
                    <GoBackButton/>
                    <Typography variant="h6"
                                className={classes.subtitle}>{props.subTitle || t('welcomeMessage.subtitle')}</Typography>
                </div>
            }
        </Grid>
    )
}