import {Grid, Paper, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import {UserDocumentsContainer} from "../../../container/UserDocumentContainer";
import LearnerDocumentsModulesSession from "../LearnerDocumentsModulesSession";

export interface LearnerDocumentsModulesProps {
    phase: PhaseEnum | null;
    service: ServiceEnum | null;
    nbSessions: number;
}

export default function LearnerDocumentsModules(props: LearnerDocumentsModulesProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [sessionList, setSessionList] = useState<number[]>([]);


    const user = new UserService().getCurrentUser();

    const userDocumentState = UserDocumentsContainer.useContainer();
    const documentKey = `${props.phase}_${props.service}`;

    const serviceTitle = (service: string | null): string => {
        return t(`catalogue.services.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.${service}`)
    };

    React.useEffect(() => {
            let active = true;
            (async () => {
                let tempSessionList: number[] = [];
                for (let i = 1; i <= props.nbSessions; i++) {
                    const key = `${documentKey}_${i}`;
                    tempSessionList = [...tempSessionList, i];
                    if (props.service === ServiceEnum.PITCH) {
                        await userDocumentState.loadUserDocument(user.userID, key + '_1');
                        await userDocumentState.loadUserDocument(user.userID, key + '_2');
                    } else {
                        await userDocumentState.loadUserDocument(user.userID, key);
                    }
                }
                setSessionList(tempSessionList);
            })();
            return () => {
                active = false;
            };
        },
        // eslint-disable-next-line
        [props.service]);


    return (
        <Grid container className={classes.row} direction="row" alignItems={'stretch'}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            <Paper className={classes.paper}>
                <div className={classes.root}>
                    <Typography variant="subtitle1"
                                className={classes.docTitle}>{serviceTitle(props.service)}</Typography>
                    <LearnerDocumentsModulesSession phase={props.phase} service={props.service}
                                                    sessionList={sessionList}/>
                </div>

            </Paper>
        </Grid>);
}
