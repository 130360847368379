import {CircularProgress, IconButton, Link, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import CustomDropzone from "../../Common/Dropzone";
import Alert from "@material-ui/lab/Alert";
import {UserService} from "../../../services/user.service";
import {UserDocumentModel} from "../../../models/user-document.model";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import {isNullOrUndefinedOrEmpty} from "../../../utils/index.util";
import {CustomAccordion} from "../../Common/Accordion";
import {UserDocumentsContainer} from "../../../container/UserDocumentContainer";
import PitchCustomDocumentUpload from "./PitchCustomDocumentUpload";
import CustomVideoPlayer from "../../Common/VideoPlayer";
import {ONE_MEGA_BYTES} from "../../../utils/file.utils";

export interface LearnerDocumentsModulesSessionProps {
    phase: PhaseEnum | null;
    service: ServiceEnum | null;
    sessionList: number[];

}

export default function LearnerDocumentsModulesSession(props: LearnerDocumentsModulesSessionProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const user = new UserService().getCurrentUser();
    const [showDropzone, setShowDropzone] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const userDocumentState = UserDocumentsContainer.useContainer();
    const documentKey = `${props.phase}_${props.service}`;

    const isPitchModule = props.service === ServiceEnum.PITCH;

    const uploadDocuments = (file: File | "", sessionNumber: number) => {
        const fileKey = `${documentKey}_${sessionNumber}`;
        setErrorMessage('');
        setIsLoading(true);
        if (file === "" || file == null) {
            // Remove document
            userDocumentState.deleteUserDoc(user.userID, fileKey)
                .catch(() => {
                    setErrorMessage(t('unexpectedError'))
                }).finally(() => {
                setIsLoading(false);
            });
        } else {
            // Add/update a document
            userDocumentState.updateUserDoc(user.userID, fileKey, file)
                .catch(() => {
                    setErrorMessage(t('unexpectedError'))
                }).finally(() => {
                setIsLoading(false);
            });
        }


    }

    const getSessionDocument = (sessionNumber: number): UserDocumentModel | null => {
        return userDocumentState.getUserDoc(`${documentKey}_${sessionNumber}`);
    };

    const getPitchDocument = (sessionNumber: number, order: number): UserDocumentModel | null => {
        return userDocumentState.getUserDoc(`${documentKey}_${sessionNumber}_${order}`);
    };

    const shouldShowDropzone = (sessionNumber: number): boolean => {
        return !isLoading && (showDropzone || isNullOrUndefinedOrEmpty(userDocumentState.getUserDoc(`${documentKey}_${sessionNumber}`)?.file));
    };

    const shouldExpandPannel = (sessionNumber: number, service: ServiceEnum | null): boolean => {
        return sessionNumber === 1 && service === ServiceEnum.CV;
    };

    const getDocumentName = (sessionNumber: number): string => {
        return getSessionDocument(sessionNumber)?.originalName ||
            t('documents.sessionDefaultName', {phase: props.phase, service: props.service, session: sessionNumber});
    };

    const getPitchDocumentUploader = (service: ServiceEnum | null, sessionNumber: number, order: number) => {
        const file = getPitchDocument(sessionNumber, order)?.file || null;
        return (
            <>
                <PitchCustomDocumentUpload documentKey={documentKey + '_' + sessionNumber + '_' + order} file={file}/>
            </>);
    }

    const getCustomDocumentUploader = (service: ServiceEnum | null, sessionNumber: number) => {
        return (
            <>
                {isLoading &&
                    <CircularProgress color="inherit"/>}
                {shouldShowDropzone(sessionNumber) &&
                    <CustomDropzone accept={'.doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx'}
                                    maxSize={2 * ONE_MEGA_BYTES}
                                    onFileAccepted={(file) => uploadDocuments(file, sessionNumber)}/>}
            </>
        );
    }

    const getVideoPlayer = (service: ServiceEnum | null, sessionNumber: number, order: number) => {
        return (getPitchDocument(sessionNumber, order)?.file &&
            <CustomVideoPlayer url={getPitchDocument(sessionNumber, order)?.file || ''}/>);
    }

    const getFileLink = (service: ServiceEnum | null, sessionNumber: number) => {
        return (<Typography className={classes.downloadedFile}>
            <Link href={getSessionDocument(sessionNumber)?.file}
                  target="_blank"
                  download={getSessionDocument(sessionNumber)?.file}
                  className={classes.link}>
                {getDocumentName(sessionNumber)}
            </Link>
            <IconButton aria-label="Infos" className={classes.buttonEdit}
                        color="default"
                        onClick={() => setShowDropzone(true)}>
                <EditIcon/>
            </IconButton>
            <IconButton aria-label="Infos" className={classes.buttonDelete}
                        onClick={() => uploadDocuments("", sessionNumber)}>
                <DeleteOutlineIcon/>
            </IconButton>
        </Typography>);
    }

    const getPitchDocumentSessionComponent = (sessionNumber: number, order: number) => {
        return (
            <div className={classes.accordionDetailsPitch}>
                {
                    getPitchDocument(sessionNumber, order)?.file &&
                    <div className={classes.fileName}>
                        {getVideoPlayer(props.service, sessionNumber, order)}
                    </div>
                }
                <div className={classes.fileDropzone}>
                    {getPitchDocumentUploader(props.service, sessionNumber, order)}
                </div>
            </div>);
    }


    React.useEffect(() => {
            setShowDropzone(false);
        },
        // eslint-disable-next-line
        [props.service]
    );

    return (
        <>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            {props.sessionList.map((sessionNumber) => (
                <CustomAccordion
                    defaultExpanded={shouldExpandPannel(sessionNumber, props.service)}
                    key={"sessionList_" + sessionNumber}
                    summary={<Typography variant="subtitle1">Session n° {sessionNumber}</Typography>}>
                    {!isPitchModule &&
                        <div className={classes.accordionDetails}>
                            {
                                getSessionDocument(sessionNumber)?.file &&
                                <div className={classes.fileName}>
                                    {getFileLink(props.service, sessionNumber)}
                                </div>
                            }
                            <div className={classes.fileDropzone}>
                                {getCustomDocumentUploader(props.service, sessionNumber)}
                            </div>
                        </div>}
                    {isPitchModule &&
                        <div className={classes.accordionDetails}>
                            {getPitchDocumentSessionComponent(sessionNumber, 1)}
                            {getPitchDocumentSessionComponent(sessionNumber, 2)}
                        </div>
                    }
                </CustomAccordion>
            ))}
        </>
    );
}
