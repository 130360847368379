import {InputAdornment} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {RemoveRedEye} from '@material-ui/icons';
import React, {useState} from 'react';
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        eye: {
            cursor: 'pointer',
        },
    })
);

const PasswordInput = (props: any) => {
    const classes = useStyles();
    const [passwordIsMasked, setPasswordIsMasked] = useState(true);

    return (
        <TextField
            type={passwordIsMasked ? 'password' : 'text'}
            {...props}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <RemoveRedEye
                            className={classes.eye}
                            onClick={() => setPasswordIsMasked(!passwordIsMasked)}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );

}

export default PasswordInput;