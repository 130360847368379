import React from 'react';
import AppRouter from './appRouter';
import {CssBaseline} from "@material-ui/core";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {RouterContainer} from "./container/RouterContainer";
import {UserContainer} from "./container/UserContainer";
import {LanguageContainer} from './container/LanguageContainer';
import './locales/i18n'; // Init internationalization files
import './utils/axios.utils'; // Init axios auth interceptor


const App: React.FC = () => {
    const defaultTheme = createMuiTheme({
        overrides: {
            MuiTableCell: {
                head: {
                    color: "#311b92",
                }
            }
        },
        palette: {
            primary: {
                main: '#311b92',
            },
        },
    });

    return (
        <ThemeProvider theme={defaultTheme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline/>
            <LanguageContainer.Provider>
                <UserContainer.Provider>
                    <RouterContainer.Provider>
                        <AppRouter/>
                    </RouterContainer.Provider>
                </UserContainer.Provider>
            </LanguageContainer.Provider>
        </ThemeProvider>
    )
};

export default App;
