import {Container} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {SuiviConsommationContainer} from "../../../container/SuiviConsommationContainer";
import LearnerPhaseServiceList from "../LearnerPhaseServiceList";
import {PhaseEnum} from "../../../models/catalogue.model";
import {CatalogueByPhase, SuiviConsommationsByPhase} from "../../../models/suivi-consommation.model";
import WelcomeMessage from "../WelcomeMessage";
import {sortPhase} from "../../../utils/suiviConso.util";
import {useTranslation} from "react-i18next";


export default function LearnerPhaseServiceListWrapper() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const suiviConsoState = SuiviConsommationContainer.useContainer();
    const {t} = useTranslation();


    const cataloguesReference: CatalogueByPhase | SuiviConsommationsByPhase = suiviConsoState.suiviConsoByPhase;

    const subtitle = t('welcomeMessage.subtitle', {parcoursName: suiviConsoState.userParcours?.name})

    const getPhasesList = (): PhaseEnum[] => {
        return Object.keys(cataloguesReference)?.map(key => key as PhaseEnum);
    }


    return (<Container maxWidth="lg" className={classes.root}>
        <WelcomeMessage showSubtitle={true} subTitle={subtitle}/>

        {
            sortPhase(getPhasesList())?.map((phase: PhaseEnum, index: number) => (
                <LearnerPhaseServiceList phase={phase} key={index + phase}
                                         displayBackButton={!index}
                                         suiviConsos={suiviConsoState.suiviConsoByPhase[phase]}
                                         cataloguesReference={cataloguesReference[phase]}/>
            ))
        }
    </Container>);
}
