import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        content: {
            padding: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        checkoutForm: {
            marginTop: theme.spacing(2)
        },
        formElement: {
            marginTop: theme.spacing(1)
        },
        dialogActions: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        total: {
            display: 'flex',
            flexDirection: 'row',
        },
        amount: {
            color: theme.palette.primary.main,
            marginLeft: theme.spacing(1)
        },
        discount: {
            color: theme.palette.error.main,
            marginLeft: theme.spacing(1)
        },
        amountAfterDiscount: {
            color: theme.palette.success.main,
            marginLeft: theme.spacing(1)
        },
        button: {
            marginTop: theme.spacing(1)
        },
        image: {},
        codeErrorIcon: {
            color: theme.palette.error.main
        },
        codeSuccessIcon: {
            color: theme.palette.success.main
        }
    })
);