import {useState} from "react";
import {createContainer} from "unstated-next";
import {UserDocumentByKey, UserDocumentModel} from "../models/user-document.model";
import {UserDocumentService} from "../services/user-document.service";

function UserDocumentsContainerHook() {
    const [documents, setDocuments] = useState<UserDocumentByKey>({});
    const userDocumentService = new UserDocumentService();

    let loadUserDocument = async (userID: string, key: string): Promise<UserDocumentModel | null> => {
        return userDocumentService.get(userID, key).then((userDoc: UserDocumentModel) => {
            if (userDoc) {
                let newDocs = documents;
                newDocs[key] = userDoc;
                setDocuments(newDocs);
            }
            return userDoc;
        });
    }

    const updateUserDoc = async (userID: string, key: string, file: File): Promise<UserDocumentModel> => {
        return userDocumentService.multiPartUpload(userID, key, file).then((data) => {
            setDocuments({...documents, ...{[key]: data}});
            return data;
        });
    }
    const deleteUserDoc = async (userID: string, key: string): Promise<UserDocumentModel> => {
        return userDocumentService.delete(userID, key).then((data) => {
            setDocuments({...documents, ...{[key]: data}});
            return data;
        });
    }

    const getUserDoc = (key: string): UserDocumentModel | null => {
        return documents[key] || null;
    }


    return {
        loadUserDocument, updateUserDoc, getUserDoc, deleteUserDoc
    }
}

export const UserDocumentsContainer = createContainer(UserDocumentsContainerHook)

