import React from 'react';
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {SessionDetail} from "../../../../models/session-detail";
import {isNullOrUndefinedOrEmpty} from "../../../../utils/index.util";
import Alert from "@material-ui/lab/Alert";
import ErrorIcon from "@material-ui/icons/Error";
import {AlertTitle} from "@material-ui/lab";
import PitchAdditionnalPrerequis from "../../../Learner/LearnerParcours/Pitch/PitchAdditionnalPrerequis";
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "../../../Learner/LearnerParcoursPhaseService/LearnerSession/style";
import {CustomAccordion} from "../../../Common/Accordion";
import InfoIcon from "@material-ui/icons/Info";

export interface CoachSessionHookProps {
    phase: PhaseEnum;
    service: ServiceEnum;
    sessionDetails: SessionDetail | null;
}

export default function CoachSessionWrapper(props: CoachSessionHookProps) {

    const {t} = useTranslation();
    const theme = useTheme();
    const classes = useStyles(theme);


    const getAdditionalPrequesiteForModule = (service: ServiceEnum) => {
        const documentKey = `${props.phase}_${props.service}_${props.sessionDetails?.order}`;

        switch (service) {
            case ServiceEnum.PITCH:
                return (<PitchAdditionnalPrerequis documentKey={documentKey} readonly={true}/>);
            default:
                return <></>
        }
    }

    const getPostSessionContent = () => {
        const adminPostsession = isNullOrUndefinedOrEmpty(props.sessionDetails) ? '' : (props.sessionDetails?.postsession || '');
        return (adminPostsession || '');
    }

    const getAdminPreRequesiteContent = () => {
        const adminPrerequesite = isNullOrUndefinedOrEmpty(props.sessionDetails) ? '' : (props.sessionDetails?.prerequesite || '');
        return (adminPrerequesite || '');
    }

    return (
        <>
            <div>
                <Typography variant="h6">{props.sessionDetails?.title}</Typography>
            </div>
            {!isNullOrUndefinedOrEmpty(props.sessionDetails?.description) && <div className={classes.description}>
                <Typography variant="subtitle1"
                            className={classes.subtitle1}>{t("catalogue.objectifs")}</Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{__html: props.sessionDetails!.description}}/>
            </div>}
            {!isNullOrUndefinedOrEmpty(props.sessionDetails?.deroule || '') && <div className={classes.deroule}>
                <Typography variant="subtitle1" className={classes.subtitle1}>{t("catalogue.deroule")}</Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{__html: props.sessionDetails!.deroule}}/>
            </div>}
            {!isNullOrUndefinedOrEmpty(props.sessionDetails?.prerequesite) && <div className={classes.prerequesite}>
                <Alert severity="warning" icon={<ErrorIcon fontSize="inherit"/>}>
                    <AlertTitle className={classes.subtitle1}>{t("catalogue.prerequis")}</AlertTitle>
                    <Typography className={classes.prePostSession} variant="body1"
                                dangerouslySetInnerHTML={{__html: getAdminPreRequesiteContent()}}/>
                    {getAdditionalPrequesiteForModule(props.service)}
                </Alert>
            </div>}
            <div className={classes.deroule}>
                <CustomAccordion
                    defaultExpanded={true}
                    summary={<Typography className={classes.accordionTitle}
                                         variant="subtitle1">{t("catalogue.postsession")}</Typography>}>
                    <div className={classes.accordionDetailsClass}>
                        {!isNullOrUndefinedOrEmpty(props.sessionDetails?.postsession) &&
                            <div className={classes.htmlPostsession}>
                                <Alert severity="info" icon={<InfoIcon fontSize="inherit"/>}>
                                    <Typography variant="body1" className={classes.prePostSession}
                                                dangerouslySetInnerHTML={{__html: getPostSessionContent()}}/>
                                </Alert>
                            </div>
                        }
                    </div>
                </CustomAccordion>
            </div>
        </>
    );
}

