import React from 'react';

// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import {UserDocumentsContainer} from '../../../../container/UserDocumentContainer';
import CustomVideoRecorderButtons from "../../../Common/CustomVideoRecorderButtons";


export interface PitchCustomDocumentUploadProps {
    documentKey: string;
    file: string | null;
}

export default function PitchCustomDocumentUpload(props: PitchCustomDocumentUploadProps) {
    const userDocumentState = UserDocumentsContainer.useContainer();
    const TEN_MINUTE = 10 * 60 * 1000;

    const updateOrDeleteDoc = (userID: string, file: File | null): Promise<any> => {
        if (!file) {
            return userDocumentState.deleteUserDoc(userID, props.documentKey);
        }
        return userDocumentState.updateUserDoc(userID, props.documentKey, file);
    }

    return (
        <>
            <UserDocumentsContainer.Provider>
                <CustomVideoRecorderButtons
                    timeLimit={TEN_MINUTE}
                    file={props.file}
                    submitVideo={updateOrDeleteDoc}/>
            </UserDocumentsContainer.Provider>
        </>
    );
}

