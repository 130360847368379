import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";
import {SessionDetailCoachModel} from "../models/session-detail-coach.model";

export class SessionDetailsCoachService {

    endpoint = '/api/v1/sessionDetailsCoach';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;

    public async getByModuleAndCoachIDAndLearner(phase: PhaseEnum, service: ServiceEnum,
                                                 order: number, coachID: string,
                                                 learnerID: string): Promise<SessionDetailCoachModel | null> {
        return await axios.get(`${this.endpoint}`, {
            params: {phase, service, order, coachID, learnerID}
        })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachmodule retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }


    public async getListByModuleAndCoachIDAndLearner(phase: PhaseEnum, service: ServiceEnum, coachID: string,
                                                     learnerID: string): Promise<SessionDetailCoachModel[] | null> {
        return await axios.get(`${this.endpoint}/list`, {
            params: {phase, service, coachID, learnerID}
        })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in coachmodule retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async update(sessionDetailsCoachID: string, data: SessionDetailCoachModel): Promise<any> {
        return await axios
            .put(`${this.endpoint}/${sessionDetailsCoachID}`, data)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw error;
            });
    }

    public async updateByQueryParams(phase: PhaseEnum, service: ServiceEnum,
                                     order: number, coachID: string,
                                     learnerID: string, data: SessionDetailCoachModel): Promise<any> {
        return await axios
            .put(`${this.endpoint}`,
                data, {
                    params: {
                        phase,
                        service,
                        order,
                        coachID,
                        learnerID
                    }
                })
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error(error);
                throw error;
            });
    }

}
